import { CircularProgress } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { CheckCircle, Warning } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";

interface GreenButtonProps extends ButtonProps {
  label: string;
  initialstate?: undefined | "busy" | "success" | "failed";
  onClick(): Promise<void>;
  onError(error: any): void;
  qualityCheckCode?: string;
  qcState?: "none" | "pass" | "fail";
}
function GreenButton(props: GreenButtonProps) {
  const { onClick, onError, qcState, qualityCheckCode } = props;
  const [successState, setSuccessState] = useState<
    undefined | "busy" | "success" | "failed"
  >(props.initialstate);

  useEffect(() => {
    if (qcState === "pass") {
      setSuccessState("success");
    } else {
      setSuccessState(undefined);
    }
  }, [qcState]);

  const pass = useCallback(async () => {
    setSuccessState("busy");
    try {
      await onClick();
      setSuccessState("success");
    } catch (error) {
      setSuccessState("failed");
      console.log("Failed to call click", error);
      onError(error);
    }
  }, [onClick, onError]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!props.disabled && event.key === "p") {
        pass();
      }
    },
    [props, pass]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Button
      data-cy="success"
      data-cy-wi={
        qualityCheckCode
          ? `success-${qualityCheckCode.toLowerCase().replace("/", "-")}`
          : undefined
      }
      variant="contained"
      color="success"
      fullWidth={props.fullWidth}
      onClick={pass}
      disabled={props.disabled}
    >
      {successState === "busy" && <CircularProgress size="1em" />}
      {successState === "failed" && <Warning />}
      {successState === "success" && <CheckCircle />}
      &nbsp;{props.label}
    </Button>
  );
}

export default GreenButton;
