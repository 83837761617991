import { IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useHistory } from "react-router-dom";

interface SettingsButtonProps {}

function SettingsButton(props: SettingsButtonProps) {
  const history = useHistory();

  return (
    <IconButton
      data-cy="settings"
      edge="start"
      color="inherit"
      aria-label="settings"
      onClick={() => {
        history.push("/settings");
      }}
      size="large">
      <SettingsIcon />
    </IconButton>
  );
}

export default SettingsButton;
