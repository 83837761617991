import { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import SensorDotSelectionDialog from "./Plot/SensorDotSelectionDialog";
import { CloudCircle } from "@mui/icons-material";

interface SelectSensorDotButtonProps {
  disabled: boolean;
  onOK(dotId: string): void;
}
function SelectSensorDotButton(props: SelectSensorDotButtonProps) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Tooltip title="Select a Sensor Dot using the recording-service">
      <span>
        <IconButton
          data-cy="select-sensor-dot"
          disabled={props.disabled}
          onClick={async () => {
            setOpen(true);
          }}
          color="primary"
        >
          <CloudCircle />
        </IconButton>
        <SensorDotSelectionDialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onOK={(dotId) => {
            setOpen(false);
            props.onOK(dotId);
          }}
        />
      </span>
    </Tooltip>
  );
}

export default SelectSensorDotButton;
