import {
  GetProduct,
  IdWithName,
  QualityPoint,
} from "@byteflies/odoo-typescript";
import { ExtendedProduct } from "./erp/manufacturing/OdooOperationsService";
import {
  Product,
  Tracking,
  ProductType,
  QualityCheck,
} from "./erp/manufacturing/Product";
import {
  isBlank,
  isNotBlank,
  substringAfter,
  substringBefore,
  substringBetween,
} from "./StringUtils";
import { id, name } from "./erp/odoo/OdooUtils";
import { DotConfiguration, Group } from "./Plot/cloudApi";
import {
  WI_BUILD_DOT,
  WI_VISUAL_INSPECTION,
  WI_SENSOR_DOT_HW_TEST,
  WI_DOT_SIGNAL_QUALITY,
  WI_WRITE_NFC_TAG,
  WI_CALIBRATE_DOT,
  WI_BLE_TEST,
  WI_24_HR_TEST,
  WI_24_HR_TEST_SERVICING,
  WI_PACKAGE_DOT,
  WI_CONFIGURE_ECG_DOTS,
  WI_SNIPEIT,
  WI_DOCK_TEST,
  WI_PRINT_LABEL,
  WI_BUILD_DOCK,
  WI_BUILD_NAKED_4WIRE_CRADLE,
  WI_TEST_4WIRE_CRADLE_WITH_FILTER,
  WI_TEST_4WIRE_CRADLE,
  WI_PACKAGE_4WIRE_CRADLE,
  WI_BUILD_NAKED_2SNAP_CRADLE,
  WI_TEST_2SNAP_CRADLE,
  WI_PACKAGE_2SNAP_CRADLE,
  WI_BUILD_NAKED_MOTION_CRADLE,
  WI_TEST_MOTION_CRADLE,
  WI_PACKAGE_BF_KIT,
  WI_PACKAGE_ECG_ADHESIVE,
  WI_PACKAGE_EEG_ADHESIVE,
  WI_BUILD_NAKED_4SNAP_CRADLE,
  WI_TEST_4SNAP_CRADLE,
  WI_PACKAGE_4SNAP_CRADLE,
  WI_BUILD_NAKED_3SNAP_CRADLE_LEFT,
  WI_TEST_3SNAP_CRADLE_LEFT,
  WI_PACKAGE_3SNAP_CRADLE_LEFT,
  WI_BUILD_NAKED_3SNAP_CRADLE_RIGHT,
  WI_TEST_3SNAP_CRADLE_RIGHT,
  WI_PACKAGE_3SNAP_CRADLE_RIGHT,
  WI_CONFIGURE_DOCK_CARDIO,
  WI_PACKAGE_CARDIO_BOX,
  WI_CONFIGURE_DOCK_EPICARE,
  WI_CONFIGURE_EEG_DOTS,
  WI_CONFIGURE_ECG_DOTS_EPICARE,
  WI_DOCK_VISUAL_INSPECTION,
  WI_FACTORY_RESET,
  WI_PACKAGE_EPICARE_ABSCENCE_BOX,
  WI_DOCK_INTERFACE_TEST,
  WI_WIFI_TEST,
  WI_DOCK_FIRMWARE_UPDATE,
  WI_DOCK_CHECK_RECORDINGS,
  WI_DOCK_CLEANING,
  WI_PRINT_LABEL_DOCK_SERVICING,
  WI_DOCK_BAT_TEST,
  WI_CONFIGURE_ECG_DOTS_DUAL_CHANNEL,
  WI_PACKAGE_ADHESIVE,
  WI_HARDWARE_VERSION_IDENTIFICATION,
  WI_DOT_VISUAL_INSPECTION,
  WI_NFC_IDENTIFICATION,
  WI_DOT_EXTERNAL_CLEANING,
  WI_BUILD_NAKED_ECG_3SNAP_CRADLE,
  WI_WRITE_CRADLE_ID_CHIP,
  WI_TEST_CRADLE_RESISTANCES,
  WI_PACKAGE_CARDIO_MULTI_LEAD_LINEAR_BOX,
  WI_CONFIGURE_EPICARE_FOCAL_BOX_DOTS,
  WI_SENSOR_DOT_UPLOAD_RECORDINGS,
  WI_DOCK_AUTO_VERSION_CHECK,
  WI_LINE_CLEARANCE_END,
  WI_LINE_CLEARANCE_START,
  WI_CRADLE_VISUAL_INSPECTION,
  WI_4WIRESNAP_TEST_WITH_DEVICE,
  WI_VERIFY_CRADLE_ID_CHIP,
  WI_CLEAN_CRADLE_CONNECTORS,
  WI_BUILD_24H_HOLTER,
  WI_DOT_VISUAL_INSPECTION_POGOPINS,
  WI_DOT_VISUAL_INSPECTION_CRACKS,
  WI_DOT_VISUAL_INSPECTION_WELD_SEAM,
  WI_CRADLE_VISUAL_INSPECTION_CONNECTORS,
  WI_CRADLE_VISUAL_INSPECTION_POGOPADS,
  WI_CRADLE_VISUAL_INSPECTION_CRACKS,
  WI_CRADLE_VISUAL_INSPECTION_WELD_SEAM,
  WI_DOCK_VISUAL_INSPECTION_INTERFACE,
  WI_DOCK_VISUAL_INSPECTION_SCREWS,
  WI_DOCK_VISUAL_INSPECTION_CASING,
  WI_DOCK_VISUAL_INSPECTION_BUMPER_FEET,
  WI_DOCK_PROVISIONING,
  WI_CONFIGURE_DOCK_CARDIO_STARTER_KITS,
  WI_BUILD_24H_STARTER_KIT,
  WI_BUILD_VITAL_SIGNS_VS4,
  WI_BUILD_VITAL_SIGNS_VS3,
  WI_BUILD_VITAL_SIGNS_VS2,
  WI_BUILD_VITAL_SIGNS_VS1,
  WI_PAIR_AD_MEDICAL_UA656BLE,
  WI_CONNECT_DOCK_MOBILE_WIFI,
  WI_BATTERY_HEALTH_CHECK,
  WI_THIRD_PARTY_DEVICE_VISUAL_INSPECTION,
  WI_THIRD_PARTY_READABILITY_LABEL,
  WI_THIRD_PARTY_CHECK_ACCESSORIES,
  WI_THIRD_PARTY_CHECK_BATTERY,
  WI_THIRD_PARTY_DEVICE_CONFIGURE,
  WI_THIRD_PARTY_DEVICE_PROPER_FUNCTIONING,
  WI_THIRD_PARTY_DEVICE_FACTORY_RESET,
  WI_THIRD_PARTY_DEVICE_CLEANING,
  WI_THIRD_PARTY_PROTECTIVE_PACKAGING,
} from "./WorkInstructions";

export const BF_NAKED_DOT = "BF13000178";
export const GTIN_SENSOR_DOT = "05430001718003";

export const BF_PACKAGED_DOT = "BF13000345";
export const BF_NAKED_DOCK = "BF13000179";
export const GTIN_DOCKING_STATION = "05430001718010";
export const BF_NAKED_4WIRE_CRADLE = "BF13000180";
export const BF_PACKAGED_4WIRE_CRADLE = "BF13000429";
export const BF_NAKED_MOTION_CRADLE = "BF13000367";

export const BF_NAKED_2SNAPCRADLE = "BF13000249";
export const BF_PACKAGED_2SNAPCRADLE = "BF13000339";
export const BF_PACKAGED_ECG_3SNAPCRADLE = "BF13000498";
export const BF_VIRTUAL_BYTEFLIES_KIT = "BF13000500";
export const BF_VIRTUAL_BYTEFLIES_KIT_WITH_IFU = "BF13000520";
export const GTIN_2SNAPCRADLE = "05430001718041";
export const GTIN_ECG_3SNAPCRADLE = "05430001718256";

export const BF_NAKED_3SNAPCRADLE_LEFT = "BF13000353";
export const BF_PACKAGED_3SNAPCRADLE_LEFT = "BF13000354";
export const BF_NAKED_3SNAPCRADLE_RIGHT = "BF13000351";
export const BF_PACKAGED_3SNAPCRADLE_RIGHT = "BF13000352";
export const BF_NAKED_4SNAPCRADLE = "BF13000325";
export const BF_PACKAGED_4SNAPCRADLE = "BF13000341";
export const BF_PACKAGED_MOTION_CRADLE = "BF13000340";
export const BF_BYTEFLIES_KIT = "BF13000183";
export const BF_BYTEFLIES_KIT_MODEL_2 = "BF13000438";
export const BF_EPICARE_BOX = "BF13000357";
export const BF_EPICARE_FOCAL_LEFT = "BF13000358";
export const BF_EPICARE_FOCAL_RIGHT = "BF13000359";
export const BF_CARDIOCARE_BOX = "BF13000371";
export const BF_CARDIOCARE_MULTI_LEAD_LINEAR_BOX = "BF13000503";
export const BF_COVIDCARE_BOX_1 = "BF13000343";
export const BF_COVIDCARE_BOX_2 = "BF13000344";
export const BF_NAKED_2SNAPCRADLE_110 = "BF13000382";
export const BF_NAKED_ECG_3SNAPCRADLE = "BF13000497";
export const BF_NAKED_3WIRECRADLE_LEFT = "BF13000513";
export const BF_NAKED_3WIRECRADLE_RIGHT = "BF13000512";
export const BF_PACKAGED_3WIRECRADLE_LEFT = "BF13000515";
export const BF_PACKAGED_3WIRECRADLE_RIGHT = "BF13000514";

export const BF_DOUBLE_SIDED_ADHESIVE = "BF13000144";
export const BF_PACKAGED_DOUBLE_SIDED_ADHESIVE = "BF13000368";

export const BF_NAKED_ECG_ADHESIVE = "BF13000248";
export const BF_NAKED_ECG_ADHESIVE_MULTI_LEAD_LINEAR = "BF13000490";
export const BF_NAKED_ECG_ADHESIVE_SINGLE_LEAD_EXTRA = "BF13000587";
export const BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD = "BF13000430";
export const BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD_2PCS = "BF13000526";
export const BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR = "BF13000491";
export const BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR_2PCS = "BF13000525";
export const BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD_EXTRA_2PCS = "BF24000735";

export const BF_NAKED_EEG_ADHESIVE_LEFT = "BF13000531";
export const BF_NAKED_EEG_ADHESIVE_RIGHT = "BF13000532";
export const BF_PACKAGED_EEG_ADHESIVE = "BF13000431";

export const BF_POWER_SUPPLY = "BF13000165";
export const BF_USB_CABLE_1M = "BF13000004";
export const BF_USB_CABLE_1_5M = "BF13000388";
export const BF_XCARE_BOX = "BF13000350";

export const BF_CARDIOCARE_PATIENT_GUIDE_NL = "BF13000427";
export const BF_CARDIOCARE_PATIENT_GUIDE_FR = "BF13000440";
export const BF_CARDIOCARE_PATIENT_DIARY_NL = "BF13000453";
export const BF_CARDIOCARE_PATIENT_DIARY_FR = "BF13000454";
export const BF_CARDIOCARE_MULTI_LEAD_LINEAR_PATIENT_GUIDE_NL = "BF13000505";
export const BF_CARDIOCARE_MULTI_LEAD_LINEAR_PATIENT_GUIDE_FR = "BF13000506";
export const BF_CARDIOCARE_MULTI_LEAD_LINEAR_PATIENT_DIARY_NL = "BF13000507";
export const BF_CARDIOCARE_MULTI_LEAD_LINEAR_PATIENT_DIARY_FR = "BF13000508";
export const BF_CARDIOCARE_PATIENT_IDCARD_NL = "BF13000455";
export const BF_CARDIOCARE_PATIENT_IDCARD_FR = "BF13000456";
export const BF_CARDIOCARE_24H_HOLTER = "BF13000537";
export const BF_CARDIOCARE_NON_LINEAR_24H_HOLTER = "BF13000616";
export const BF_CARDIOCARE_24H_STARTER_KIT = "BF13000542";
export const BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER = "BF13000597";

export const BF_VITAL_SIGNS_VS1 = "BF13000545";
export const BF_VITAL_SIGNS_VS2 = "BF13000570";
export const BF_VITAL_SIGNS_VS3 = "BF13000571";
export const BF_VITAL_SIGNS_VS4 = "BF13000572";
export const BF_VITAL_SIGNS_VS6 = "BF24000715";
export const BF_VITAL_SIGNS_VS8 = "BF24000730";

export const BF_BLOOD_PRESSURE_MONITOR = "BF13000550";
export const BF_PULSE_OXIMETER = "BF13000563";
export const BF_THERMOMETER = "BF13000560";
export const BF_WEIGHING_SCALE = "BF13000549";

export const CARDIOCARE_STARTER_KITS_GROUP: Group = {
  id: "fbf43d90-5880-11ee-8e7b-c5f74f8cee6f",
  name: "CardioCare@Home - starter kits",
};

export const CARDIOCARE_GROUP: Group = {
  id: "57990310-7b39-11eb-b455-d75afa717c56",
  description: "CardioCare@Home group",
  name: "CardioCare@Home",
};

export const EPICARE_GROUP: Group = {
  id: "1cff8560-596c-11eb-b6ce-65f845405daf",
  description: "EpiCare@Home group",
  name: "EpiCare@Home",
};

export const COVIDCARE_GROUP: Group = {
  id: "5fa4f850-0494-11eb-80dc-9b4efce07665",
  description: "CovidCare@Home group",
  name: "CovidCare@Home",
};

export const MANUFACTURING_GROUP: Group = {
  id: "2f355a60-4c03-11ea-9f2d-0949ce20b25c",
  name: "Manufacturing",
};

export const BATTERY_TEST_MANUFACTURING_GROUP: Group = {
  id: "2df67e70-5bd6-11eb-af93-cb2458965bec",
  name: "BatteryTestManufacturing",
};

export const CARDIOCARE_LOADING_DOCKS_GROUP: Group = {
  id: "1b1795a0-5881-11ee-8e7b-c5f74f8cee6f",
  name: "CardioCare@Home - loading docks",
};

export type NamedDotConfiguration = {
  name: string;
  configuration: DotConfiguration[];
};

export const ONE_CHANNEL_ECG: NamedDotConfiguration = {
  name: "One Channel ECG",
  configuration: [
    {
      channel: 2,
      type: "ECG",
      gain: 200,
      fs: 250,
    },
    {
      fs: 50,
      type: "ACC",
    },
  ],
};

export const TWO_CHANNEL_ECG: NamedDotConfiguration = {
  name: "Two Channel ECG",
  configuration: [
    {
      type: "ECG",
      fs: 250,
      channel: 1,
    },
    {
      type: "ECG",
      fs: 250,
      channel: 2,
    },
    {
      type: "ACC",
      fs: 50,
    },
  ],
};

export const ONE_CHANNEL_ECG_WITH_GYRO: NamedDotConfiguration = {
  name: "One Channel ECG with Gyroscope",
  configuration: [
    {
      type: "ECG",
      fs: 250,
      channel: 2,
    },
    {
      type: "GYR",
      fs: 25,
    },
    {
      type: "ACC",
      fs: 50,
    },
  ],
};

export const TWO_CHANNEL_EEG_WITH_GYRO: NamedDotConfiguration = {
  name: "Two Channel EEG with Gyroscope",
  configuration: [
    {
      type: "EEG",
      fs: 250,
      channel: 1,
    },
    {
      type: "EEG",
      fs: 250,
      channel: 2,
    },
    {
      type: "GYR",
      fs: 25,
    },
    {
      type: "ACC",
      fs: 50,
    },
  ],
};

export const COVIDCARE_ECG: NamedDotConfiguration = {
  name: "ECG(ch 2), ACC (100)",
  configuration: [
    {
      type: "ECG",
      fs: 250,
      channel: 2,
    },
    {
      type: "ACC",
      fs: 100,
    },
  ],
};

export function configurationsMatch(
  c1: NamedDotConfiguration,
  c2: NamedDotConfiguration
) {
  if (
    c2 === undefined ||
    c2.configuration === undefined ||
    c2.configuration.length === 0
  ) {
    return false;
  } else if (
    c1 === undefined ||
    c1.configuration === undefined ||
    c1.configuration.length === 0
  ) {
    return false;
  } else if (c1.configuration.length !== c2.configuration.length) {
    return false;
  }

  for (const conf of c2.configuration) {
    const sameChannel = c1.configuration.find(
      (c) =>
        c.channel === conf.channel && c.type === conf.type && c.fs === conf.fs
    );
    if (sameChannel === undefined) {
      return false;
    }
  }
  return true;
}

export interface DeviceDescription {
  udi?: string;
  gtin?: string;
  serial?: string;
  deviceId?: string;
  versionNumber?: string;
  productionDate?: Date;
}

export const getProductVersion = (product: Product): string | undefined => {
  if (product === undefined) {
    return undefined;
  }

  const intRef = getProductBfNumber(product);
  if (intRef === undefined || intRef === "") {
    return undefined;
  }
  for (const product of products) {
    for (const r of product.internalReference) {
      if (r === intRef && product.version) {
        return product.version;
      }
    }
  }

  if (product.name === undefined) {
    return undefined;
  }

  const version =
    product.name.indexOf(" - ") !== -1
      ? substringAfter(product.name, " - ")
      : undefined;
  if (version !== undefined) {
    return version;
  }

  const lastSpace = product.name.lastIndexOf(" ");
  const lastDot = product.name.lastIndexOf(".");
  if (lastSpace !== -1 && lastDot !== -1 && lastSpace < lastDot) {
    const version = product.name.substring(lastSpace + 1).trim();
    const dot1 = version.indexOf(".");
    const dot2 = version.lastIndexOf(".");
    if (dot1 !== -1 && dot2 !== -1 && dot1 < dot2) {
      return version;
    }
  }
  return undefined;
};

export const getProductNameWithoutInternalReference = (
  name: string
): string | undefined => {
  if (name !== undefined && name.indexOf("[") === 0 && name.indexOf("] ") > 1) {
    return substringAfter(name, "] ");
  }
  return name;
};

export function getProductNameWithoutInternalReferenceFromId(
  product_id: IdWithName
): string | undefined {
  if (Array.isArray(product_id)) {
    const name = product_id[1];
    if (name !== undefined) {
      const open = name.indexOf("[");
      const close = name.indexOf("] ");
      if (open !== -1 && close !== -1 && open < close) {
        return name.substring(close + 2).trim();
      }
    }
  }
  return undefined;
}

export const getProductInternalReferenceFromName = (
  name: string
): string | undefined => {
  if (name !== undefined && name.indexOf("[") === 0 && name.indexOf("]") > 1) {
    return substringBetween(name, "[", "]");
  }
  return undefined;
};

export const isTrackedByUniqueSerial = (product: GetProduct): boolean => {
  const tracking = product.tracking;
  if (tracking !== undefined) {
    return tracking === "serial";
  }

  if (
    product.default_code === BF_NAKED_2SNAPCRADLE ||
    product.default_code === BF_NAKED_2SNAPCRADLE_110 ||
    product.default_code === BF_NAKED_3SNAPCRADLE_LEFT ||
    product.default_code === BF_NAKED_3SNAPCRADLE_RIGHT ||
    product.default_code === BF_NAKED_3WIRECRADLE_LEFT ||
    product.default_code === BF_NAKED_3WIRECRADLE_RIGHT ||
    product.default_code === BF_NAKED_4SNAPCRADLE ||
    product.default_code === BF_NAKED_ECG_3SNAPCRADLE ||
    product.default_code === BF_CARDIOCARE_BOX ||
    product.default_code === BF_CARDIOCARE_MULTI_LEAD_LINEAR_BOX ||
    product.default_code === BF_EPICARE_BOX ||
    product.default_code === BF_EPICARE_FOCAL_LEFT ||
    product.default_code === BF_EPICARE_FOCAL_RIGHT ||
    product.default_code === BF_BYTEFLIES_KIT ||
    product.default_code === BF_BYTEFLIES_KIT_MODEL_2 ||
    product.default_code === BF_NAKED_DOT ||
    product.default_code === BF_NAKED_DOCK ||
    product.default_code === BF_CARDIOCARE_24H_HOLTER ||
    product.default_code === BF_CARDIOCARE_NON_LINEAR_24H_HOLTER ||
    product.default_code === BF_CARDIOCARE_24H_STARTER_KIT ||
    product.default_code === BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER ||
    product.default_code === BF_VITAL_SIGNS_VS1 ||
    product.default_code === BF_VITAL_SIGNS_VS2 ||
    product.default_code === BF_VITAL_SIGNS_VS3 ||
    product.default_code === BF_VITAL_SIGNS_VS4 ||
    product.default_code === BF_VITAL_SIGNS_VS6 ||
    product.default_code === BF_VITAL_SIGNS_VS8 ||
    product.default_code === BF_BLOOD_PRESSURE_MONITOR ||
    product.default_code === BF_PULSE_OXIMETER ||
    product.default_code === BF_THERMOMETER ||
    product.default_code === BF_WEIGHING_SCALE
  ) {
    return true;
  }
  return product.tracking === "serial";
};

export const productRefToRoutecardProduct = (
  product_id: IdWithName
): ExtendedProduct => {
  if (!Array.isArray(product_id)) {
    throw new Error(`product_id is invalid: ${product_id}`);
  }

  const pname = getProductNameWithoutInternalReference(name(product_id)!);
  const internalRef = getProductInternalReferenceFromName(name(product_id)!);
  const tracking = bfNumberToTracking(internalRef!);
  const pr: ExtendedProduct = {
    id: id(product_id),
    name: pname!,
    internalReference: internalRef,
    inventory: { traceability: { tracking: tracking } },
    type: ProductType.StorableProduct,
  } as ExtendedProduct;
  return pr;
};

export const productToTracking = (product: Product) => {
  if (product === undefined) {
    throw new Error("Product is undefined");
  }
  const odooTracking = product?.inventory?.traceability?.tracking;
  if (odooTracking !== undefined) {
    return odooTracking;
  }

  const bfNumber = product?.internalReference;
  if (isNotBlank(bfNumber)) {
    for (const product of products) {
      const internalReferences = product.internalReference;
      for (const internalReference of internalReferences) {
        if (internalReference === bfNumber && product.tracking !== undefined) {
          return product.tracking;
        }
      }
    }

    const tracking = bfNumberToTracking(bfNumber);
    if (tracking !== undefined) {
      return tracking;
    }
  }
  return undefined;
};

const bfNumberToTracking = (bfNumber: string): Tracking => {
  switch (bfNumber) {
    case BF_NAKED_DOT:
    case BF_PACKAGED_DOT:
    case BF_NAKED_DOCK:
    case "BF13000342": // old BF number of byteflies kit
    case BF_NAKED_4WIRE_CRADLE:
    case "BF13000347": // old BF number of Packaged ExG Cradle / 4-wire cradle
    case BF_PACKAGED_2SNAPCRADLE:
    case BF_PACKAGED_4WIRE_CRADLE:
    case BF_BYTEFLIES_KIT:
    case BF_BYTEFLIES_KIT_MODEL_2:
    case BF_NAKED_2SNAPCRADLE:
    case BF_NAKED_2SNAPCRADLE_110:
    case BF_NAKED_3SNAPCRADLE_LEFT:
    case BF_NAKED_ECG_3SNAPCRADLE:
    case BF_PACKAGED_3SNAPCRADLE_LEFT:
    case BF_NAKED_3SNAPCRADLE_RIGHT:
    case BF_PACKAGED_3SNAPCRADLE_RIGHT:
    case BF_NAKED_4SNAPCRADLE:
    case BF_PACKAGED_4SNAPCRADLE:
    case BF_CARDIOCARE_BOX:
    case BF_CARDIOCARE_MULTI_LEAD_LINEAR_BOX:
    case BF_CARDIOCARE_24H_STARTER_KIT:
    case BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER:
    case BF_VIRTUAL_BYTEFLIES_KIT:
    case BF_EPICARE_BOX:
    case BF_EPICARE_FOCAL_LEFT:
    case BF_EPICARE_FOCAL_RIGHT:
    case BF_NAKED_3WIRECRADLE_LEFT:
    case BF_PACKAGED_3WIRECRADLE_LEFT:
    case BF_NAKED_3WIRECRADLE_RIGHT:
    case BF_PACKAGED_3WIRECRADLE_RIGHT:
    case BF_CARDIOCARE_24H_HOLTER:
    case BF_CARDIOCARE_NON_LINEAR_24H_HOLTER:
    case BF_VITAL_SIGNS_VS1:
    case BF_VITAL_SIGNS_VS2:
    case BF_VITAL_SIGNS_VS3:
    case BF_VITAL_SIGNS_VS4:
    case BF_VITAL_SIGNS_VS6:
    case BF_VITAL_SIGNS_VS8:
    case BF_BLOOD_PRESSURE_MONITOR:
    case BF_PULSE_OXIMETER:
    case BF_THERMOMETER:
    case BF_WEIGHING_SCALE:
      return Tracking.ByUniqueSerialNumber;
    default:
      return Tracking.ByLots;
  }
};

export interface ProductDescription {
  name?: string;
  gtin: string;
  internalReference: string[];
  tracking: Tracking;
  version?: string;
}

export const isCE = function (bfNumber: string, vn: string) {
  switch (bfNumber) {
    case BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD:
    case BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD_2PCS:
      return true;
    case BF_PACKAGED_EEG_ADHESIVE:
      return vn.toLowerCase() === "4b" ? false : true;
    case BF_PACKAGED_3SNAPCRADLE_LEFT:
    case BF_PACKAGED_3SNAPCRADLE_RIGHT:
    case BF_PACKAGED_4SNAPCRADLE:
    case BF_PACKAGED_4WIRE_CRADLE:
    case BF_PACKAGED_MOTION_CRADLE:
      // switch (vn) {
      //   case "1.1.0":
      //   case "1.2.0":
      //     return false;
      //   default:
      return true;
    //}
    default:
      return true;
  }
};

export const products: ProductDescription[] = [
  {
    name: "Docking Station",
    gtin: "05430001718010",
    internalReference: [BF_NAKED_DOCK],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "4-wire cradle",
    gtin: "05430001718027",
    internalReference: [BF_PACKAGED_4WIRE_CRADLE, BF_NAKED_4WIRE_CRADLE],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Sensor Dot",
    gtin: GTIN_SENSOR_DOT,
    internalReference: [BF_NAKED_DOT, BF_PACKAGED_DOT],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Byteflies Kit",
    gtin: "05430001718034",
    internalReference: [BF_BYTEFLIES_KIT],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Byteflies Kit Model 2",
    gtin: "05430001718218",
    internalReference: [BF_BYTEFLIES_KIT_MODEL_2],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Virtual Byteflies Kit",
    gtin: "05430001718034",
    internalReference: [
      BF_VIRTUAL_BYTEFLIES_KIT,
      BF_VIRTUAL_BYTEFLIES_KIT_WITH_IFU,
    ],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "2-snap cradle",
    gtin: GTIN_2SNAPCRADLE,
    internalReference: [
      BF_NAKED_2SNAPCRADLE,
      BF_NAKED_2SNAPCRADLE_110,
      BF_PACKAGED_2SNAPCRADLE,
    ],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "ECG 3-snap cradle",
    gtin: GTIN_ECG_3SNAPCRADLE,
    internalReference: [BF_NAKED_ECG_3SNAPCRADLE, BF_PACKAGED_ECG_3SNAPCRADLE],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "3-snap cradle left",
    gtin: "05430001718126",
    internalReference: [
      BF_NAKED_3SNAPCRADLE_LEFT,
      BF_PACKAGED_3SNAPCRADLE_LEFT,
    ],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "3-snap cradle right",
    gtin: "05430001718133",
    internalReference: [
      BF_NAKED_3SNAPCRADLE_RIGHT,
      BF_PACKAGED_3SNAPCRADLE_RIGHT,
    ],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "3-wire cradle left",
    gtin: "05430001718287",
    internalReference: [
      BF_NAKED_3WIRECRADLE_LEFT,
      BF_PACKAGED_3WIRECRADLE_LEFT,
    ],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "3-wire cradle right",
    gtin: "05430001718294",
    internalReference: [
      BF_NAKED_3WIRECRADLE_RIGHT,
      BF_PACKAGED_3WIRECRADLE_RIGHT,
    ],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "ECG adhesive",
    gtin: "05430001718058",
    internalReference: [
      BF_NAKED_ECG_ADHESIVE,
      BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD,
      BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD_2PCS,
    ],
    tracking: Tracking.ByLots,
  },
  {
    name: "ECG Adhesive Multi Lead Linear",
    gtin: "05430001718263",
    internalReference: [
      BF_NAKED_ECG_ADHESIVE_MULTI_LEAD_LINEAR,
      BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR,
      BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR_2PCS,
    ],
    tracking: Tracking.ByLots,
  },
  {
    name: "ECG Adhesive Single Lead Extra",
    gtin: "05430001718362",
    internalReference: [
      BF_NAKED_ECG_ADHESIVE_SINGLE_LEAD_EXTRA,
      BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD_EXTRA_2PCS,
    ],
    tracking: Tracking.ByLots,
  },
  {
    name: "Motion cradle",
    gtin: "05430001718065",
    internalReference: ["BF13000367"],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Packaged motion cradle",
    gtin: "05430001718065",
    internalReference: [BF_PACKAGED_MOTION_CRADLE],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "EEG Adhesive",
    gtin: "05430001718089",
    internalReference: [
      BF_NAKED_EEG_ADHESIVE_LEFT,
      BF_NAKED_EEG_ADHESIVE_RIGHT,
      BF_PACKAGED_EEG_ADHESIVE,
    ],
    tracking: Tracking.ByLots,
  },
  {
    name: "4-snap cradle",
    gtin: "05430001718119",
    internalReference: [BF_NAKED_4SNAPCRADLE, BF_PACKAGED_4SNAPCRADLE],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "CovidCare@Home box",
    gtin: "05430001718096",
    internalReference: [BF_COVIDCARE_BOX_1],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "CovidCare@Home box",
    gtin: "05430001718096",
    internalReference: [BF_COVIDCARE_BOX_2],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "CovidCare@Home box",
    gtin: "05430001718102", // No longer used but still needs to be found
    internalReference: [BF_COVIDCARE_BOX_2],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "EpiCare@Home box",
    gtin: "05430001718157",
    internalReference: ["BF13000357"],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "EpiCare@Home box focal left",
    gtin: "05430001718164",
    internalReference: ["BF13000358"],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "EpiCare@Home box focal right",
    gtin: "05430001718171",
    internalReference: ["BF13000359"],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Double-sided adhesive",
    gtin: "05430001718140",
    internalReference: [BF_DOUBLE_SIDED_ADHESIVE],
    tracking: Tracking.ByLots,
  },
  {
    name: "Packaged double-sided adhesive",
    gtin: "05430001718140",
    internalReference: [BF_PACKAGED_DOUBLE_SIDED_ADHESIVE],
    tracking: Tracking.ByLots,
  },
  {
    name: "CardioCare@Home box",
    gtin: "05430001718188",
    internalReference: ["BF13000371"],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "CardioCare@Home Multi Lead Box",
    gtin: "05430001718270",
    internalReference: [BF_CARDIOCARE_MULTI_LEAD_LINEAR_BOX],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "CardioCare@Home 24h Holter",
    gtin: "05430001718317",
    internalReference: [BF_CARDIOCARE_24H_HOLTER],
    tracking: Tracking.ByUniqueSerialNumber,
    version: "1.0.1",
  },
  {
    name: "CardioCare@Home Non-Linear 24h Holter",
    gtin: "05430001718393",
    internalReference: [BF_CARDIOCARE_NON_LINEAR_24H_HOLTER],
    tracking: Tracking.ByUniqueSerialNumber,
    version: "0.1.0",
  },
  {
    name: "CardioCare@Home 24h Starter Kit",
    gtin: "05430001718300",
    internalReference: [BF_CARDIOCARE_24H_STARTER_KIT],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "CardioCare@Home 24h Starter Kit with WiFi Router",
    gtin: "05430001718386",
    internalReference: [BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Vital Signs VS-1",
    gtin: "05430001718324",
    internalReference: [BF_VITAL_SIGNS_VS1],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Vital Signs VS-2",
    gtin: "05430001718331",
    internalReference: [BF_VITAL_SIGNS_VS2],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Vital Signs VS-3",
    gtin: "05430001718348",
    internalReference: [BF_VITAL_SIGNS_VS3],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Vital Signs VS-4",
    gtin: "05430001718355",
    internalReference: [BF_VITAL_SIGNS_VS4],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Vital Signs VS-6",
    gtin: "05430001718409",
    internalReference: [BF_VITAL_SIGNS_VS6],
    tracking: Tracking.ByUniqueSerialNumber,
  },
  {
    name: "Vital Signs VS-8",
    gtin: "05430001718416",
    internalReference: [BF_VITAL_SIGNS_VS8],
    tracking: Tracking.ByUniqueSerialNumber,
  },
];

const getProductBfNumber = (product: Product): string | undefined => {
  if (
    product.internalReference !== undefined &&
    product.internalReference.trim() !== ""
  ) {
    return product.internalReference;
  } else if (product.name !== undefined && product.name.startsWith("[")) {
    return substringBetween(product.name, "[", "]");
  } else {
    return undefined;
  }
};

export const productToGtin = (product: Product): string => {
  const intRef = getProductBfNumber(product);
  if (intRef === undefined || intRef === "") {
    throw new Error(
      `Unable to find registry type for ${product.name} (No internal reference number)`
    );
  }
  for (const product of products) {
    for (const r of product.internalReference) {
      if (r === intRef) {
        return product.gtin;
      }
    }
  }
  throw new Error(`Unable to find registry type for ${intRef}`);
};

export function uniqueArray(values: any[]): any[] {
  const result = [];
  const map = new Map();
  for (const item of values) {
    const h = hash(JSON.stringify(item));
    if (!map.has(h)) {
      map.set(h, true); // set any value to Map
      result.push(item);
    }
  }
  return result;
}

export const isQualityCheckAutomatic = function (qc: QualityCheck) {
  switch (qualityCheckCode(qc)) {
    case WI_NFC_IDENTIFICATION:
    case WI_DOCK_AUTO_VERSION_CHECK:
    case WI_DOCK_BAT_TEST:
    case WI_DOCK_FIRMWARE_UPDATE:
    case WI_WIFI_TEST:
    case WI_DOCK_CHECK_RECORDINGS:
    case WI_FACTORY_RESET:
    case WI_BATTERY_HEALTH_CHECK:
    case WI_24_HR_TEST:
    case WI_24_HR_TEST_SERVICING:
      return true;
    default:
      return false;
  }
};

export const isScannableLotProduct = function (ref?: string) {
  switch (ref) {
    case BF_PACKAGED_DOUBLE_SIDED_ADHESIVE:
    case BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD:
    case BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD_2PCS:
    case BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR:
    case BF_PACKAGED_ECG_ADHESIVE_MULTI_LEAD_LINEAR_2PCS:
    case BF_PACKAGED_ECG_ADHESIVE_SINGLE_LEAD_EXTRA_2PCS:
    case BF_PACKAGED_EEG_ADHESIVE:
      return true;
    default:
      return false;
  }
};

export const isCompoundProduct = function (ref?: string) {
  switch (ref) {
    case BF_CARDIOCARE_24H_HOLTER:
    case BF_CARDIOCARE_NON_LINEAR_24H_HOLTER:
      return true;
    default:
      return false;
  }
};

export const isPreBomQualityCheck = function (qc: QualityCheck) {
  switch (qualityCheckCode(qc)) {
    case WI_LINE_CLEARANCE_START:
    case WI_BUILD_DOCK:
    case WI_DOCK_PROVISIONING:
    case WI_CALIBRATE_DOT:
      return true;
    default:
      return false;
  }
};

export const isScrappableQualityCheck = function (qc: QualityCheck) {
  switch (qualityCheckCode(qc)) {
    case WI_DOCK_AUTO_VERSION_CHECK:
    case WI_CALIBRATE_DOT:
    case WI_24_HR_TEST_SERVICING:
    case WI_BATTERY_HEALTH_CHECK:
    case WI_WRITE_NFC_TAG:
    case WI_BUILD_DOT:
    case WI_HARDWARE_VERSION_IDENTIFICATION:
    case WI_BUILD_NAKED_ECG_3SNAP_CRADLE:
    case WI_DOT_VISUAL_INSPECTION_POGOPINS:
    case WI_DOT_VISUAL_INSPECTION_CRACKS:
    case WI_DOT_VISUAL_INSPECTION_WELD_SEAM:
    case WI_DOCK_VISUAL_INSPECTION_INTERFACE:
    case WI_DOCK_VISUAL_INSPECTION_CASING:
    case WI_CRADLE_VISUAL_INSPECTION_CONNECTORS:
    case WI_CRADLE_VISUAL_INSPECTION_POGOPADS:
    case WI_CRADLE_VISUAL_INSPECTION_CRACKS:
    case WI_CRADLE_VISUAL_INSPECTION_WELD_SEAM:
      return true;
    default:
      return false;
  }
};

export function sortQualityChecks(
  productInternalReference: string,
  checks: QualityCheck[]
): QualityCheck[] {
  if (isBlank(productInternalReference)) {
    return [];
  } else if (
    checks === undefined ||
    !Array.isArray(checks) ||
    checks.length === 0
  ) {
    return [];
  }

  let sorted: string[] = [];

  if (productInternalReference === BF_NAKED_DOT) {
    sorted = [
      WI_LINE_CLEARANCE_START,
      WI_SENSOR_DOT_UPLOAD_RECORDINGS,
      WI_NFC_IDENTIFICATION,
      WI_BATTERY_HEALTH_CHECK,
      WI_HARDWARE_VERSION_IDENTIFICATION,
      WI_BUILD_DOT,
      WI_WRITE_NFC_TAG,
      WI_VISUAL_INSPECTION,
      WI_DOT_VISUAL_INSPECTION_POGOPINS,
      WI_DOT_VISUAL_INSPECTION_CRACKS,
      WI_DOT_VISUAL_INSPECTION_WELD_SEAM,
      WI_DOT_VISUAL_INSPECTION,
      WI_DOT_SIGNAL_QUALITY,
      WI_CALIBRATE_DOT,
      WI_DOT_EXTERNAL_CLEANING,
      WI_SENSOR_DOT_HW_TEST,
      WI_BLE_TEST,
      WI_24_HR_TEST,
      WI_24_HR_TEST_SERVICING,
      WI_PACKAGE_DOT,
      WI_CONFIGURE_ECG_DOTS,
      WI_SNIPEIT,
    ];
  } else {
    sorted = [
      WI_LINE_CLEARANCE_START,
      WI_NFC_IDENTIFICATION,
      WI_BATTERY_HEALTH_CHECK,
      WI_HARDWARE_VERSION_IDENTIFICATION,
      WI_VISUAL_INSPECTION,
      WI_CRADLE_VISUAL_INSPECTION_CONNECTORS,
      WI_CRADLE_VISUAL_INSPECTION_POGOPADS,
      WI_CRADLE_VISUAL_INSPECTION_CRACKS,
      WI_CRADLE_VISUAL_INSPECTION_WELD_SEAM,
      WI_CRADLE_VISUAL_INSPECTION,
      WI_CLEAN_CRADLE_CONNECTORS,
      WI_VERIFY_CRADLE_ID_CHIP,
      WI_DOCK_TEST,
      WI_PRINT_LABEL,
      WI_BUILD_DOT,
      WI_DOT_SIGNAL_QUALITY,
      WI_CALIBRATE_DOT,
      WI_BLE_TEST,
      WI_24_HR_TEST,
      WI_24_HR_TEST_SERVICING,
      WI_PACKAGE_DOT,
      WI_BUILD_DOCK,
      WI_BUILD_NAKED_4WIRE_CRADLE,
      WI_TEST_4WIRE_CRADLE_WITH_FILTER,
      WI_TEST_4WIRE_CRADLE,
      WI_PACKAGE_4WIRE_CRADLE,
      WI_BUILD_NAKED_2SNAP_CRADLE,
      WI_TEST_2SNAP_CRADLE,
      WI_PACKAGE_2SNAP_CRADLE,
      WI_BUILD_NAKED_MOTION_CRADLE,
      WI_TEST_MOTION_CRADLE,
      WI_PACKAGE_BF_KIT,
      WI_PACKAGE_ADHESIVE,
      WI_PACKAGE_ECG_ADHESIVE,
      WI_PACKAGE_EEG_ADHESIVE,
      WI_BUILD_NAKED_4SNAP_CRADLE,
      WI_TEST_4SNAP_CRADLE,
      WI_PACKAGE_4SNAP_CRADLE,
      WI_BUILD_NAKED_3SNAP_CRADLE_LEFT,
      WI_TEST_3SNAP_CRADLE_LEFT,
      WI_PACKAGE_3SNAP_CRADLE_LEFT,
      WI_BUILD_NAKED_3SNAP_CRADLE_RIGHT,
      WI_TEST_3SNAP_CRADLE_RIGHT,
      WI_PACKAGE_3SNAP_CRADLE_RIGHT,
      WI_CONFIGURE_DOCK_CARDIO,
      WI_CONFIGURE_DOCK_CARDIO_STARTER_KITS,
      WI_CONFIGURE_ECG_DOTS,
      WI_PACKAGE_CARDIO_BOX,
      WI_CONFIGURE_DOCK_EPICARE,
      WI_CONFIGURE_EEG_DOTS,
      WI_CONFIGURE_ECG_DOTS_EPICARE,
      WI_CONFIGURE_EPICARE_FOCAL_BOX_DOTS,
      WI_PACKAGE_EPICARE_ABSCENCE_BOX,
      WI_CONFIGURE_ECG_DOTS_DUAL_CHANNEL,
      WI_DOCK_CLEANING,
      WI_DOCK_VISUAL_INSPECTION_INTERFACE,
      WI_DOCK_VISUAL_INSPECTION_SCREWS,
      WI_DOCK_VISUAL_INSPECTION_CASING,
      WI_DOCK_VISUAL_INSPECTION_BUMPER_FEET,
      WI_DOCK_VISUAL_INSPECTION,
      WI_DOCK_INTERFACE_TEST,
      WI_DOCK_AUTO_VERSION_CHECK,
      WI_DOCK_BAT_TEST,
      WI_WIFI_TEST,
      WI_DOCK_FIRMWARE_UPDATE,
      WI_DOCK_CHECK_RECORDINGS,
      WI_FACTORY_RESET,
      WI_PRINT_LABEL_DOCK_SERVICING,
      WI_WRITE_CRADLE_ID_CHIP,
      WI_WRITE_NFC_TAG,
      WI_BUILD_NAKED_ECG_3SNAP_CRADLE,
      WI_PACKAGE_CARDIO_MULTI_LEAD_LINEAR_BOX,
      WI_4WIRESNAP_TEST_WITH_DEVICE,
      WI_BUILD_24H_HOLTER,
      WI_CONNECT_DOCK_MOBILE_WIFI,
      WI_PAIR_AD_MEDICAL_UA656BLE,
      WI_BUILD_VITAL_SIGNS_VS1,
      WI_BUILD_VITAL_SIGNS_VS2,
      WI_BUILD_VITAL_SIGNS_VS3,
      WI_BUILD_VITAL_SIGNS_VS4,
      WI_BUILD_24H_STARTER_KIT,
      WI_SNIPEIT,
      WI_THIRD_PARTY_DEVICE_VISUAL_INSPECTION,
      WI_THIRD_PARTY_READABILITY_LABEL,
      WI_THIRD_PARTY_CHECK_ACCESSORIES,
      WI_THIRD_PARTY_CHECK_BATTERY,
      WI_THIRD_PARTY_DEVICE_CONFIGURE,
      WI_THIRD_PARTY_DEVICE_PROPER_FUNCTIONING,
      WI_THIRD_PARTY_DEVICE_FACTORY_RESET,
      WI_THIRD_PARTY_DEVICE_CLEANING,
      WI_THIRD_PARTY_PROTECTIVE_PACKAGING,
      WI_TEST_CRADLE_RESISTANCES,
      WI_LINE_CLEARANCE_END,
    ];
  }

  return checks.sort((a, b) => {
    const aCode = qualityCheckCode(a);
    if (isBlank(aCode)) {
      return -1;
    }
    const bCode = qualityCheckCode(b);
    if (isBlank(bCode)) {
      return 1;
    }
    const aIndex = sorted.indexOf(aCode.toUpperCase());
    const bIndex = sorted.indexOf(bCode.toUpperCase());
    if (aIndex === -1 || bIndex === -1) {
      return aCode.toUpperCase().localeCompare(bCode.toUpperCase());
    } else if (aIndex === bIndex) {
      return 0;
    } else if (aIndex > bIndex) {
      return 1;
    } else {
      return -1;
    }
  });
}

export function qualityCheckCode(qc: QualityCheck) {
  if (isBlank(qc.title)) {
    return "";
  }
  return substringAfter(qc.title!, " - ").trim();
}

export function qualityCheckCodeWithoutProject(qc: QualityCheck): string {
  return qualityCheckCode(qc).split("/").at(-1)!;
}

export function qualityCheckTitleWithoutCode(qc: QualityCheck) {
  if (isBlank(qc.title)) {
    return "";
  }
  return substringBefore(qc.title!, " - ").trim();
}

export function qualityPointCode(qc: QualityPoint) {
  if (isBlank(qc.title)) {
    return "";
  }
  const code = substringAfter(qc.title!, " - ");
  return code.trim();
}

export function matrixUrl(qc: QualityCheck): string | undefined {
  const code = qualityCheckCodeWithoutProject(qc);
  if (code === "") {
    return undefined;
  } else {
    return `https://byteflies.matrixreq.com/pub/QMS?show=${code}`;
  }
}

export function average(n: number[]): number | undefined {
  if (n === undefined || n.length === 0) {
    return undefined;
  } else if (n.length === 1) {
    return n[0];
  }

  let sum = 0;
  for (const v of n) {
    sum += v;
  }

  return sum / n.length;
}

export function max(n: number[]): number | undefined {
  if (n === undefined || n.length === 0) {
    return undefined;
  } else if (n.length === 1) {
    return n[0];
  }

  let m = n[0];
  for (const v of n) {
    if (v >= m) {
      m = v;
    }
  }

  return m;
}

export function min(n: number[]): number | undefined {
  if (n === undefined || n.length === 0) {
    return undefined;
  } else if (n.length === 1) {
    return n[0];
  }

  let m = n[0];
  for (const v of n) {
    if (v <= m) {
      m = v;
    }
  }

  return m;
}

// Hashes a string
function hash(str: string) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = ((hash << 5) - hash + str.charCodeAt(i)) & 0xffffffff;
  }

  return hash;
}

export function odooProductToRoutecardProduct(p: GetProduct) {
  const result: ExtendedProduct = {
    id: p.id,
    company_id: p.company_id === undefined ? -1 : p.company_id[0],
    internalReference: p.default_code,
    type: ProductType.StorableProduct,
    name: p.name,
    canBePurchased: false,
    canBeSold: true,
    inventory: {
      traceability: {
        tracking: odooTrackingToTracking(p.tracking),
      },
    },
    bom_ids:
      p.bom_ids !== undefined && Array.isArray(p.bom_ids)
        ? (p.bom_ids as any as number[])
        : undefined,
    route_ids:
      p.route_ids !== undefined && Array.isArray(p.route_ids)
        ? (p.route_ids as any as number[])
        : undefined,
  };
  return result;
}

export function odooTrackingToTracking(
  tracking: "lot" | "serial" | "none" | undefined
) {
  switch (tracking) {
    case "lot":
      return Tracking.ByLots;
    case "serial":
      return Tracking.ByUniqueSerialNumber;
    case "none":
    default:
      return Tracking.NoTracking;
  }
}

export const getGtinByInternalReference = (internalReference: string) => {
  if (isBlank(internalReference)) {
    return undefined;
  }

  const gtins = products
    .filter((product) => {
      for (const i of product.internalReference) {
        if (i === internalReference) {
          return true;
        }
      }
      return false;
    })
    .map((product) => product.gtin);

  if (gtins.length === 1) {
    return gtins[0];
  }

  const uniqueArrays: string[] = uniqueArray(gtins);
  if (uniqueArrays !== undefined && uniqueArrays.length === 1) {
    return uniqueArrays[0];
  } else if (
    internalReference === BF_COVIDCARE_BOX_2 &&
    uniqueArrays !== undefined &&
    uniqueArrays.length === 2
  ) {
    return uniqueArrays[0];
  }

  return undefined;
};

export const getInternalReferencesByGtin = (gtin: string): string[] => {
  if (isBlank(gtin)) {
    return [];
  }

  const refs = products
    .filter((product) => product.gtin === gtin)
    .map((product) => product.internalReference)
    .flat();

  const uniqueRefs: string[] = uniqueArray(refs);

  return uniqueRefs;
};

export const canCopySerialNumber = (
  sourceBfnumber: string | undefined,
  destinationBfnumber: string | undefined
) => {
  if (isBlank(sourceBfnumber) || isBlank(destinationBfnumber)) {
    return false;
  } else if (
    sourceBfnumber === BF_NAKED_DOT &&
    destinationBfnumber === BF_PACKAGED_DOT
  ) {
    return true;
  } else if (
    destinationBfnumber === BF_COVIDCARE_BOX_1 ||
    destinationBfnumber === BF_COVIDCARE_BOX_2
  ) {
    return true;
  } else if (
    !isPackagedProduct(sourceBfnumber) &&
    isPackagedProduct(destinationBfnumber)
  ) {
    return true;
  } else {
    return false;
  }
};

export const needsDeviceId = (bfnumber: string | undefined) => {
  if (isBlank(bfnumber)) {
    return true;
  } else if (
    bfnumber === BF_NAKED_DOT ||
    bfnumber === BF_PACKAGED_DOT ||
    bfnumber === BF_NAKED_DOCK ||
    bfnumber === BF_COVIDCARE_BOX_1 ||
    bfnumber === BF_COVIDCARE_BOX_2 ||
    bfnumber === BF_NAKED_ECG_3SNAPCRADLE
  ) {
    return true;
  } else {
    return false;
  }
};

export const canBeServiced = (bfnumber: string | undefined) => {
  if (isBlank(bfnumber)) {
    return true;
  } else if (
    bfnumber === BF_CARDIOCARE_BOX ||
    bfnumber === BF_CARDIOCARE_MULTI_LEAD_LINEAR_BOX ||
    bfnumber === BF_EPICARE_BOX ||
    bfnumber === BF_EPICARE_FOCAL_LEFT ||
    bfnumber === BF_EPICARE_FOCAL_RIGHT ||
    bfnumber === BF_COVIDCARE_BOX_2 ||
    bfnumber === BF_COVIDCARE_BOX_1 ||
    bfnumber === BF_BYTEFLIES_KIT ||
    bfnumber === BF_BYTEFLIES_KIT_MODEL_2 ||
    bfnumber === BF_VIRTUAL_BYTEFLIES_KIT ||
    bfnumber === BF_VIRTUAL_BYTEFLIES_KIT_WITH_IFU ||
    bfnumber === BF_CARDIOCARE_24H_HOLTER ||
    bfnumber === BF_CARDIOCARE_NON_LINEAR_24H_HOLTER ||
    bfnumber === BF_CARDIOCARE_24H_STARTER_KIT ||
    bfnumber === BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER ||
    bfnumber === BF_VITAL_SIGNS_VS1 ||
    bfnumber === BF_VITAL_SIGNS_VS2 ||
    bfnumber === BF_VITAL_SIGNS_VS3 ||
    bfnumber === BF_VITAL_SIGNS_VS4 ||
    bfnumber === BF_VITAL_SIGNS_VS6 ||
    bfnumber === BF_VITAL_SIGNS_VS8
  ) {
    return false;
  } else {
    return true;
  }
};

export const usesDeviceIdAsSerialNumber = (bfnumber: string | undefined) => {
  if (bfnumber === BF_NAKED_ECG_3SNAPCRADLE) {
    return true;
  } else {
    return false;
  }
};

export function hasNfc(internalReference: string) {
  switch (internalReference) {
    case BF_NAKED_DOCK:
    case BF_NAKED_DOT:
    case BF_NAKED_2SNAPCRADLE:
    case BF_NAKED_2SNAPCRADLE_110:
    case BF_NAKED_3SNAPCRADLE_LEFT:
    case BF_NAKED_3SNAPCRADLE_RIGHT:
    case BF_NAKED_3WIRECRADLE_LEFT:
    case BF_NAKED_3WIRECRADLE_RIGHT:
    case BF_NAKED_4SNAPCRADLE:
    case BF_NAKED_ECG_3SNAPCRADLE:
    case BF_NAKED_MOTION_CRADLE:
    case BF_NAKED_4WIRE_CRADLE:
      return true;
    default:
      return false;
  }
}

export function hasIdChip(internalReference: string) {
  switch (internalReference) {
    case BF_NAKED_ECG_3SNAPCRADLE:
      return true;
    default:
      return false;
  }
}

export function hasLabel(internalReference: string) {
  if (isBlank(internalReference)) {
    return undefined;
  }
  switch (internalReference) {
    case BF_NAKED_DOCK:
    case BF_PACKAGED_DOT:
    case BF_PACKAGED_2SNAPCRADLE:
    case BF_PACKAGED_3SNAPCRADLE_LEFT:
    case BF_PACKAGED_3SNAPCRADLE_RIGHT:
    case BF_PACKAGED_4SNAPCRADLE:
    case BF_PACKAGED_ECG_3SNAPCRADLE:
    case BF_PACKAGED_MOTION_CRADLE:
    case BF_BYTEFLIES_KIT:
    case BF_BYTEFLIES_KIT_MODEL_2:
    case BF_VIRTUAL_BYTEFLIES_KIT:
    case BF_VIRTUAL_BYTEFLIES_KIT_WITH_IFU:
    case BF_CARDIOCARE_BOX:
    case BF_CARDIOCARE_MULTI_LEAD_LINEAR_BOX:
    case BF_EPICARE_FOCAL_LEFT:
    case BF_EPICARE_FOCAL_RIGHT:
    case BF_COVIDCARE_BOX_1:
    case BF_COVIDCARE_BOX_2:
    case BF_PACKAGED_4WIRE_CRADLE:
    case BF_CARDIOCARE_24H_HOLTER:
    case BF_CARDIOCARE_NON_LINEAR_24H_HOLTER:
    case BF_CARDIOCARE_24H_STARTER_KIT:
    case BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER:
    case BF_VITAL_SIGNS_VS1:
    case BF_VITAL_SIGNS_VS2:
    case BF_VITAL_SIGNS_VS3:
    case BF_VITAL_SIGNS_VS4:
    case BF_VITAL_SIGNS_VS6:
    case BF_VITAL_SIGNS_VS8:
      return true;
    case BF_NAKED_DOT:
    case BF_NAKED_2SNAPCRADLE:
    case BF_NAKED_2SNAPCRADLE_110:
    case BF_NAKED_3SNAPCRADLE_LEFT:
    case BF_NAKED_3SNAPCRADLE_RIGHT:
    case BF_NAKED_3WIRECRADLE_LEFT:
    case BF_NAKED_3WIRECRADLE_RIGHT:
    case BF_NAKED_4SNAPCRADLE:
    case BF_NAKED_ECG_3SNAPCRADLE:
    case BF_NAKED_MOTION_CRADLE:
    case BF_NAKED_4WIRE_CRADLE:
      return false;
    default:
      return undefined;
  }
}

export const hasNfcWithId = (bfnumber: string | undefined) => {
  const devicesWithId = [BF_NAKED_DOCK, BF_NAKED_DOT, BF_NAKED_ECG_3SNAPCRADLE];
  if (isBlank(bfnumber)) {
    throw new Error("BF-number should not be blank");
  } else if (devicesWithId.includes(bfnumber!.toUpperCase())) {
    return true;
  } else {
    return false;
  }
};

export const isPackagedProduct = (bfnumber: string | undefined) => {
  if (isBlank(bfnumber)) {
    return false;
  } else if (
    bfnumber === BF_PACKAGED_DOT ||
    bfnumber === BF_PACKAGED_2SNAPCRADLE ||
    bfnumber === BF_PACKAGED_ECG_3SNAPCRADLE ||
    bfnumber === BF_PACKAGED_3SNAPCRADLE_LEFT ||
    bfnumber === BF_PACKAGED_3SNAPCRADLE_RIGHT ||
    bfnumber === BF_PACKAGED_4SNAPCRADLE ||
    bfnumber === BF_PACKAGED_3WIRECRADLE_LEFT ||
    bfnumber === BF_PACKAGED_3WIRECRADLE_RIGHT ||
    bfnumber === BF_PACKAGED_4WIRE_CRADLE ||
    bfnumber === BF_PACKAGED_MOTION_CRADLE
  ) {
    return true;
  } else {
    return false;
  }
};

export type GroupSeparatorState =
  | ""
  | "Alt"
  | "Alt2"
  | "Alt29"
  | "Control"
  | "Control]";

export const COMPLETE_GROUP_SEPARATOR_STATES: GroupSeparatorState[] = [
  "Alt29",
  "Control]",
];

export const GROUP_SEPARATOR = String.fromCodePoint(29);
