import axios from "axios";

export interface DotState {
  Type: "DotState";
  Slot: number;
  Serial: string;
}

export interface DockStatusLocal {
  Type: "DockState";
  DockID: string; // Dock-d412439a6c74
  MACAddress: string; // d4:12:43:9a:6c:74
  DotStates: DotState[];
  CloudOK?: boolean;
  WifiState: {
    CurrentAP: null | {
      Bssid: string;
      Essid: string;
      Security: number;
      Freq: number;
      Noise: number;
      Rssi: number;
    };
    Status: "Network Not Found" | string;
  };
}

export interface PostWifiRequest {
  Essid: string;
  Bssid: string;
  Security: number;
}

export async function readDockStatus() {
  try {
    const instance = axios.create({
      baseURL: "https://192-168-51-97.setup.byteflies.xyz",
      timeout: 1000,
      adapter: require("axios/lib/adapters/http"),
      headers: {
        "content-type": "application/json;charset=utf-8",
      },
    });
    const response = await instance.get<DockStatusLocal>("/api/dock");
    return response.data;
  } catch (error) {
    console.log("Failed to GET dock status", error);
    throw new Error("Failed to GET dock status");
  }
}

export async function connectDockToWifi(request: PostWifiRequest) {
  try {
    const instance = axios.create({
      baseURL: "https://192-168-51-97.setup.byteflies.xyz",
      timeout: 1000,
      adapter: require("axios/lib/adapters/http"),
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    });
    const response = await instance.post<any>(
      "/api/wifi",
      JSON.stringify(request),
      {
        timeout: 1000,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Failed to POST dock wifi", error);
    throw new Error("Failed to POST dock wifi");
  }
}
