import { parseSerial } from "@byteflies/byteflies-serials";
import { GetNfcResponse } from "./bff/NFC_API_READ";
import { SerialFilter } from "./erp/manufacturing/OdooOperationsService";
import { Product } from "./erp/manufacturing/Product";
import { isBlank, isNotBlank } from "./StringUtils";
import { DeviceDescription, getInternalReferencesByGtin } from "./Utils";

export function nfcToFilter(product: Product, nfc: GetNfcResponse) {
  if (nfc === undefined) {
    throw new Error("Failed to read NFC");
  }

  const device = convertNfcResponse(nfc);

  if (isBlank(device.serial)) {
    throw new Error("Failed to parse NFC: serial");
  }

  const filter: SerialFilter = {
    serial: device.serial!,
  };

  if (isNotBlank(device.deviceId)) {
    filter.ref = device.deviceId;
  }

  if (isNotBlank(device.versionNumber)) {
    filter.versionNumber = device.versionNumber;
  }

  if (isNotBlank(device.gtin) && isNotBlank(product.internalReference)) {
    const gtinIntRefs = getInternalReferencesByGtin(device.gtin);
    if (gtinIntRefs.includes(product.internalReference)) {
      filter.productRefs = [product.internalReference];
    }
  }

  return filter;
}
export function getUdiFromResponse(nfc: GetNfcResponse): string | undefined {
  if (nfc === undefined) {
    return undefined;
  } else {
    const keys = Object.keys(nfc);
    for (const key of keys) {
      if (key.toLowerCase().endsWith("/udi")) {
        const u = nfc as any;
        return u[key];
      }
    }
    return undefined;
  }
}

export function convertNfcResponse(nfc: GetNfcResponse): DeviceDescription {
  if (nfc === undefined) {
    throw new Error("NFC response is undefined");
  }
  const id = getIdFromResponse(nfc);
  const udi = getUdiFromResponse(nfc);

  const result: DeviceDescription = {
    deviceId: id,
    udi: udi,
  };

  if (udi !== undefined) {
    const p = parseSerial(udi);
    if (p !== undefined) {
      result.serial = p.serial;
      result.gtin = p.gtin;
    }
  }

  return result;
}

export function getIdFromResponse(nfc: GetNfcResponse): string | undefined {
  if (nfc === undefined) {
    return undefined;
  } else {
    const keys = Object.keys(nfc);
    for (const key of keys) {
      if (key.toLowerCase().endsWith("/id")) {
        const u = nfc as any;
        return u[key];
      }
    }
    return undefined;
  }
}
