import Grid from "@mui/material/Grid";
import IOdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import { ProductSerial, QualityCheck } from "../erp/manufacturing/Product";
import { QualityCheckFail, QualityCheckPass } from "./QualityCheckPassFail";
import {
  Alert,
  AlertTitle,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  MANUFACTURING_GROUP,
  matrixUrl,
  qualityCheckCode,
  qualityCheckTitleWithoutCode,
} from "../Utils";
import { isBlank, isNotBlank } from "../StringUtils";
import { useCallback, useEffect, useState } from "react";
import { CheckCircle } from "@mui/icons-material";
import RecordingSelectionDialog from "../Plot/RecordingSelectionDialog";
import {
  cloudRecordingUrl,
  cloudSetToken,
  getRecording,
  GetRecordingResult,
  GetRecordingSignalResult,
  ListRecordingResult,
  listRecordings,
  SignalType,
} from "../Plot/cloudApi";
import PlotData from "../Plot/PlotData";
import axios from "axios";
import { parse } from "papaparse";
import { CsvRow } from "./QualityCheckTestSensorDotUtils";
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async/fixed";
import { Auth } from "aws-amplify";
import moment from "moment";

interface QualityCheckWiTestSensorDotProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  serial?: ProductSerial;
  onError(error: any): void;
  onSuccess(): void;
  onFail(): void;
}

export const read = async (csvUrl: string) => {
  // First download the CSV file
  const fileContent = await axios.get<string>(csvUrl, { responseType: "text" });
  const csvData = fileContent.data;
  if (typeof csvData !== "string") {
    throw new Error(`Failed to read CSV file ${csvUrl}`);
  }

  // Then parse the CSV file
  const records = parse<CsvRow>(csvData, {
    skipEmptyLines: true,
    header: false,
  });
  const csvRows = records.data;
  if (!Array.isArray(csvRows)) {
    throw new Error(
      `Failed to GET ${csvUrl} - expected array but got ${typeof csvRows}`
    );
  } else if (csvRows.length > 0 && !Array.isArray(csvRows[0])) {
    throw new Error(
      `Failed to GET ${csvUrl} - expected first row to be an array but got ${typeof csvRows[0]}`
    );
  }

  return csvRows
    .filter((r, idx) => {
      if (idx === 0 && (r[0] as any) === "time") {
        return false;
      } else {
        return true;
      }
    })
    .map((r) => {
      const result: CsvRow = [+r[0], +r[1]];
      return result;
    });
};

const eexSignals = (recording: GetRecordingResult) => {
  return recording.signals.filter((s) =>
    ["EEG", "ECG", "EMG"].includes(s.type)
  );
};

const passDisabled = (
  dotId: string | undefined,
  recording: GetRecordingResult | undefined
) => {
  if (isBlank(dotId)) {
    return true;
  } else if (
    recording === undefined ||
    recording.id === undefined ||
    recording.signals === undefined ||
    !Array.isArray(recording.signals) ||
    recording.signals.length === undefined
  ) {
    return true;
  } else if (recording.dotId !== dotId) {
    return true;
  }

  const signals = eexSignals(recording);
  if (signals.length !== 2) {
    // The signal type does not matter but we need both signals
    return true;
  }

  const bat = recording.signals.filter((s) => ["BAT"].includes(s.type));
  if (bat.length === 0) {
    return true;
  }

  return false;
};

const downloadSignals = async (
  groupId: string,
  recording: GetRecordingResult
) => {
  if (groupId === undefined || groupId === "") {
    throw new Error("groupId is empty");
  } else if (recording === undefined) {
    throw new Error("recordingId is empty");
  }
  const signalTypesToDownload: SignalType[] = [
    "ECG",
    "EEG",
    "BAT",
    "LEAD_OFF",
    "ACC",
    "EMG",
    "GYR",
  ];

  const signals = recording.signals || [];
  const fetchedSignals = new Map<GetRecordingSignalResult, CsvRow[]>();

  for (const signal of signals) {
    if (signalTypesToDownload.includes(signal.type)) {
      console.log("GET", signal.rawData);
      try {
        const csvRows = await read(signal.rawData);
        if (!Array.isArray(csvRows)) {
          throw new Error(
            `Failed to GET ${
              signal.rawData
            } - expected array but got ${typeof csvRows}`
          );
        }
        fetchedSignals.set(signal, csvRows);
      } catch (error) {
        console.error(`Failed to GET ${signal.rawData}`, error);
        throw error;
      }
    }
  }
  return fetchedSignals;
};

function QualityCheckWiTestSensorDot(props: QualityCheckWiTestSensorDotProps) {
  const { svc, qualityCheck, serial, onSuccess, onFail, onError } = props;

  const [recordingDialogOpen, setRecordingDialogOpen] = useState(false);
  const [recording, setRecording] = useState<ListRecordingResult>();
  const [recordingDetails, setRecordingDetails] =
    useState<GetRecordingResult>();
  const [signals, setSignals] = useState<
    Map<GetRecordingSignalResult, CsvRow[]>
  >(new Map());
  const [recordings, setRecordings] = useState<ListRecordingResult[]>([]);

  const handleNewRecording = useCallback(
    async (newRecording: ListRecordingResult) => {
      if (recording && recording.id === newRecording.id) {
        return;
      }
      setRecording(newRecording);
      setRecordingDetails(undefined);
      setSignals(new Map());

      try {
        const rec = await getRecording(MANUFACTURING_GROUP.id, newRecording.id);
        setRecordingDetails(rec);
        const sigs = await downloadSignals(MANUFACTURING_GROUP.id, rec);
        setSignals(sigs);
      } catch (error) {
        console.error("Failed to fetch signals", error);
        setSignals(new Map());
        setRecordingDetails(undefined);
      }
    },
    [recording]
  );

  useEffect(() => {
    const timer = setIntervalAsync(
      async (selectedDotId: string | undefined) => {
        if (selectedDotId === undefined) {
          return;
        }

        try {
          const session = await Auth.currentSession();
          const token = session?.getIdToken().getJwtToken();
          cloudSetToken(token);

          const begin = moment().subtract(1, "days").startOf("day").toDate();
          const end = new Date();

          const recordings = await listRecordings(
            MANUFACTURING_GROUP.id,
            begin,
            end,
            selectedDotId
          );
          const filteredRecordings = recordings.filter(
            (r) => r.dotId === selectedDotId
          );
          setRecordings(filteredRecordings);
          if (!recording && filteredRecordings.length === 1) {
            await handleNewRecording(filteredRecordings[0]);
          }
        } catch (error) {
          console.error(
            "Failed to list recordings for group",
            MANUFACTURING_GROUP.id,
            selectedDotId,
            error
          );
        }
      },
      2000,
      serial?.deviceId
    );
    return () => {
      clearIntervalAsync(timer);
    };
  }, [serial?.deviceId, setRecordings, recording, handleNewRecording]);

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Typography variant="h4">
            {qualityCheckTitleWithoutCode(qualityCheck)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4">
            <a target="_blank" rel="noreferrer" href={matrixUrl(qualityCheck)}>
              {qualityCheckCode(qualityCheck)}
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="warning">
            <AlertTitle>
              Important: only test one Sensor Dot at a time
            </AlertTitle>
          </Alert>
        </Grid>

        <Grid item xs={2}>
          {isNotBlank(serial?.deviceId) ? (
            <CheckCircle data-cy="test-sensor-dot-device-id-ok" />
          ) : (
            <CircularProgress
              size="1em"
              data-cy="test-sensor-dot-no-device-id"
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <Typography>
            Make sure the Sensor Dot has a serial number with device ID
          </Typography>
        </Grid>

        <Grid item xs={1}>
          {recording !== undefined && isNotBlank(recording.id) && (
            <CheckCircle data-cy="test-sensor-dot-recording-selected" />
          )}
        </Grid>

        <Grid item xs={1}>
          <Button
            variant="contained"
            color={"primary"}
            onClick={() => setRecordingDialogOpen(true)}
            data-cy="select-recording"
            disabled={serial === undefined || isBlank(serial.deviceId)}
          >
            rec
          </Button>

          <RecordingSelectionDialog
            open={recordingDialogOpen}
            recordings={recordings}
            onClose={() => setRecordingDialogOpen(false)}
            onOK={async (recording) => {
              await handleNewRecording(recording);
              setRecordingDialogOpen(false);
            }}
            group={MANUFACTURING_GROUP}
            dotId={serial?.deviceId || ""}
          />
        </Grid>
        <Grid item xs={10}>
          {recording !== undefined && isNotBlank(recording.id) ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={cloudRecordingUrl(MANUFACTURING_GROUP.id, recording.id)}
            >
              {recording.id}
            </a>
          ) : (
            <Typography>
              Select a recording from group {MANUFACTURING_GROUP.name}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          {signals !== undefined && <PlotData signals={signals} />}
        </Grid>

        <Grid item xs={6}>
          <QualityCheckFail
            qualityCheck={qualityCheck}
            label="Fail"
            tooltip="Fail the quality check"
            svc={svc}
            onError={(error) => {
              onError(error);
            }}
            onFail={() => {
              if (qualityCheck !== undefined) {
                qualityCheck.state = "fail";
              }
              onFail();
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <QualityCheckPass
            qualityCheck={qualityCheck}
            svc={svc}
            disabled={passDisabled(serial?.deviceId, recordingDetails)}
            label="Pass"
            tooltip="Pass the quality check"
            onError={(error) => {
              props.onError(error);
            }}
            onSuccess={() => {
              if (qualityCheck !== undefined) {
                qualityCheck.state = "pass";
              }
              onSuccess();
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default QualityCheckWiTestSensorDot;
