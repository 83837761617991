import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "typeface-roboto";
import "./App.css";
import Amplify from "aws-amplify";

import App from "./App";

import { getDeviceToken } from "xfa-connect-sdk-js";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    mandatorySignIn: true,
  },
});

//XFA Connect Integration (duplicated here to allow mocking for tests)
getDeviceToken();

const el = document.getElementById("root")!;
if (el === null) throw new Error("Root container missing in index.html");
const root = ReactDOM.createRoot(el);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
