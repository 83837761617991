import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { GetNfcResponse } from "./bff/NFC_API_READ";
import { CircularProgress } from "@mui/material";
import { CheckCircle, Nfc, Warning } from "@mui/icons-material";
import { readNfc } from "./Nfc";
import { getStation } from "./Settings";

interface ReadNfcProps {
  disabled?: boolean;
  onNfc(nfc: GetNfcResponse | undefined): void;
  onError(error: any): void;
  onStart?(): void;
  onFinish?(): void;
}

function ReadNfcButton(props: ReadNfcProps) {
  const [nfcState, setNfcState] = useState<
    undefined | "busy" | "success" | "failed"
  >(undefined);
  return (
    <IconButton
      data-cy="read-nfc"
      disabled={props.disabled}
      onClick={async () => {
        if (props.onStart) {
          props.onStart();
        }
        setNfcState("busy");
        try {
          const station = getStation();
          const ndefMsg = await readNfc(station);
          const nfc: GetNfcResponse = Object.assign(
            {},
            ...ndefMsg.records.map((r) => ({ [r.mediaType!]: r.data }))
          );
          props.onNfc(nfc);
          setNfcState("success");
        } catch (error) {
          console.error("Failed to read NFC", error);
          setNfcState("failed");
          props.onError(error);
        } finally {
          if (props.onFinish) {
            props.onFinish();
          }
        }
      }}
      color="primary"
    >
      {nfcState === undefined && <Nfc />}
      {nfcState === "busy" && <CircularProgress size="1em" />}
      {nfcState === "failed" && <Warning />}
      {nfcState === "success" && <CheckCircle />}
    </IconButton>
  );
}

export default ReadNfcButton;
