import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import IOdooOperationsService from "./erp/manufacturing/OdooOperationsService";
import { ProductionOrder } from "./erp/manufacturing/Product";
import ProductionOrderEditor from "./ProductionOrderEditor";
import MarkAsDoneButton from "./actions/MarkAsDoneButton";
import { useHistory } from "react-router";

interface BillOfMaterialEditorProps {
  svc: IOdooOperationsService;
  productionOrder: ProductionOrder | undefined;
  onChange: (productionOrder: ProductionOrder) => void;
}
function BillOfMaterialEditor(props: BillOfMaterialEditorProps) {
  const { productionOrder, svc, onChange } = props;
  const [open, setOpen] = React.useState(false);

  const [messageSnackbar, setmessageSnackbar] = useState("");

  const handleSnackbar = (message: string) => {
    setmessageSnackbar(message);
    setOpen(true);
  };
  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const history = useHistory();

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <ProductionOrderEditor
        svc={svc}
        onChange={(po) => {
          onChange(po);
        }}
        productionOrder={productionOrder}
      />

      <Grid container spacing={1} style={{ padding: 20 }}>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          {productionOrder !== undefined && (
            <MarkAsDoneButton
              svc={svc}
              productionOrder={productionOrder}
              onError={(error) => {
                console.error("Failed to mark as done", error);
                handleSnackbar(`Failed to mark as done: ${error}`);
              }}
              onDone={(po) => {
                onChange(po);
                history.push("/");
              }}
            />
          )}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={messageSnackbar}
        action={
          <React.Fragment>
            <Button
              color="secondary"
              size="small"
              onClick={(event) => {
                handleClose(event, "");
              }}
            >
              UNDO
            </Button>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default BillOfMaterialEditor;
