/* tslint:disable */
/* eslint-disable */
/**
 * KitchenAid Cradle Tester API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CradleTesterDevice
 */
export interface CradleTesterDevice {
    /**
     * 
     * @type {string}
     * @memberof CradleTesterDevice
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CradleTesterDevice
     */
    'kitchenaid': string;
}
/**
 * 
 * @export
 * @interface CradleTesterJob
 */
export interface CradleTesterJob {
    /**
     * 
     * @type {string}
     * @memberof CradleTesterJob
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CradleTesterJob
     */
    'deviceId': string;
    /**
     * 
     * @type {CradleTesterJobType}
     * @memberof CradleTesterJob
     */
    'type': CradleTesterJobType;
    /**
     * 
     * @type {CradleTesterJobState}
     * @memberof CradleTesterJob
     */
    'state'?: CradleTesterJobState;
    /**
     * 
     * @type {CradleTesterJobTestResult}
     * @memberof CradleTesterJob
     */
    'testResult'?: CradleTesterJobTestResult;
    /**
     * 
     * @type {CradleTesterJobReadRomIdResult}
     * @memberof CradleTesterJob
     */
    'readRomIdResult'?: CradleTesterJobReadRomIdResult;
    /**
     * 
     * @type {CradleTesterJobWriteIdChipInput}
     * @memberof CradleTesterJob
     */
    'writeIdChipInput'?: CradleTesterJobWriteIdChipInput;
    /**
     * 
     * @type {CradleTesterJobReadIdChipResult}
     * @memberof CradleTesterJob
     */
    'readIdChipResult'?: CradleTesterJobReadIdChipResult;
}
/**
 * 
 * @export
 * @interface CradleTesterJobReadIdChipResult
 */
export interface CradleTesterJobReadIdChipResult {
    /**
     * 
     * @type {string}
     * @memberof CradleTesterJobReadIdChipResult
     */
    'serial': string;
    /**
     * 
     * @type {string}
     * @memberof CradleTesterJobReadIdChipResult
     */
    'gtin': string;
    /**
     * 
     * @type {string}
     * @memberof CradleTesterJobReadIdChipResult
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof CradleTesterJobReadIdChipResult
     */
    'prodDate': string;
}
/**
 * 
 * @export
 * @interface CradleTesterJobReadRomIdResult
 */
export interface CradleTesterJobReadRomIdResult {
    /**
     * 
     * @type {string}
     * @memberof CradleTesterJobReadRomIdResult
     */
    'romId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CradleTesterJobState = {
    New: 'new',
    Sent: 'sent',
    Done: 'done',
    Error: 'error'
} as const;

export type CradleTesterJobState = typeof CradleTesterJobState[keyof typeof CradleTesterJobState];


/**
 * 
 * @export
 * @interface CradleTesterJobTestResult
 */
export interface CradleTesterJobTestResult {
    /**
     * 
     * @type {boolean}
     * @memberof CradleTesterJobTestResult
     */
    'pass': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof CradleTesterJobTestResult
     */
    'resistances': Array<number>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CradleTesterJobType = {
    Test: 'test',
    ReadRomId: 'readRomId',
    WriteIdChip: 'writeIdChip',
    ReadIdChip: 'readIdChip'
} as const;

export type CradleTesterJobType = typeof CradleTesterJobType[keyof typeof CradleTesterJobType];


/**
 * 
 * @export
 * @interface CradleTesterJobWriteIdChipInput
 */
export interface CradleTesterJobWriteIdChipInput {
    /**
     * 
     * @type {string}
     * @memberof CradleTesterJobWriteIdChipInput
     */
    'serial': string;
    /**
     * 
     * @type {string}
     * @memberof CradleTesterJobWriteIdChipInput
     */
    'gtin': string;
    /**
     * 
     * @type {string}
     * @memberof CradleTesterJobWriteIdChipInput
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof CradleTesterJobWriteIdChipInput
     */
    'prodDate': string;
}
/**
 * 
 * @export
 * @interface PostCradleTesterJobRequest
 */
export interface PostCradleTesterJobRequest {
    /**
     * 
     * @type {string}
     * @memberof PostCradleTesterJobRequest
     */
    'deviceId': string;
    /**
     * 
     * @type {CradleTesterJobType}
     * @memberof PostCradleTesterJobRequest
     */
    'type': CradleTesterJobType;
    /**
     * 
     * @type {CradleTesterJobWriteIdChipInput}
     * @memberof PostCradleTesterJobRequest
     */
    'writeIdChipInput'?: CradleTesterJobWriteIdChipInput;
}

/**
 * CradleTesterApi - axios parameter creator
 * @export
 */
export const CradleTesterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get cradle tester job
         * @param {string} id Cradle tester job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCradleTesterJob: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCradleTesterJob', 'id', id)
            const localVarPath = `/cradleTester/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List cradle tester devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCradleTesterDevices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cradleTester/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List cradle tester jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCradleTesterJobs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cradleTester/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CradleTesterApi - functional programming interface
 * @export
 */
export const CradleTesterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CradleTesterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get cradle tester job
         * @param {string} id Cradle tester job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCradleTesterJob(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CradleTesterJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCradleTesterJob(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List cradle tester devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCradleTesterDevices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CradleTesterDevice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCradleTesterDevices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List cradle tester jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCradleTesterJobs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CradleTesterJob>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCradleTesterJobs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CradleTesterApi - factory interface
 * @export
 */
export const CradleTesterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CradleTesterApiFp(configuration)
    return {
        /**
         * 
         * @summary Get cradle tester job
         * @param {string} id Cradle tester job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCradleTesterJob(id: string, options?: any): AxiosPromise<CradleTesterJob> {
            return localVarFp.getCradleTesterJob(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List cradle tester devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCradleTesterDevices(options?: any): AxiosPromise<Array<CradleTesterDevice>> {
            return localVarFp.listCradleTesterDevices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List cradle tester jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCradleTesterJobs(options?: any): AxiosPromise<Array<CradleTesterJob>> {
            return localVarFp.listCradleTesterJobs(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CradleTesterApi - object-oriented interface
 * @export
 * @class CradleTesterApi
 * @extends {BaseAPI}
 */
export class CradleTesterApi extends BaseAPI {
    /**
     * 
     * @summary Get cradle tester job
     * @param {string} id Cradle tester job ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CradleTesterApi
     */
    public getCradleTesterJob(id: string, options?: AxiosRequestConfig) {
        return CradleTesterApiFp(this.configuration).getCradleTesterJob(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List cradle tester devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CradleTesterApi
     */
    public listCradleTesterDevices(options?: AxiosRequestConfig) {
        return CradleTesterApiFp(this.configuration).listCradleTesterDevices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List cradle tester jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CradleTesterApi
     */
    public listCradleTesterJobs(options?: AxiosRequestConfig) {
        return CradleTesterApiFp(this.configuration).listCradleTesterJobs(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new cradle tester job
         * @param {PostCradleTesterJobRequest} [postCradleTesterJobRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cradleTesterJobsPost: async (postCradleTesterJobRequest?: PostCradleTesterJobRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cradleTester/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCradleTesterJobRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new cradle tester job
         * @param {PostCradleTesterJobRequest} [postCradleTesterJobRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cradleTesterJobsPost(postCradleTesterJobRequest?: PostCradleTesterJobRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cradleTesterJobsPost(postCradleTesterJobRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new cradle tester job
         * @param {PostCradleTesterJobRequest} [postCradleTesterJobRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cradleTesterJobsPost(postCradleTesterJobRequest?: PostCradleTesterJobRequest, options?: any): AxiosPromise<string> {
            return localVarFp.cradleTesterJobsPost(postCradleTesterJobRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Create a new cradle tester job
     * @param {PostCradleTesterJobRequest} [postCradleTesterJobRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cradleTesterJobsPost(postCradleTesterJobRequest?: PostCradleTesterJobRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cradleTesterJobsPost(postCradleTesterJobRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


