import Grid from "@mui/material/Grid";
import {
  CradleTesterJob,
  CradleTesterJobState,
  CradleTesterJobTestResult,
  CradleTesterJobType,
  PostCradleTesterJobRequest,
} from "../openapi/kitchenaid/cradle-tester/api";
import { KitchenAid } from "../KitchenAid";
import { getStation } from "../Settings";
import IOdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import { QualityCheck } from "../erp/manufacturing/Product";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Bolt, CheckCircle, Error as ErrorIcon } from "@mui/icons-material";
import { getCradleTesterDeviceId } from "../CradleTester";
import {
  matrixUrl,
  qualityCheckCode,
  qualityCheckTitleWithoutCode,
} from "../Utils";

interface QualityCheckTestCradleProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  onError(error: Error): void;
  onSuccess(): void;
  onFail(): void;
}

export function QualityCheckTestCradle(props: QualityCheckTestCradleProps) {
  const { svc, qualityCheck, onSuccess, onError } = props;

  const [testState, setTestState] = useState<
    "busy" | "error" | "success" | null
  >(null);
  const [testResult, setTestResult] =
    useState<CradleTesterJobTestResult | null>(null);

  useEffect(() => {
    if (qualityCheck.state === "pass") {
      setTestState("success");
    } else if (qualityCheck.state === "fail") {
      setTestState("error");
    } else {
      setTestState(null);
    }
  }, [qualityCheck.state]);

  return (
    <Grid container spacing={1} style={{ padding: 10 }}>
      <Grid item xs={10} style={{ paddingBottom: 20 }}>
        <Typography variant="h4">
          {qualityCheckTitleWithoutCode(qualityCheck)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h4">
          <a target="_blank" rel="noreferrer" href={matrixUrl(qualityCheck)}>
            {qualityCheckCode(qualityCheck)}
          </a>
        </Typography>
      </Grid>
      <Grid item xs={2} paddingBottom={2}>
        <Button
          data-cy="cradle-test-start-test"
          variant="contained"
          color={(() => {
            switch (testState) {
              case "success":
                return "success";
              case "error":
                return "error";
              default:
                return "primary";
            }
          })()}
          fullWidth
          startIcon={(() => {
            switch (testState) {
              case "success":
                return <CheckCircle />;
              case "error":
                return <ErrorIcon />;
              case "busy":
                return <CircularProgress size="1rem" />;
              default:
                return <Bolt />;
            }
          })()}
          onClick={async () => {
            setTestState("busy");
            setTestResult(null);
            try {
              const cradleTester = await getCradleTesterDeviceId();
              const jobRequest: PostCradleTesterJobRequest = {
                deviceId: cradleTester,
                type: CradleTesterJobType.Test,
              };
              console.log(`Testing cradle on device '${cradleTester}'`);
              const station = getStation();
              const test = ["test"].includes(station) ? true : false;
              const kitchenAid = new KitchenAid(test);
              const jobId = await kitchenAid.createCradleTesterJob(jobRequest);
              console.log(`Cradle tester job ID: ${jobId}`);
              let retries = -1;
              let job: CradleTesterJob;
              let state: CradleTesterJobState | undefined;
              while (retries !== 10) {
                retries++;
                try {
                  job = await kitchenAid.readCradleTesterJob(jobId);
                  console.log(
                    `Polling, job result: ${JSON.stringify(job, null, 2)}`
                  );
                } catch (e) {
                  console.error("Polling", e);
                  continue;
                }
                state = job.state;
                const testResult = job.testResult;
                if (testResult) {
                  setTestResult(testResult);
                }
                if (state === CradleTesterJobState.Done) {
                  const note = `Resistances: ${testResult!.resistances
                    .map((r) => r + " Ω")
                    .join(", ")} (${testResult!.pass ? "pass" : "fail"})`;
                  await svc.writeQualityCheck(qualityCheck, note);
                  await svc.postMessage(
                    "quality.check",
                    [qualityCheck.id],
                    note
                  );
                  if (testResult!.pass) {
                    qualityCheck!.state = "pass";
                    await svc.saveQualityCheck(qualityCheck, true);
                    setTestState("success");
                    onSuccess();
                    return;
                  } else {
                    qualityCheck!.state = "fail";
                    await svc.saveQualityCheck(qualityCheck, false);
                    setTestState("error");
                    throw new Error("Cradle test failed");
                  }
                } else if (state === CradleTesterJobState.Error) {
                  setTestState("error");
                  throw new Error("Cradle test error");
                }
                await new Promise((r) => setTimeout(r, 1000));
              }
              setTestState("error");
            } catch (e) {
              setTestState("error");
              onError(e as Error);
            }
          }}
          disabled={testState === "busy"}
        >
          Test cradle
        </Button>
      </Grid>
      <Grid item xs={12}>
        {testResult ? (
          <>
            Resistances:{" "}
            {testResult.resistances
              .map((r) => (r === 90499408 ? "open circuit" : r + " Ω"))
              .join(", ")}
          </>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <Typography>Resistances should be between 1500 Ω and 2500 Ω</Typography>
      </Grid>
    </Grid>
  );
}
