import Grid from "@mui/material/Grid";
import IOdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import {
  ProductionOrder,
  ProductSerial,
  QualityCheck,
} from "../erp/manufacturing/Product";
import { QualityCheckFail, QualityCheckPass } from "./QualityCheckPassFail";
import { Typography } from "@mui/material";

import {
  matrixUrl,
  needsDeviceId,
  qualityCheckCode,
  qualityCheckTitleWithoutCode,
} from "../Utils";
import SnipeItButton, { hardwareUrl } from "../actions/SnipeItButton";
import { PostHardwareResponse } from "../snipeIT/snipeit";
import { useState } from "react";
import {
  AddNewRecordCommand,
  MailMessage,
  One2Many,
  ProductionOrder as OdooProductionOrder,
} from "@byteflies/odoo-typescript";

interface QualityCheckSnipeItProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  productionOrder: ProductionOrder;
  serial?: ProductSerial;
  onError(error: any): void;
  onSuccess(): void;
  onFail(): void;
}

function QualityCheckSnipeIt(props: QualityCheckSnipeItProps) {
  const {
    svc,
    qualityCheck,
    serial,
    onSuccess,
    onFail,
    onError,
    productionOrder,
  } = props;
  const [hardware, setHardware] = useState<PostHardwareResponse>();

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Typography variant="h4">
            {qualityCheckTitleWithoutCode(qualityCheck)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4">
            <a target="_blank" rel="noreferrer" href={matrixUrl(qualityCheck)}>
              {qualityCheckCode(qualityCheck)}
            </a>
          </Typography>
        </Grid>

        <Grid item xs={2}>
          {serial !== undefined && productionOrder !== undefined && (
            <SnipeItButton
              svc={svc}
              productionOrder={productionOrder}
              onError={(err) => onError(err)}
              onSuccess={async (hardware) => {
                // Automatically pass the check
                await svc.saveQualityCheck(qualityCheck, true);
                qualityCheck.state = "pass";

                if (hardware !== null) {
                  console.info(
                    "SnipeIt asset creation succesful",
                    JSON.stringify(hardware)
                  );
                  setHardware(hardware);

                  if (
                    hardware.payload !== undefined &&
                    hardware.payload.id !== undefined
                  ) {
                    const message: MailMessage = {
                      body: hardwareUrl(hardware!.payload!.id),
                      message_type: "comment",
                      model: "mrp.production",
                    };

                    const command: AddNewRecordCommand<MailMessage> = [
                      0,
                      0,
                      message,
                    ];
                    await svc.writeProductionOrder2(productionOrder.id, {
                      message_ids: [command] as One2Many<MailMessage> as any,
                    } as OdooProductionOrder);
                  }
                }

                // Let the success ripple through the system
                onSuccess();
              }}
              disabled={
                productionOrder === undefined ||
                productionOrder.product === undefined ||
                productionOrder.finishedSerial === undefined ||
                (needsDeviceId(productionOrder.product.internalReference) &&
                  (productionOrder.finishedSerial.deviceId === undefined ||
                    productionOrder.finishedSerial.deviceId === ""))
              }
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <Typography>
            Register the serial number in{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://byteflies.snipe-it.io/hardware"
            >
              Snipe-IT
            </a>
          </Typography>
        </Grid>

        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          {hardware &&
            hardware.payload !== undefined &&
            hardware.payload.id !== undefined && (
              <Typography>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={hardwareUrl(hardware!.payload!.id)}
                >
                  {hardwareUrl(hardware!.payload!.id)}
                </a>
              </Typography>
            )}
        </Grid>

        <Grid item xs={6}>
          <QualityCheckFail
            qualityCheck={qualityCheck}
            label="Fail"
            tooltip="Fail the quality check"
            svc={svc}
            onError={(error) => {
              props.onError(error);
            }}
            onFail={() => {
              qualityCheck.state = "fail";
              onFail();
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <QualityCheckPass
            disabled={true}
            qualityCheck={qualityCheck}
            svc={svc}
            label="Pass"
            tooltip="Pass the quality check"
            onError={(error) => {
              props.onError(error);
            }}
            onSuccess={() => {
              qualityCheck.state = "pass";
              onSuccess();
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default QualityCheckSnipeIt;
