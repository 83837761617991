import { useState } from "react";
import Button from "@mui/material/Button";
import { CircularProgress, Tooltip } from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";
import { isNotBlank } from "./StringUtils";
import OdooOperationsService from "./erp/manufacturing/OdooOperationsService";

interface GetNextSequenceProps {
  svc: OdooOperationsService;
  code: "byteflies.covidcare.box.v0" | "byteflies.covidcare.box.v1" | "unknown";
  fullWidth?: boolean;
  disabled?: boolean;
  onNextSequence(code: string): void;
  onError(error: any): void;
}
function GetNextSequenceButton(props: GetNextSequenceProps) {
  const [status, setStatus] = useState<
    undefined | "busy" | "success" | "failed"
  >(undefined);
  return (
    <Tooltip title={`Get the next odoo sequence number for code ${props.code}`}>
      <span>
        <Button
          data-cy="get-next-sequence"
          disabled={props.disabled}
          fullWidth={props.fullWidth}
          onClick={async () => {
            setStatus("busy");
            try {
              const sequence = await props.svc.getNextSequenceByCode(
                props.code
              );
              if (sequence !== undefined && isNotBlank(sequence)) {
                props.onNextSequence(sequence);
              } else {
                throw new Error("Unable to get next sequence");
              }
              setStatus("success");
            } catch (error) {
              setStatus("failed");
              props.onError(error);
            }
          }}
          color="primary"
        >
          {status === undefined && "BOX-"}
          {status === "busy" && <CircularProgress size="1em" />}
          {status === "failed" && <Warning />}
          {status === "success" && <CheckCircle />}
        </Button>
      </span>
    </Tooltip>
  );
}

export default GetNextSequenceButton;
