import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";
import SnipeItClient, {
  LOCATION_BYTEFLIES_HQ,
  PostHardware,
  PostHardwareResponse,
  STATUS_DEPLOYABLE,
} from "../snipeIT/snipeit";
import { ProductionOrder } from "../erp/manufacturing/Product";
import {
  BF_BYTEFLIES_KIT,
  BF_BYTEFLIES_KIT_MODEL_2,
  BF_CARDIOCARE_24H_HOLTER,
  BF_CARDIOCARE_24H_STARTER_KIT,
  BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER,
  BF_CARDIOCARE_BOX,
  BF_CARDIOCARE_MULTI_LEAD_LINEAR_BOX,
  BF_CARDIOCARE_NON_LINEAR_24H_HOLTER,
  BF_EPICARE_BOX,
  BF_EPICARE_FOCAL_LEFT,
  BF_EPICARE_FOCAL_RIGHT,
  BF_NAKED_2SNAPCRADLE,
  BF_NAKED_2SNAPCRADLE_110,
  BF_NAKED_3SNAPCRADLE_LEFT,
  BF_NAKED_3SNAPCRADLE_RIGHT,
  BF_NAKED_4SNAPCRADLE,
  BF_NAKED_4WIRE_CRADLE,
  BF_NAKED_DOCK,
  BF_NAKED_DOT,
  BF_NAKED_ECG_3SNAPCRADLE,
  BF_NAKED_MOTION_CRADLE,
  BF_PACKAGED_2SNAPCRADLE,
  BF_PACKAGED_3SNAPCRADLE_LEFT,
  BF_PACKAGED_3SNAPCRADLE_RIGHT,
  BF_PACKAGED_4SNAPCRADLE,
  BF_PACKAGED_4WIRE_CRADLE,
  BF_PACKAGED_DOT,
  BF_PACKAGED_ECG_3SNAPCRADLE,
  BF_PACKAGED_MOTION_CRADLE,
  getProductVersion,
} from "../Utils";
import { getBoxID, getDockID, getDotID } from "./LabelUtils";
import OdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import { isBlank } from "../StringUtils";

interface SnipeItProps {
  fullWidth?: boolean;
  disabled?: boolean;
  productionOrder: ProductionOrder;
  svc: OdooOperationsService;
  onError(error: any): void;
  onSuccess(hardware: PostHardwareResponse | null): void;
}

function snipeitBfNumber(bfNumber: string) {
  switch (bfNumber) {
    case BF_NAKED_DOT:
      return BF_PACKAGED_DOT;
    case BF_NAKED_2SNAPCRADLE:
    case BF_NAKED_2SNAPCRADLE_110:
      return BF_PACKAGED_2SNAPCRADLE;
    case BF_NAKED_3SNAPCRADLE_LEFT:
      return BF_PACKAGED_3SNAPCRADLE_LEFT;
    case BF_NAKED_3SNAPCRADLE_RIGHT:
      return BF_PACKAGED_3SNAPCRADLE_RIGHT;
    case BF_NAKED_4SNAPCRADLE:
      return BF_PACKAGED_4SNAPCRADLE;
    case BF_NAKED_MOTION_CRADLE:
      return BF_PACKAGED_MOTION_CRADLE;
    case BF_NAKED_4WIRE_CRADLE:
      return BF_PACKAGED_4WIRE_CRADLE;
    case BF_NAKED_ECG_3SNAPCRADLE:
      return BF_PACKAGED_ECG_3SNAPCRADLE;
    default:
      return bfNumber;
  }
}

export function hardwareUrl(id: number) {
  return `https://byteflies.snipe-it.io/hardware/${id}`;
}

function SnipeItButton(props: SnipeItProps) {
  const [snipeItState, setSnipeItState] = useState<
    undefined | "busy" | "success" | "failed"
  >(undefined);
  const [error, setError] = useState<string>();

  const getAssetTag = async (
    productionOrder: ProductionOrder,
    svc: OdooOperationsService
  ) => {
    switch (productionOrder.product.internalReference) {
      case BF_CARDIOCARE_BOX:
      case BF_CARDIOCARE_MULTI_LEAD_LINEAR_BOX:
      case BF_CARDIOCARE_24H_STARTER_KIT:
      case BF_CARDIOCARE_24H_STARTER_KIT_WITH_WIFI_ROUTER:
      case BF_EPICARE_BOX:
      case BF_EPICARE_FOCAL_LEFT:
      case BF_EPICARE_FOCAL_RIGHT:
      case BF_BYTEFLIES_KIT:
      case BF_BYTEFLIES_KIT_MODEL_2:
        const boxId = getBoxID(productionOrder.finishedSerial!);
        const boxDockSerial = await getDockID(productionOrder, svc);
        const boxDockId = boxDockSerial?.deviceId;
        if (isBlank(boxDockId)) {
          throw new Error("Unable to find the dock ID");
        }
        return `${boxId}/${boxDockId}`;
      case BF_CARDIOCARE_24H_HOLTER:
      case BF_CARDIOCARE_NON_LINEAR_24H_HOLTER:
        const holterBoxId = getBoxID(productionOrder.finishedSerial!);
        const holterDotId = getDotID(productionOrder);
        if (!holterDotId) {
          throw new Error("Unable to determine dot ID");
        }
        return `${holterBoxId}/${holterDotId}`;
      case BF_NAKED_DOCK:
        const dockSerial = await getDockID(productionOrder, svc);
        const dockId = dockSerial?.deviceId;
        if (isBlank(dockId)) {
          throw new Error("Unable to find the dock ID");
        }
        return dockId;
      case BF_NAKED_DOT:
      case BF_PACKAGED_DOT:
        const dotId = getDotID(productionOrder);
        if (isBlank(dotId)) {
          throw new Error("Unable to find the dot ID");
        }
        return dotId;
      default:
        return (
          productionOrder.finishedSerial?.deviceId ||
          productionOrder.finishedSerial?.udi ||
          productionOrder.finishedSerial?.serial!
        );
    }
  };

  const getDotIds = (productionOrder: ProductionOrder): string[] => {
    return productionOrder.lines
      .filter((line) => line.product?.internalReference === BF_NAKED_DOT)
      .map((line) => line.serial.deviceId!);
  };

  const snipeITAPIfunction = async (
    productionOrder: ProductionOrder,
    svc: OdooOperationsService
  ): Promise<PostHardwareResponse | null> => {
    const reactAppProxy = process.env.REACT_APP_PROXY;
    if (!reactAppProxy) {
      throw new Error("REACT_APP_PROXY not configured");
    }
    const snipeItClient = new SnipeItClient(reactAppProxy);

    const version = getProductVersion(productionOrder.product);
    const bfNumber = snipeitBfNumber(
      productionOrder.product.internalReference!
    );
    const models = await snipeItClient.listModelsById(bfNumber);
    console.log(`Models: ${JSON.stringify(models, null, 2)}`);
    const filteredModels = models.filter((m) => m.model_number === version);
    console.log(`Filtered models: ${JSON.stringify(filteredModels, null, 2)}`);
    if (filteredModels.length === 0) {
      console.error("models", JSON.stringify(models));
      console.error("version", version);
      throw new Error(
        `Unable to find any snipe-it models. Probably the Snipe-IT model "[${productionOrder.product.internalReference}] ${productionOrder.product.name}" does not exist.`
      );
    } else if (filteredModels.length !== 1) {
      console.error("models", JSON.stringify(models));
      console.error("version", version);
      throw new Error(
        "Unable to find correct snipe-it model. Please check Snipe-IT."
      );
    }

    const assetTag = await getAssetTag(productionOrder, svc);
    if (!assetTag) {
      throw new Error("Unable to get Snipe-IT asset tag");
    }
    const dotIds =
      productionOrder.product.internalReference ===
      BF_CARDIOCARE_MULTI_LEAD_LINEAR_BOX
        ? getDotIds(productionOrder)
        : undefined;
    const getResponse = await snipeItClient.getHardwareByTag(assetTag);
    if (getResponse === null) {
      const model = filteredModels[0];
      const hw: PostHardware = {
        status_id: STATUS_DEPLOYABLE,
        location_id: LOCATION_BYTEFLIES_HQ,
        asset_tag: assetTag || "",
        model_id: model.id,
        name:
          productionOrder.product.name ||
          productionOrder.product.internalReference!,
        serial: productionOrder.finishedSerial?.udi || "",
        _snipeit_dot_ids_3: dotIds ? dotIds.join(",") : undefined,
      };
      const postResponse = await snipeItClient.postHardware(hw);
      console.log(`Response: ${JSON.stringify(postResponse, null, 2)}`);
      return postResponse;
    } else {
      return null;
    }
  };

  const icon = (): React.ReactNode => {
    switch (snipeItState) {
      case undefined:
        return undefined;
      case "busy":
        return <CircularProgress size="1em" />;
      case "failed":
        return <Warning />;
      case "success":
        return <CheckCircle />;
      default:
        break;
    }
    return undefined;
  };

  return (
    <Tooltip title="Save device to Snipe-IT">
      <span>
        <Button
          variant="contained"
          data-cy="snipeit-button"
          color="primary"
          fullWidth={props.fullWidth}
          disabled={
            props.disabled ||
            props.productionOrder === undefined ||
            props.productionOrder.product === undefined ||
            props.productionOrder.product.internalReference === undefined ||
            props.productionOrder.finishedSerial === undefined ||
            props.productionOrder.finishedSerial.serial === undefined
          }
          onClick={async () => {
            setSnipeItState("busy");

            try {
              const response = await snipeITAPIfunction(
                props.productionOrder!,
                props.svc!
              );
              console.log(JSON.stringify(response));
              setSnipeItState("success");
              props.onSuccess(response);
            } catch (error) {
              console.error("Failed to save to snipe-it", error);
              setSnipeItState("failed");
              const e = error as Error;
              setError(e.message);
              props.onError(error);
            }
          }}
          startIcon={icon()}
        >
          Snipe-IT
        </Button>

        <Dialog
          open={error !== undefined}
          aria-labelledby="form-dialog-title"
          scroll="paper"
        >
          <DialogTitle>Snipe-IT error</DialogTitle>
          <DialogContent>
            <Alert severity="warning">{error}</Alert>
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://byteflies.snipe-it.io/models"
            >
              https://byteflies.snipe-it.io/models
            </a>
          </DialogContent>
          <DialogActions>
            <Button
              data-cy="ok"
              variant="contained"
              color="primary"
              onClick={() => {
                setError(undefined);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    </Tooltip>
  );
}

export default SnipeItButton;
