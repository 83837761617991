import { useState } from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";
import OdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import { ProductionOrder } from "../erp/manufacturing/Product";
import {
  getProductVersion,
  needsDeviceId,
  getGtinByInternalReference,
} from "../Utils";
import { RegisteryBasedProductSerial } from "../RegistryUtils";
import { isBlank, isNotBlank } from "../StringUtils";
import {
  AddNewRecordCommand,
  IrAttachment,
  MailMessage,
  Many2Many,
  One2Many,
  ProductionOrder as OdooProductionOrder,
} from "@byteflies/odoo-typescript";
import { parseSerial } from "@byteflies/byteflies-serials";
import { getKitchenAidId, getStation, Station } from "../Settings";
import {
  MakeLabelRequest,
  Label as KitchenAidLabel,
  PostPrintJobRequest,
  Printer,
} from "../openapi/kitchenaid/print";
import { v4 as uuidv4 } from "uuid";
import { KitchenAid } from "../KitchenAid";
import { createLabels, getProductionDate } from "./LabelUtils";

const PRINTER_ID = process.env.REACT_APP_PRINTER_ID;

interface PrintLabelButtonProps {
  svc: OdooOperationsService;
  productionOrder: ProductionOrder | undefined;
  onError(error: any): void;
  onSuccess(): void;
}

export const getGtin = (productionOrder: ProductionOrder | undefined) => {
  if (
    productionOrder === undefined ||
    productionOrder.finishedSerial === undefined
  ) {
    return undefined;
  }
  const serial = productionOrder.finishedSerial as RegisteryBasedProductSerial;
  const udi = serial.udi;
  let gtin = "";
  if (
    isBlank(gtin) &&
    serial !== undefined &&
    serial.product !== undefined &&
    getGtinByInternalReference(serial.product.internalReference!) !== undefined
  ) {
    gtin = getGtinByInternalReference(serial.product.internalReference!)!;
  } else if (isBlank(gtin) && isNotBlank(serial.gtin)) {
    gtin = serial.gtin;
  } else if (
    isBlank(gtin) &&
    isNotBlank(udi) &&
    parseSerial(udi) !== undefined
  ) {
    const p = parseSerial(udi);
    if (p !== undefined && p.gtin !== undefined) {
      gtin = p.gtin;
    }
  }

  return gtin;
};

const disabled = (productionOrder: ProductionOrder | undefined) => {
  if (
    productionOrder === undefined ||
    productionOrder.finishedSerial === undefined
  ) {
    return true;
  }
  const serial = productionOrder.finishedSerial as RegisteryBasedProductSerial;
  const gtin = getGtin(productionOrder);
  if (isBlank(gtin)) {
    console.error(
      "Print disabled: no gtin number",
      JSON.stringify(serial.product),
      serial.gtin
    );
    return true;
  } else if (productionOrder.quantity <= 0) {
    console.error("Print disabled: quantity <= 0");
    return true;
  } else if (
    productionOrder.product === undefined ||
    isBlank(getProductVersion(productionOrder.product))
  ) {
    console.error("Print disabled: product version is blank");
    return true;
  } else if (
    productionOrder.finishedSerial === undefined ||
    isBlank(productionOrder.finishedSerial.serial) ||
    isBlank(productionOrder.finishedSerial.udi)
  ) {
    console.error("Print disabled: PO serial is blank");
    return true;
  } else if (getProductionDate(productionOrder) === undefined) {
    console.error("Print disabled: production date is blank");
    return true;
  } else if (
    needsDeviceId(productionOrder.product.internalReference) &&
    isBlank(productionOrder.finishedSerial.deviceId)
  ) {
    console.error("Print disabled: device id is blank");
    return true;
  }
  return false;
};

function PrintLabelButton(props: PrintLabelButtonProps) {
  const { svc, productionOrder, onSuccess, onError } = props;

  const [successState, setSuccessState] = useState<
    undefined | "busy" | "success" | "failed"
  >(undefined);

  const getPrinterId = async (kitchenAid: KitchenAid, station: Station) => {
    if (PRINTER_ID) {
      console.log(`Printer ID set via environmental variable: ${PRINTER_ID}`);
      return PRINTER_ID;
    }
    const kitchenAidId = getKitchenAidId(station);
    const printers: Printer[] = await kitchenAid.listPrinters(kitchenAidId);
    if (printers.length === 0) {
      throw new Error("No printers are available");
    }
    console.log(`
      Found printers:
      ${JSON.stringify(printers)}
    `);
    const kitchenAidPrinters = printers.filter(
      (p) => p.computer.id === kitchenAidId
    );
    if (kitchenAidPrinters.length === 0) {
      throw new Error(
        `No printers are available for '${station}' on '${kitchenAidId}'`
      );
    }
    return kitchenAidPrinters[0].id;
  };

  const printLabelsUsingKitchenAid = async function (
    labels: MakeLabelRequest[],
    copies: number
  ) {
    const station = getStation();
    const test = ["test"].includes(station) ? true : false;
    const kitchenAid = new KitchenAid(test);
    const printerId = await getPrinterId(kitchenAid, station);
    const kitchenAidLabels: KitchenAidLabel[] = [];
    for (const label of labels) {
      const kitchenAidLabel = await kitchenAid.makeLabel(label);
      for (const lbl of kitchenAidLabel) {
        kitchenAidLabels.push(lbl);
      }
    }
    for (const kitchenAidLabel of kitchenAidLabels) {
      const title = uuidv4();
      const printjob: PostPrintJobRequest = {
        printerId: printerId,
        title: title,
        contentType: kitchenAidLabel.contentType,
        content: kitchenAidLabel.content,
        options: {
          bin: kitchenAidLabel.bin,
          copies: copies,
        },
      };
      console.log(`
        Submitting print job
        Title: ${printjob.title}
        Bin: ${printjob.options?.bin}
        Copies: ${printjob.options?.copies}
        Printer: ${printjob.printerId}
      `);
      const printJobId = await kitchenAid.createPrintJob(printjob);
      console.log(`Print job ID: ${printJobId}`);
    }

    const message = await kitchenAidLabelsToMessage(kitchenAidLabels);

    const po = productionOrder;
    if (message !== undefined && po !== undefined && po.id !== undefined) {
      const command: AddNewRecordCommand<MailMessage> = [0, 0, message];
      console.log("writeProductionOrder2", po.id);
      await svc.writeProductionOrder2(po.id, {
        message_ids: [command] as One2Many<MailMessage> as any,
      } as OdooProductionOrder);
    }
  };

  const kitchenAidLabelsToMessage = async function (labels: KitchenAidLabel[]) {
    if (labels.length === 0) {
      return undefined;
    } else {
      const attachments: IrAttachment[] = [];
      for (const [i, label] of labels.entries()) {
        const attachment: IrAttachment = {
          name: `LABEL-${i + 1}`,
          type: "binary",
          datas: label.content,
          mimetype: "application/pdf",
        };
        attachments.push(attachment);
      }

      const message: MailMessage = {
        body: "Labels",
        message_type: "comment",
        model: "mrp.production",
        attachment_ids: attachments.map(
          (attachment) =>
            [0, 0, attachment] as AddNewRecordCommand<IrAttachment>
        ) as Many2Many<IrAttachment>,
      };
      return message;
    }
  };

  return (
    <Button
      data-cy="print-label"
      variant="contained"
      color="primary"
      fullWidth={true}
      disabled={disabled(productionOrder) || successState === "busy"}
      onClick={async () => {
        setSuccessState("busy");
        try {
          const labelRequests = await createLabels(productionOrder!, svc);
          const copies = productionOrder!.quantity;
          await printLabelsUsingKitchenAid(labelRequests, copies);
          setSuccessState("success");
          onSuccess();
        } catch (error) {
          setSuccessState("failed");
          console.error("Failed to make label", error);
          onError(error);
        }
      }}
    >
      {successState === "busy" && <CircularProgress size="1em" />}
      {successState === "failed" && <Warning />}
      {successState === "success" && <CheckCircle />}
      &nbsp; Make Label Document
    </Button>
  );
}

export default PrintLabelButton;
