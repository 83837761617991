import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import IOdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import {
  ProductionOrder,
  ProductSerial,
  QualityCheck,
} from "../erp/manufacturing/Product";
import { QualityCheckFail, QualityCheckPass } from "./QualityCheckPassFail";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";

import {
  addDockToGroup,
  addSensorDotToGroup,
  cloudSetToken,
  Group,
} from "../Plot/cloudApi";
import { Auth } from "aws-amplify";
import {
  BF_NAKED_DOCK,
  BF_NAKED_DOT,
  matrixUrl,
  qualityCheckCode,
} from "../Utils";
import { isBlank } from "../StringUtils";
import {
  AddNewRecordCommand,
  MailMessage,
  One2Many,
  ProductionOrder as OdooProductionOrder,
} from "@byteflies/odoo-typescript";

interface DeviceStatus {
  serial: ProductSerial;
  group?: Group;
  inProgress: boolean;
  error?: Error;
}

interface QualityCheckAddDevicesToGroupProps {
  productionOrder: ProductionOrder;
  group: Group;
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  onError(error: any): void;
  onSuccess(): void;
  onFail(): void;
}

function QualityCheckAddDevicesToGroup(
  props: QualityCheckAddDevicesToGroupProps
) {
  const { productionOrder, group, svc, qualityCheck, onSuccess, onFail } =
    props;

  const [devices, setDevices] = useState<DeviceStatus[]>();
  const [qcState, setQcState] = useState<"none" | "pass" | "fail">();
  const [addingDevices, setAddingDevices] = useState(false);

  const cloudAuthenticate = () => {
    Auth.currentSession().then((response) => {
      const Authentication = response.getIdToken().getJwtToken();
      cloudSetToken(Authentication);
    });
  };

  useEffect(() => {
    cloudAuthenticate();
    setInterval(cloudAuthenticate, 45 * 60 * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const deviceSerials = await svc.readProductionOrderLineSerials(
          productionOrder.id,
          [BF_NAKED_DOT, BF_NAKED_DOCK]
        );

        const myDevices: DeviceStatus[] = [];
        for (const deviceSerial of deviceSerials) {
          const myDevice: DeviceStatus = {
            serial: deviceSerial,
            inProgress: false,
          };
          myDevices.push(myDevice);
        }
        setDevices(myDevices);
      } catch (error) {
        console.error(error);
      }
    };

    if (devices === undefined) {
      fetchDevices();
    }
  }, [
    svc,
    productionOrder,
    devices,
    setDevices,
    group.id,
    qualityCheck,
    onSuccess,
  ]);

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Typography variant="h4">
            Add all devices to the {group.name} group
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4">
            <a target="_blank" rel="noreferrer" href={matrixUrl(qualityCheck)}>
              {qualityCheckCode(qualityCheck)}
            </a>
          </Typography>
        </Grid>

        {(devices === undefined || devices.length === 0) && (
          <Grid item xs={12}>
            <Alert severity="warning">
              No Sensor Dots or Docking Stations found in this production order
            </Alert>
          </Grid>
        )}

        {devices?.map((device) => [
          <Grid item xs={1}>
            {device.error !== undefined ? (
              <Warning data-cy="device-add-to-group-error" />
            ) : device.group?.id === group.id ? (
              <CheckCircle data-cy="device-added-to-group" />
            ) : (
              <Button
                variant="contained"
                color={"primary"}
                fullWidth
                data-cy={`add-device-to-group-${
                  device.serial?.deviceId || "x"
                }`}
                disabled={
                  device.inProgress ||
                  addingDevices ||
                  isBlank(device.serial?.deviceId) ||
                  device.group?.id === group.id
                }
                onClick={async () => {
                  setAddingDevices(true);
                  device.inProgress = true;
                  // needed to re-render
                  setDevices([...devices]);

                  try {
                    switch (device.serial?.product?.internalReference) {
                      case BF_NAKED_DOCK:
                        await addDockToGroup(group.id, device.serial.deviceId!);
                        device.group = group;
                        break;
                      case BF_NAKED_DOT:
                        await addSensorDotToGroup(
                          group.id,
                          device.serial.deviceId!,
                          true
                        );
                        device.group = group;
                        break;
                      default:
                        throw new Error("Unknown device");
                    }
                    if (
                      qualityCheck.state !== "pass" &&
                      devices.length !== 0 &&
                      devices.every((d) => d.group?.id === group.id)
                    ) {
                      await svc.saveQualityCheck(qualityCheck, true);
                      const message: MailMessage = {
                        body: `Added devices to group ${group.name} (${
                          group.id
                        }): ${devices
                          .map(
                            (d) =>
                              `${d.serial.product.name} (${d.serial.serial})`
                          )
                          .join(", ")}`,
                        message_type: "comment",
                        model: "mrp.production",
                      };

                      const command: AddNewRecordCommand<MailMessage> = [
                        0,
                        0,
                        message,
                      ];
                      await svc.writeProductionOrder2(productionOrder.id, {
                        message_ids: [command] as One2Many<MailMessage> as any,
                      } as OdooProductionOrder);
                      qualityCheck.state = "pass";
                      setQcState("pass");
                      onSuccess();
                    }
                  } catch (error) {
                    console.error("Failed to add device to group", error);
                    device.error = error as Error;
                  } finally {
                    // needed to re-render
                    setDevices([...devices]);
                    setAddingDevices(false);
                  }
                }}
              >
                {device.inProgress && <CircularProgress size="1em" />}
                {device.error !== undefined && <Warning />}
                {device.group?.id === group.id && <CheckCircle />}
                &nbsp;Add
              </Button>
            )}
          </Grid>,
          <Grid item xs={11}>
            {`${device.serial?.product.name} / ${device.serial?.deviceId} ${
              device.group?.id === group.id
                ? `has been added to ${group.name}`
                : `to the ${group.name} group`
            }`}
          </Grid>,
        ])}

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://cloud.byteflies.net/groupmgmt"
            >
              https://cloud.byteflies.net/groupmgmt
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={6}>
          <QualityCheckFail
            qualityCheck={qualityCheck}
            label="Fail"
            tooltip="Fail the quality check"
            svc={svc}
            onError={(error) => {
              props.onError(error);
            }}
            onFail={() => {
              if (qualityCheck !== undefined) {
                qualityCheck.state = "fail";
                setQcState("fail");
              }
              onFail();
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <QualityCheckPass
            qualityCheck={qualityCheck}
            svc={svc}
            label="Pass"
            tooltip="Pass the quality check"
            disabled={true}
            onError={(error) => {
              props.onError(error);
            }}
            onSuccess={() => {}}
            qcState={qcState}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default QualityCheckAddDevicesToGroup;
