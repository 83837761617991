import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { CheckCircle, Warning } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";

interface RedButtonProps {
  fullWidth?: boolean;
  disabled?: boolean;
  label: string;
  initialstate?: undefined | "busy" | "success" | "failed";
  onClick(): Promise<void>;
  onError(error: any): void;
  qcState?: "none" | "pass" | "fail";
}
function RedButton(props: RedButtonProps) {
  const { onClick, onError, qcState } = props;
  const [successState, setSuccessState] = useState<
    undefined | "busy" | "success" | "failed"
  >(props.initialstate);

  useEffect(() => {
    if (qcState === "fail") {
      setSuccessState("success");
    } else {
      setSuccessState(undefined);
    }
  }, [qcState]);

  const fail = useCallback(async () => {
    setSuccessState("busy");
    try {
      await onClick();
      setSuccessState("success");
    } catch (error) {
      setSuccessState("failed");
      console.log("Failed to call click", error);
      onError(error);
    }
  }, [onClick, onError]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!props.disabled && event.key === "f") {
        fail();
      }
    },
    [props, fail]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Button
      data-cy="fail"
      variant="contained"
      color="error"
      fullWidth={props.fullWidth}
      onClick={fail}
      disabled={props.disabled}
    >
      {successState === "busy" && <CircularProgress size="1em" />}
      {successState === "failed" && <Warning />}
      {successState === "success" && <CheckCircle />}
      &nbsp;{props.label}
    </Button>
  );
}

export default RedButton;
