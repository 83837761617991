import Grid from "@mui/material/Grid";
import IOdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import { QualityCheck } from "../erp/manufacturing/Product";
import RedButton from "../actions/RedButton";
import GreenButton from "../actions/GreenButton";
import { Alert, AlertTitle, Tooltip, Typography } from "@mui/material";
import { isNotBlank } from "../StringUtils";
import {
  isQualityCheckAutomatic,
  matrixUrl,
  qualityCheckCode,
  qualityCheckTitleWithoutCode,
} from "../Utils";

interface QualityCheckFailProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  onError(error: any): void;
  onFail(qualityCheck: QualityCheck): void;
  label: string;
  tooltip: string;
}
export function QualityCheckFail(props: QualityCheckFailProps) {
  const { svc, qualityCheck, label, tooltip, onFail, onError } = props;

  return (
    <Tooltip title={tooltip}>
      <span>
        <RedButton
          initialstate={qualityCheck.state === "fail" ? "success" : undefined}
          fullWidth
          label={label}
          disabled={qualityCheck === undefined || qualityCheck.id === undefined}
          onClick={async () => {
            try {
              await svc.saveQualityCheck(qualityCheck, false);
              qualityCheck.state = "fail";
              onFail(qualityCheck!);
            } catch (error) {
              console.error(
                "Failed to save quality check",
                error,
                qualityCheck
              );
              onError(error);
            }
          }}
          onError={(error) => {
            onError(error);
          }}
          qcState={qualityCheck.state}
        />
      </span>
    </Tooltip>
  );
}

interface QualityCheckPassProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  onError(error: any): void;
  label: string;
  tooltip: string;
  onSuccess(): void;
  disabled?: boolean;
  qcState?: "none" | "pass" | "fail";
}
export function QualityCheckPass(props: QualityCheckPassProps) {
  const {
    svc,
    qualityCheck,
    onError,
    label,
    tooltip,
    onSuccess,
    disabled,
    qcState,
  } = props;

  return (
    <Tooltip title={tooltip}>
      <span>
        <GreenButton
          initialstate={qualityCheck.state === "pass" ? "success" : undefined}
          qualityCheckCode={qualityCheckCode(qualityCheck)}
          label={label}
          fullWidth
          disabled={
            disabled === undefined
              ? qualityCheck === undefined || qualityCheck.id === undefined
              : disabled
          }
          onClick={async () => {
            try {
              await svc.saveQualityCheck(qualityCheck, true);
              qualityCheck.state = "pass";
              onSuccess();
            } catch (error) {
              console.error(
                "Failed to save quality check",
                error,
                qualityCheck
              );
              onError(error);
            }
          }}
          onError={(error) => {
            props.onError(error);
          }}
          qcState={qcState ?? qualityCheck.state}
        />
      </span>
    </Tooltip>
  );
}

interface QualityCheckPassFailProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  onError(error: any): void;
  onSuccess(): void;
  onFail(): void;
}
function QualityCheckPassFail(props: QualityCheckPassFailProps) {
  const { svc, qualityCheck, onSuccess, onFail } = props;

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={1} style={{ padding: 20 }}>
        <Grid item xs={10}>
          <Typography variant="h4">
            {qualityCheckTitleWithoutCode(qualityCheck)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {qualityCheck !== undefined && qualityCheck.id !== undefined && (
            <Typography variant="h4">
              <a
                target="_blank"
                rel="noreferrer"
                href={matrixUrl(qualityCheck)}
              >
                {qualityCheckCode(qualityCheck)}
              </a>
            </Typography>
          )}
        </Grid>
        {isQualityCheckAutomatic(qualityCheck) && (
          <Grid item xs={12}>
            <Alert
              severity={
                qualityCheck.state === "fail"
                  ? "error"
                  : qualityCheck.state === "pass"
                  ? "success"
                  : "warning"
              }
            >
              <AlertTitle>Important: this check is automatic</AlertTitle>
            </Alert>
          </Grid>
        )}
        {qualityCheck !== undefined && isNotBlank(qualityCheck.note) && (
          <Grid item xs={12}>
            {/* Safer would be to use html-react-parser */}
            <div dangerouslySetInnerHTML={{ __html: qualityCheck.note! }} />
          </Grid>
        )}
        {!isQualityCheckAutomatic(qualityCheck) && (
          <>
            <Grid item xs={6}>
              <QualityCheckFail
                qualityCheck={qualityCheck}
                label="Fail"
                tooltip="Fail the quality check"
                svc={svc}
                onError={(error) => {
                  props.onError(error);
                }}
                onFail={(qc) => {
                  if (qualityCheck !== undefined) {
                    qualityCheck.state = "fail";
                  }
                  onFail();
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <QualityCheckPass
                qualityCheck={qualityCheck}
                svc={svc}
                label="Pass"
                tooltip="Pass the quality check"
                onError={(error) => {
                  props.onError(error);
                }}
                onSuccess={() => {
                  if (qualityCheck !== undefined) {
                    qualityCheck.state = "pass";
                  }
                  onSuccess();
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default QualityCheckPassFail;
