import axios from "axios";
import { isBlank } from "../StringUtils";
import { DateTime } from "luxon";

export const cloudApi = {
  // api_url: "https://fb9f571quc.execute-api.eu-west-1.amazonaws.com/Prod/",
  api_url: "https://api.cloud.byteflies.net/",
  token: "",
};

export interface GetDockStatusDotsResponse {
  position: number;
  dotId?: string;
}

export type GetDockStatusLinkedDotsResponse = {
  dotId: string;
};

export interface GetDockStatusResponse {
  dockId: string;
  groupId: string;
  status: "online" | "offline";
  firmwareVersion?: string;
  desiredFirmwareVersion?: string;
  lastActivity?: number;
  /**
   * The current battery level, in percent
   */
  batteryLevel?: number;
  dots: GetDockStatusDotsResponse[];
  linkedDots?: GetDockStatusLinkedDotsResponse[];
}

export interface DockStatus extends GetDockStatusResponse {
  lastModified: number;
}

export interface Group {
  id: string;
  name: string;
  description?: string;
}

export interface DockConnection {
  groupId: string;
  thingName: string;
  connectivity: boolean;
  timestamp: number;
  firmwareVersion: string;
  // dots: Dot[];
}

export type PutFactoryResetResponse = {
  id: string;
};

export type GetFactoryResetResponse = {
  id: string;
};

export type PutWifiScanRequest = {
  bssids: string[];
};
export type PutWifiScanResponse = {
  id: string;
};

export type WifiNetworkScanResult = {
  bssid: string;
  rssi: number;
};

export type GetWifiScanResponse = {
  networks: WifiNetworkScanResult[];
};

export type DotConfiguration = {
  channel?: number;
  fs?: number;
  gain?: number;
  type: SignalType;
};

export type GetDotConfigurationResponse = {
  configuration: DotConfiguration[];
};

export type GetDockShadowConfigurationResponse = {
  shadow: {
    /**
     * JSON encoded shadoww configuration object
     */
    payload: string;
  };
};

export type SignalType =
  | "ACC"
  | "EEG"
  | "EVENT"
  | "ECG"
  | "EMG"
  | "BAT"
  | "GYR"
  | "LEAD_OFF";

export enum ModeString {
  NORMAL = "normal",
  CALIBRATION = "calibration",
  CONFIGURATION = "configuration",
}

export interface ISignal {
  type: SignalType;
  fs: number;
  channel?: number;
}

export interface ShadowRecording {
  hash: string;
}
export interface DockConfiguration {
  mode: ModeString;
  configSignals?: ISignal[];
  recordings?: ShadowRecording[];
}

export interface ListRecordingSignalResult {
  id: string;
  rawData: string;
  type: SignalType;
}
export interface ListRecordingResult {
  id: string;
  dockName: string;
  dotId: string;
  startDate: number;
  uploadDate: number; //1636453372.728
  duration: number;
  groupId: string;
  patient?: string;
  signals: ListRecordingSignalResult[];
  status: "done";
  stopReason?: string;
}
export interface GetRecordingSignalAlgorithmResult {
  added: number; //1636454192
  id: string;
  type: "EEG_ARTFCTS" | "ECG_ARTFCTS";
}
export interface GetRecordingSignalResult {
  algorithms: GetRecordingSignalAlgorithmResult[];
  channel?: number;
  conversionFactor?: number;
  id: string;
  quality: "CHECK" | "FAIL";
  rawData: string;
  samplingRate: number;
  type: SignalType;
}
export interface GetRecordingResult {
  id: string;
  dockName: string;
  dotId: string;
  firmwareVersion: string;
  startDate: number;
  uploadDate: number; //1636453372.728
  duration: number;
  groupId: string;
  patient?: string;
  signals: GetRecordingSignalResult[];
  status: "done";
  stopReason?: string;
}

export const cloudSetToken = (token: string) => {
  cloudApi.token = token;
};

export const cloudFetchDockStatus = async (
  dockId: string
): Promise<DockStatus | undefined> => {
  if (isBlank(dockId)) {
    throw new Error("dockId is empty");
  }
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }
  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/docks/${dockId}/status`;
  const response = await instance.get<GetDockStatusResponse>(url);
  const lastModified = DateTime.fromRFC2822(
    response.headers["last-modified"]
  ).toSeconds();

  const dockStatus = response.data;
  if (dockStatus !== undefined) {
    return { ...dockStatus, lastModified };
  }

  return undefined;
};

export const putWifi = async (dockId: string, bssids: string[]) => {
  if (isBlank(dockId)) {
    throw new Error("dockId is empty");
  }
  if (bssids.length === 0) {
    throw new Error("bssids is empty");
  }
  const body: PutWifiScanRequest = { bssids: bssids };
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }
  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/docks/${dockId}/wifi`;
  const response = await instance.put<PutWifiScanResponse>(url, body);

  const job = response.data as PutWifiScanResponse;
  if (job !== undefined && job.id !== undefined) {
    return job;
  }

  return undefined;
};

export const getWifi = async (dockId: string, jobId: string) => {
  if (isBlank(dockId)) {
    throw new Error("dockId is empty");
  }
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }
  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/docks/${dockId}/wifi/${jobId}`;
  const response = await instance.get<GetWifiScanResponse>(url);

  const job = response.data as GetWifiScanResponse;
  if (job !== undefined && job.networks !== undefined) {
    return job;
  }

  return undefined;
};

export const putFactoryReset = async (dockId: string) => {
  if (isBlank(dockId)) {
    throw new Error("dockId is empty");
  }

  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }
  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/docks/${dockId}/factoryreset`;
  const response = await instance.put<PutFactoryResetResponse>(url, {});

  const job = response.data;
  if (job !== undefined && job.id !== undefined) {
    return job;
  }

  return undefined;
};

export const getFactoryReset = async (dockId: string, jobId: string) => {
  if (isBlank(dockId)) {
    throw new Error("dockId is empty");
  }
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }
  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/docks/${dockId}/factoryreset/${jobId}`;
  const response = await instance.get<GetFactoryResetResponse>(url);

  const job = response.data;
  if (job !== undefined && job.id !== undefined) {
    return job;
  }

  return undefined;
};

export function cloudGroupUrl(groupId: string) {
  return `https://cloud.byteflies.net/groups/${groupId}/recordings`;
}

export function cloudRecordingUrl(groupId: string, recordingId: string) {
  return `https://cloud.byteflies.net/groups/${groupId}/recordings/${recordingId}`;
}

export const listRecordings = async (
  groupId: string,
  begin: Date,
  end: Date,
  dotId?: string
) => {
  if (begin === undefined) {
    throw new Error("begin is not defined");
  } else if (end === undefined) {
    throw new Error("end is not defined");
  } else if (isBlank(groupId)) {
    throw new Error("groupId is not defined");
  }

  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }
  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 10000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/groups/${groupId}/recordings`;
  const response = await instance.get<ListRecordingResult[]>(url, {
    params: {
      end: end.getTime() / 1000,
      begin: begin.getTime() / 1000,
      dot: dotId,
    },
  });

  return response.data;
};

export const listDockConnections = async (groupId: string) => {
  if (isBlank(groupId)) {
    throw new Error("groupId is not defined");
  }

  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }
  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 10000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/groups/${groupId}/DockConnections`;
  const response = await instance.get<DockConnection[]>(url, {});

  return response.data;
};

export const getRecording = async (groupId: string, recordingId: string) => {
  if (isBlank(groupId)) {
    throw new Error("groupId is not defined");
  } else if (isBlank(recordingId)) {
    throw new Error("recordingId is not defined");
  }

  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }
  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 10000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/groups/${groupId}/recordings/${recordingId}`;
  const response = await instance.get<GetRecordingResult>(url);
  return response.data;
};

export const addDockToGroup = async (groupId: string, dockId: string) => {
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }

  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/groups/${groupId}/docks/${dockId}`;
  await instance.put<string>(url);
};

export const removeDockFromGroup = async (groupId: string, dockId: string) => {
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }

  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });
  const url = `/groups/${groupId}/docks/${dockId}`;
  await instance.delete<string>(url, {
    headers: { Authorization: token },
  });
};

export const addSensorDotToGroup = async (
  groupId: string,
  dotId: string,
  force?: boolean
) => {
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }

  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    params: {
      force: force ? "true" : undefined,
    },
  });

  const url = `/groups/${groupId}/dots/${dotId}`;
  await instance.put<string>(url);
};

export const removeSensorDotFromGroup = async (
  groupId: string,
  dotId: string
) => {
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }

  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });
  const url = `/groups/${groupId}/dots/${dotId}`;
  await instance.delete<string>(url, {
    headers: { Authorization: token },
  });
};

export const addSensorDotToDock = async (
  groupId: string,
  dockId: string,
  dotId: string
) => {
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }

  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/groups/${groupId}/docks/${dockId}/dots/${dotId}`;
  await instance.put<string>(url);
};

export const removeSensorDotFromDock = async (
  groupId: string,
  dockId: string,
  dotId: string
) => {
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }

  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });
  const url = `/groups/${groupId}/docks/${dockId}/dots/${dotId}`;
  await instance.delete<string>(url, {
    headers: { Authorization: token },
  });
};

export const cloudSetDockConfiguration = async (
  dockId: string,
  configuration: DockConfiguration
) => {
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }

  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/docks/${dockId}/config`;
  await instance.put<string>(url, configuration);
};

export const getDockShadowConfiguration = async (dockId: string) => {
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }

  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
  });

  const url = `/docks/${dockId}/shadow`;
  const response = await instance.get<GetDockShadowConfigurationResponse>(url);

  const body = response.data;
  if (
    body === undefined ||
    body.shadow === undefined ||
    body.shadow.payload === undefined ||
    typeof body.shadow.payload !== "string"
  ) {
    throw new Error("No shadow.payload found");
  }
  const payload = JSON.parse(body.shadow.payload);
  const reportedState = payload.state.reported as DockConfiguration;
  return reportedState;
};

export const getSensorDotConfiguration = async (
  groupId: string,
  dotId: string
) => {
  const token = cloudApi.token;
  if (isBlank(token) || isBlank(cloudApi.api_url)) {
    throw new Error("Not logged in");
  }

  const instance = axios.create({
    baseURL: cloudApi.api_url,
    timeout: 5000,
    adapter: require("axios/lib/adapters/http"),
    headers: {
      Authorization: token,
      "content-type": "application/json",
    },
    validateStatus: (s) => s === 200 || s === 404,
  });

  const url = `/groups/${groupId}/dots/${dotId}/configuration`;
  const response = await instance.get<GetDotConfigurationResponse>(url);

  const body = response.data;
  if (
    response.status === 404 ||
    body === undefined ||
    body.configuration === undefined
  ) {
    return undefined;
  }
  return body;
};

export default cloudApi;
