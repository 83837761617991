import * as H from "history";
import { ProductionOrder } from "./erp/manufacturing/Product";
import { Chip } from "@mui/material";

export function openProductionOrder(
  productionOrder: ProductionOrder,
  history: H.History
) {
  if (productionOrder === undefined) {
    throw new Error("Production order is undefined");
  }
  history.push(`production-order/${(productionOrder as ProductionOrder).id}`, {
    productionOrder: productionOrder,
  });
}

export function poStateChip(poState: string) {
  switch (poState) {
    case "draft":
      return <Chip label="Draft" />;
    case "confirmed":
      return <Chip label="Confirmed" />;
    case "cancel":
      return <Chip label="Cancel" color="warning" />;
    case "progress":
      return <Chip label="Progress" color="primary" />;
    case "to_close":
      return <Chip label="To close" />;
    default:
      return <div />;
  }
}
