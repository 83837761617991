import { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { isBlank, isNotBlank } from "../StringUtils";
import { Group, ListRecordingResult } from "./cloudApi";
import moment from "moment";

interface RecordingSelectionDialogProps {
  open: boolean;
  group: Group;
  dotId: string;
  recordings: ListRecordingResult[];
  onOK: (recording: ListRecordingResult) => void;
  onClose: () => void;
}

function formatRecording(recording: ListRecordingResult) {
  const s = recording.startDate * 1000;
  const sd = moment(s).toDate().toISOString();
  const dur = Math.trunc(recording.duration);
  return `${recording.id} (start ${sd}, ${dur} s)`;
}

function RecordingSelectionDialog(props: RecordingSelectionDialogProps) {
  const [recording, setRecording] = useState<ListRecordingResult | undefined>(
    undefined
  );
  const { group, dotId, open, recordings } = props;

  const handleRecording = useCallback(
    (id: string) => {
      const filteredRecording = recordings.filter((r) => r.id === id);
      if (filteredRecording.length === 1) {
        setRecording(filteredRecording[0]);
      } else {
        setRecording(undefined);
      }
    },
    [recordings]
  );

  useEffect(() => {
    if (recordings.length === 1) {
      handleRecording(recordings[0].id);
    }
  }, [recordings, handleRecording]);

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      scroll="paper"
      fullWidth={true}
    >
      <DialogTitle>Select a recording</DialogTitle>
      <DialogContent>
        <DialogContentText>Group</DialogContentText>
        <RadioGroup value={group.id} title="Group">
          {[group].map((g) => (
            <FormControlLabel
              value={g.id}
              key={group.id}
              control={<Radio />}
              label={g.name}
              disabled={true}
            />
          ))}
        </RadioGroup>

        <DialogContentText>Sensor Dot</DialogContentText>
        <RadioGroup value={dotId}>
          {[dotId].map((dot) => (
            <FormControlLabel
              value={dot}
              disabled={true}
              key={dot}
              control={<Radio />}
              label={dot}
              data-cy={`select-sensor-dot-${dot}`}
            />
          ))}
        </RadioGroup>

        <DialogContentText>Recordings</DialogContentText>
        <RadioGroup
          value={recording !== undefined ? recording.id : "-----"}
          onChange={(_, value) => handleRecording(value)}
        >
          {recordings
            .filter((r) => isNotBlank(r.id))
            .sort((a, b) => b.startDate - a.startDate)
            .map((r) => (
              <FormControlLabel
                value={r.id}
                key={r.id}
                control={<Radio />}
                label={formatRecording(r)}
                disabled={isBlank(r.id)}
                data-cy={`select-recording-${r.id}`}
              />
            ))}
        </RadioGroup>

        {(recordings === undefined || recordings.length === 0) && (
          <Alert severity="info">
            No recordings found. Checking again soon ...
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="cancel"
          onClick={() => {
            props.onClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>

        <Button
          data-cy="ok"
          variant="contained"
          color="primary"
          onClick={() => {
            props.onOK(recording!);
          }}
          disabled={recording === undefined || isBlank(recording.id)}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default RecordingSelectionDialog;
