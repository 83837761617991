import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import IOdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import {
  ProductSerial,
  QualityCheck,
  ProductionOrder,
} from "../erp/manufacturing/Product";
import {
  matrixUrl,
  needsDeviceId,
  qualityCheckCode,
  qualityCheckTitleWithoutCode,
} from "../Utils";
import { isNotBlank } from "../StringUtils";
import { TextField, Typography } from "@mui/material";
import GreenButton from "../actions/GreenButton";
import PrintLabelButton, { getGtin } from "../actions/PrintLabelButton";
import { CheckCircle } from "@mui/icons-material";

interface QualityCheckPrintLabelProps {
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  productionOrder?: ProductionOrder;
  serial: ProductSerial;
  allowSkip?: boolean;
  onError(error: any): void;
  onSuccess(): void;
  onFail(): void;
}

function QualityCheckPrintLabel(props: QualityCheckPrintLabelProps) {
  const { svc, qualityCheck, serial, onSuccess, productionOrder, allowSkip } =
    props;
  const [po, setPo] = useState<ProductionOrder>();
  const [skipSuccess, setSkipSuccess] = useState(false);

  useEffect(() => {
    async function handlePo(p: ProductionOrder) {
      try {
        //the finished serial is only id and name, we need both
        if (p.finishedSerial === undefined) {
          p.finishedSerial = serial;
        } else if (
          p.finishedSerial !== undefined &&
          p.finishedSerial.id === serial.id &&
          p.finishedSerial.deviceId !== serial.deviceId
        ) {
          p.finishedSerial = serial;
        }

        setPo(p);
      } catch (error) {
        console.error("error getting serial", error);
      }
    }
    async function fetchPo(serial: ProductSerial) {
      try {
        const po = await svc.searchProductionOrderBySerialId(serial.id);
        if (po) {
          handlePo(po);
        } else {
          console.error("No production order found");
        }
      } catch (error) {
        console.error("error getting serial", error);
      }
    }
    if (productionOrder !== undefined) {
      handlePo(productionOrder);
    } else if (serial !== undefined && serial.id !== undefined) {
      fetchPo(serial);
    }
  }, [svc, serial, setPo, productionOrder]);

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Typography variant="h4">
            {qualityCheckTitleWithoutCode(qualityCheck)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {qualityCheck !== undefined && qualityCheck.id !== undefined && (
            <Typography variant="h4">
              <a
                target="_blank"
                rel="noreferrer"
                href={matrixUrl(qualityCheck)}
              >
                {qualityCheckCode(qualityCheck)}
              </a>
            </Typography>
          )}
        </Grid>

        <Grid item xs={3}>
          <TextField
            size="small"
            fullWidth
            label="Quantity"
            value={po?.quantity || ""}
            placeholder="Quantity"
            disabled={true}
          />
        </Grid>

        <Grid item xs={9}></Grid>

        <Grid item xs={3}>
          <TextField
            size="small"
            fullWidth
            label="Product"
            value={`${po?.quantity || ""} ${po?.product?.name || ""}`}
            placeholder="Quantity"
            disabled={true}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            size="small"
            fullWidth
            label="GTIN"
            value={getGtin(po) || ""}
            placeholder="GTIN"
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid item xs={3}>
          <TextField
            size="small"
            fullWidth
            label="Serial"
            value={po?.finishedSerial?.serial || ""}
            placeholder="Serial"
            disabled={true}
          />
        </Grid>
        <Grid item xs={3}>
          {isNotBlank(po?.finishedSerial?.deviceId) ||
            (needsDeviceId(po?.product?.internalReference) && (
              <TextField
                size="small"
                fullWidth
                label="Device ID"
                value={po?.finishedSerial?.deviceId || ""}
                placeholder="Device ID"
                disabled={true}
              />
            ))}
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid item xs={allowSkip ? 8 : 12}>
          {po !== undefined && (
            <PrintLabelButton
              svc={svc}
              productionOrder={po}
              onError={(error) => {
                props.onError(error);
              }}
              onSuccess={async () => {
                await svc.saveQualityCheck(qualityCheck!, true);
                qualityCheck!.state = "pass";
                onSuccess();
              }}
            />
          )}
        </Grid>
        {allowSkip && (
          <Grid item xs={4}>
            <GreenButton
              qualityCheckCode={qualityCheckCode(qualityCheck)}
              startIcon={skipSuccess && <CheckCircle />}
              label="Skip"
              fullWidth
              onClick={async () => {
                try {
                  await svc.saveQualityCheck(qualityCheck!, true);
                  qualityCheck!.state = "pass";
                  setSkipSuccess(true);
                  onSuccess();
                } catch (error) {
                  console.error(
                    "Failed to save quality check",
                    error,
                    qualityCheck
                  );
                  setSkipSuccess(false);
                  props.onError(error);
                }
              }}
              onError={(error) => {
                props.onError(error);
              }}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default QualityCheckPrintLabel;
