import { StockQuant } from "@byteflies/odoo-typescript";
import { Location } from "./erp/manufacturing/Product";
import {
  WH_BYTEFLIES_MANUFACTURING,
  WH_CARDIOCARE_AT_HOME,
  WH_EPICARE_AT_HOME,
  WH_COVIDCARE_AT_HOME,
  WH_QUARANTAINE,
  WH_STOCK,
  WH_UNKNOWN,
  VIRT_SCRAP,
  VIRT_PRODUCTION,
  WH_COREDOT,
  id,
} from "./erp/odoo/OdooUtils";

export type LocationType =
  | "supplier"
  | "view"
  | "internal"
  | "customer"
  | "inventory"
  | "production"
  | "transit";

export const LOCATIONS: Location[] = [
  {
    id: VIRT_PRODUCTION,
    name: "Production",
    type: "virtual",
  },
  {
    id: WH_UNKNOWN,
    name: "Unknown",
    type: "internal",
  },
  {
    id: WH_QUARANTAINE,
    name: "Quarantaine",
    type: "internal",
  },
  {
    id: WH_BYTEFLIES_MANUFACTURING,
    name: "Byteflies Manufacturing",
    type: "view",
  },
  {
    id: WH_STOCK,
    name: "Stock",
    type: "internal",
  },
  {
    id: WH_CARDIOCARE_AT_HOME,
    name: "CardioCare@Home",
    type: "customer",
  },
  {
    id: WH_EPICARE_AT_HOME,
    name: "EpiCare@Home",
    type: "customer",
  },
  {
    id: WH_COVIDCARE_AT_HOME,
    name: "CovidCare@Home",
    type: "customer",
  },
  {
    id: WH_COREDOT,
    name: "CoreDot@Home",
    type: "internal",
  },
  {
    id: VIRT_SCRAP,
    name: "Scrap",
    type: "inventory",
  },
];

export const SCRAP_LOCATIONS: Location[] = [
  {
    id: VIRT_SCRAP,
    name: "Scrap",
  },
];

export function isScrapped(currentLocations: StockQuant[]) {
  if (currentLocations === undefined || currentLocations.length === 0) {
    return false;
  }

  const scrapped = currentLocations
    .filter((l) => l.quantity > 0)
    .filter((l) => id(l.location_id) === VIRT_SCRAP);
  if (scrapped.length > 0) {
    return true;
  }
  return false;
}