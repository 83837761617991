import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { ProductSerial } from "./erp/manufacturing/Product";
import { CircularProgress, TextField } from "@mui/material";
import { isBlank, isNotBlank } from "./StringUtils";
import { getUdiFromResponse } from "./GetNfcDialog";
import ReadNfcButton from "./ReadNfcButton";
import {
  IOdooOperationsService,
  SerialFilter,
} from "./erp/manufacturing/OdooOperationsService";
import {
  COMPLETE_GROUP_SEPARATOR_STATES,
  getInternalReferencesByGtin,
  GroupSeparatorState,
  GROUP_SEPARATOR,
  hasLabel,
  hasNfc,
} from "./Utils";
import { parseSerial, ParseSerialResponse } from "@byteflies/byteflies-serials";

export function serialToFilter(
  serial: ParseSerialResponse,
  source: SerialSource
): SerialFilter {
  if (isBlank(serial.serial)) {
    throw new Error("Serial should not be blank");
  }

  const filter: SerialFilter = {
    serial: serial.serial!,
  };

  if (isNotBlank(serial.gtin)) {
    const intRefs = getInternalReferencesByGtin(serial.gtin);
    if (intRefs === undefined || intRefs.length === 0) {
      filter.productRefs = [];
    } else if (intRefs.length === 1) {
      filter.productRefs = intRefs;
    } else if (source === "barcode" && intRefs.length > 1) {
      // The QR code applies to multiple products (e.g. naked cradle and packaged cradle)
      // filter out products that do not have a QR code
      const intRefsWithLabels = intRefs.filter(
        (intRef) => hasLabel(intRef) !== false
      );
      if (intRefsWithLabels.length > 0) {
        filter.productRefs = intRefsWithLabels;
      } else {
        filter.productRefs = intRefs;
      }
    } else if (source === "nfc" && intRefs.length > 1) {
      const intRefsWithLabels = intRefs.filter(
        (intRef) => hasNfc(intRef) !== false
      );
      if (intRefsWithLabels.length > 0) {
        filter.productRefs = intRefsWithLabels;
      } else {
        filter.productRefs = intRefs;
      }
    } else {
      filter.productRefs = intRefs;
    }
  } else if (serial.internalReference) {
    filter.productRefs = [serial.internalReference];
  }

  return filter;
}

async function searchSerials(
  svc: IOdooOperationsService,
  str: string | undefined,
  source: SerialSource
): Promise<ProductSerial[]> {
  console.log(`Search serials for: ${str}`);
  if (
    isNotBlank(str) &&
    (str.toLowerCase().startsWith("box-") ||
      str.toLowerCase().startsWith("boxa-") ||
      str.toLowerCase().startsWith("dock-") ||
      str.toLowerCase().startsWith("dot-"))
  ) {
    const filter: SerialFilter = {
      ref: str.trim(),
    };
    const odooSerials = await svc.searchSerialsByName(filter);
    if (odooSerials !== undefined && odooSerials.length > 0) {
      return odooSerials;
    }
  }

  const serial = parseSerial(str);
  if (serial === undefined || isBlank(serial.serial)) {
    return [];
  }

  const filter = serialToFilter(serial, source);
  const odooSerials = await svc.searchSerialsByName(filter);
  if (odooSerials !== undefined) {
    return odooSerials;
  }

  return [];
}
export type SerialSource = "barcode" | "nfc";

export interface SerialSearchFieldProps {
  svc: IOdooOperationsService;
  onSerialsSelected: (
    serials: { serial: ProductSerial; source: SerialSource }[]
  ) => Promise<void>;
  onClear?: () => void;
  onError: (error: any) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
}

function SerialSearchField(props: SerialSearchFieldProps) {
  const componentInputRef = useRef<HTMLInputElement>();

  const grpSepState = useRef<GroupSeparatorState>("");

  const {
    svc,
    onSerialsSelected,
    fullWidth,
    onClear,
    onError,
    inputRef = componentInputRef,
  } = props;
  const [apiBusy, setApiBusy] = useState(false);
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);

  const disabled = useCallback(
    () => props.disabled || apiBusy,
    [props.disabled, apiBusy]
  );

  const handleSearch = async (str: string, source: SerialSource) => {
    if (str === "" && onClear) {
      onClear();
      return;
    }

    try {
      setApiBusy(true);
      setError(false);
      const serials = await searchSerials(svc, str.trim(), source);
      await onSerialsSelected(serials.map((s) => ({ serial: s, source })));
      setInput("");
    } catch (error) {
      console.error("Failed to parse serial", str, error);
      setError(true);
      onError(error);
    } finally {
      setApiBusy(false);
    }
  };

  useEffect(() => {
    if (!disabled() && inputRef.current) {
      inputRef.current.focus();
    }
  }, [disabled, inputRef]);

  return (
    <TextField
      data-cy="serial-search"
      size="small"
      placeholder={"Search by serial number"}
      fullWidth={fullWidth}
      type="search"
      error={error}
      value={input}
      disabled={disabled()}
      inputRef={inputRef}
      onChange={(event) => {
        const newInput = event.target.value;
        setInput(newInput);
        if (newInput === "" && onClear) {
          onClear();
        }
      }}
      onKeyDown={(event) => {
        setError(false);
        const key = event.key;
        if (key === "Enter" && input !== "") {
          event.preventDefault();
          handleSearch(input, "barcode");
        } else if (key === "Alt") {
          event.preventDefault();
          grpSepState.current = "Alt";
        } else if (key === "0" && grpSepState.current === "Alt") {
          event.preventDefault();
        } else if (key === "2" && grpSepState.current === "Alt") {
          event.preventDefault();
          grpSepState.current = "Alt2";
        } else if (key === "9" && grpSepState.current === "Alt2") {
          event.preventDefault();
          grpSepState.current = "Alt29";
        } else if (key === "Control") {
          event.preventDefault();
          grpSepState.current = "Control";
        } else if (key === "]" && grpSepState.current === "Control") {
          event.preventDefault();
          grpSepState.current = "Control]";
        } else if (grpSepState.current !== "") {
          grpSepState.current = "";
        }

        if (COMPLETE_GROUP_SEPARATOR_STATES.includes(grpSepState.current)) {
          const newInput = input + GROUP_SEPARATOR;
          setInput(newInput);
          grpSepState.current = "";
        }
      }}
      InputProps={{
        endAdornment: disabled() ? (
          <CircularProgress size="1.5rem" />
        ) : (
          <ReadNfcButton
            disabled={disabled()}
            onError={(error) => {
              setError(true);
              onError(error);
            }}
            onNfc={async (nfc) => {
              if (nfc !== undefined) {
                setInput("");
                setError(false);

                const udi = getUdiFromResponse(nfc);
                if (isNotBlank(udi)) {
                  setInput(udi);
                  await handleSearch(udi, "nfc");
                }
              }
            }}
          />
        ),
      }}
    />
  );
}

export default SerialSearchField;
