import { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";
import { ProductionOrder } from "../erp/manufacturing/Product";
import OdooOperationsService, {
  ExtendedProductSerial,
} from "../erp/manufacturing/OdooOperationsService";
import { needsDeviceId } from "../Utils";
import { isBlank } from "../StringUtils";
import { productionOrderComplete } from "../erp/odoo/OdooUtils";

export const serialIsDone = (
  productionOrder: ProductionOrder
): productionOrder is ProductionOrder => {
  const serial = productionOrder.finishedSerial;
  if (serial === undefined) {
    console.log("PO serial is not set");
    return false;
  } else if (serial.id === undefined) {
    console.log("PO serial id is not set", JSON.stringify(serial));
    return false;
  } else if (
    needsDeviceId(serial.product?.internalReference) &&
    isBlank(serial.deviceId)
  ) {
    console.log("PO serial deviceId is not set", JSON.stringify(serial));
    return false;
  }

  return true;
};

export const canBeMarkedAsDone = (
  productionOrder: ProductionOrder | undefined
) => {
  if (
    productionOrder === undefined ||
    productionOrder.quantity === 0 ||
    productionOrder.state === undefined
  ) {
    return false;
  } else if (!productionOrderComplete(productionOrder)) {
    return false;
  } else if (
    !["progress", "confirmed", "to_close"].includes(productionOrder.state)
  ) {
    return false;
  } else if (!serialIsDone(productionOrder)) {
    return false;
  }

  if (productionOrder.quality_check_fail === true) {
    return false;
  } else if (productionOrder.quality_check_todo === true) {
    return false;
  } else if (
    productionOrder.quality_check_fail === undefined &&
    productionOrder.quality_check_todo === undefined &&
    // only check each individual quality check if the above booleans values are OK
    // this is because not in all cases the quality checks have been fully loaded (only id's)
    productionOrder.qualityChecks !== undefined
  ) {
    for (const qc of productionOrder.qualityChecks) {
      if (qc.state === undefined || qc.state === "none") {
        return false;
      } else if (qc.state === undefined || qc.state === "fail") {
        return false;
      }
    }
  }
  return true;
};

const assertPoCorrect = async (
  svc: OdooOperationsService,
  productionOrder: ProductionOrder
) => {
  //First perform some sanity checks
  const existingProductionOrder = productionOrder;
  if (existingProductionOrder.id === undefined) {
    throw new Error(`Not an odoo production order`);
  }

  for (const line of productionOrder.lines) {
    if (line.id === undefined) {
      throw new Error(`Not an odoo PO line`);
    }
    const existing = line.serial as ExtendedProductSerial;
    if (existing === undefined) {
      throw new Error(
        `Unable to find serial ${line.serial?.serial} / ${line.serial?.udi} in odoo`
      );
    }
  }

  if (
    productionOrder.finishedSerial === undefined ||
    !svc.isOdooProductSerial(productionOrder.finishedSerial!)
  ) {
    throw new Error(`Serial number is not saved to PO yet`);
  }
};

export const disabled = (productionOrder: ProductionOrder | undefined) => {
  return productionOrder === undefined || !canBeMarkedAsDone(productionOrder);
};

interface MarkAsDoneButtonProps {
  productionOrder: ProductionOrder | undefined;
  svc: OdooOperationsService;
  onError(error: Error): void;
  onDone(productionOrder: ProductionOrder): void;
}

function MarkAsDoneButton(props: MarkAsDoneButtonProps) {
  const [successState, setSuccessState] = useState<
    undefined | "busy" | "success" | "failed"
  >(undefined);
  const { onDone } = props;

  const markAsDone = useCallback(async () => {
    setSuccessState("busy");
    const productionOrder = props.productionOrder!;
    try {
      //First perform some sanity checks
      assertPoCorrect(props.svc, productionOrder);

      // We should be good, mark as done
      const result = await props.svc.markStockProductionAsDone(productionOrder);
      if (
        typeof result === "object"
      ) {
        console.error("Mark as done error", JSON.stringify(result));
        throw new Error("Mark as done error");
      }

      setSuccessState("success");

      const clone: ProductionOrder = { ...productionOrder, state: "done" };
      onDone(clone);
    } catch (error) {
      setSuccessState("failed");
      console.log("Failed to save", error);
      props.onError(error as Error);
    }
  }, [onDone, props]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!disabled(props.productionOrder) && event.key === "d") {
        markAsDone();
      }
    },
    [props, markAsDone]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Button
      data-cy="mark-as-done"
      variant="contained"
      color="secondary"
      fullWidth={true}
      disabled={disabled(props.productionOrder)}
      onClick={markAsDone}
    >
      {successState === "busy" && <CircularProgress size="1em" />}
      {successState === "failed" && <Warning />}
      {successState === "success" && <CheckCircle />}
      {successState !== "success" && <>&nbsp; Mark as done</>}
    </Button>
  );
}

export default MarkAsDoneButton;
