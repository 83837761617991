import { useEffect, useState } from "react";
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async/fixed";

import Grid from "@mui/material/Grid";
import IOdooOperationsService from "../erp/manufacturing/OdooOperationsService";
import { ProductSerial, QualityCheck } from "../erp/manufacturing/Product";
import { QualityCheckFail, QualityCheckPass } from "./QualityCheckPassFail";
import { isBlank, isNotBlank } from "../StringUtils";
import { Button, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { DateTime } from "luxon";

import {
  cloudFetchDockStatus,
  removeDockFromGroup,
  cloudSetToken,
  DockStatus,
  Group,
} from "../Plot/cloudApi";
import { Auth } from "aws-amplify";
import { DockStatusLocal, readDockStatus } from "../DockApiLocal";
import { matrixUrl, qualityCheckCode } from "../Utils";
import { StockPicking } from "@byteflies/odoo-typescript";

interface QualityCheckDockTestProps {
  description: string;
  svc: IOdooOperationsService;
  qualityCheck: QualityCheck;
  serial?: ProductSerial;
  stockPicking?: StockPicking;
  group: Group;
  minimumDots: number;
  removeFromGroupEnabled: boolean;
  onError(error: any): void;
  onSuccess(): void;
  onFail(): void;
}

function isOK(status: DockStatus | undefined, minimumDots: number): boolean {
  if (minimumDots === 0) {
    return true;
  }
  if (
    status === undefined ||
    status.dockId === undefined ||
    status.dots === undefined ||
    status.lastActivity === undefined
  ) {
    return false;
  }
  const dots = status.dots.filter(
    (dot) => isNotBlank(dot.dotId) && !isNaN(dot.position)
  );
  return new Set(dots).size >= minimumDots;
}

function isOK2(
  status: DockStatusLocal | undefined,
  minimumDots: number
): boolean {
  if (minimumDots === 0) {
    return true;
  }
  if (
    status === undefined ||
    status.DockID === undefined ||
    status.DotStates === undefined
  ) {
    return false;
  }
  const dots = status.DotStates.filter(
    (dot) => isNotBlank(dot.Serial) && !isNaN(dot.Slot)
  );
  return dots.length >= minimumDots;
}

function QualityCheckDockTest(props: QualityCheckDockTestProps) {
  const {
    description,
    svc,
    qualityCheck,
    serial,
    onSuccess,
    onFail,
    group,
    removeFromGroupEnabled,
    minimumDots,
    stockPicking,
  } = props;

  const [localLastDockStatus, setLocalLastDockStatus] =
    useState<DockStatusLocal>();
  const [lastDockStatus, setLastDockStatus] = useState<DockStatus>();
  const [dockStatusUpToDate, setDockStatusUpToDate] = useState<boolean>(false);
  const [dockId, setDockId] = useState(serial?.deviceId);

  const cloudAuthenticate = () => {
    Auth.currentSession().then((response) => {
      const Authentication = response.getIdToken().getJwtToken();
      cloudSetToken(Authentication);
    });
  };

  useEffect(() => {
    cloudAuthenticate();
    setInterval(cloudAuthenticate, 45 * 60 * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setIntervalAsync(
      async (dockId: string) => {
        if (isBlank(dockId)) {
          console.error("DockID is empty, unable to dock status");
          return;
        }
        try {
          const status = await cloudFetchDockStatus(dockId);
          setLastDockStatus(status);
          if (stockPicking) {
            const lastModifiedSeconds = status?.lastModified;
            const scheduledDate = stockPicking?.scheduled_date;
            const scheduledDateSeconds = scheduledDate
              ? DateTime.fromSQL(scheduledDate, { zone: "utc" }).toSeconds()
              : undefined;
            if (!lastModifiedSeconds || !scheduledDateSeconds) {
              setDockStatusUpToDate(false);
              return;
            }
            if (lastModifiedSeconds < scheduledDateSeconds) {
              console.log(
                `Dock status timestamp (${lastModifiedSeconds}) is older than stocking picking timestamp (${scheduledDateSeconds})`
              );
              setDockStatusUpToDate(false);
            } else {
              setDockStatusUpToDate(true);
            }
          } else {
            setDockStatusUpToDate(true);
          }
        } catch (error) {
          console.error("Failed to get dock status", error);
        }
      },
      1000,
      dockId
    );
    return () => {
      clearIntervalAsync(timer);
    };
  }, [dockId, group.id, stockPicking]);

  useEffect(() => {
    const timer = setIntervalAsync(async () => {
      try {
        const status = await readDockStatus();

        setLocalLastDockStatus(status);
        setDockId(status.DockID);
        console.log(
          "local dock status",
          group.id,
          status.DockID,
          JSON.stringify(status)
        );
      } catch (error) {
        setLocalLastDockStatus(undefined);
        setDockId(serial?.deviceId);
      }
    }, 1000);
    return () => {
      clearIntervalAsync(timer);
    };
  }, [group.id, setDockId, setLocalLastDockStatus, serial?.deviceId]);

  useEffect(() => {
    if (dockStatusUpToDate && isOK(lastDockStatus, minimumDots)) {
      const saveQualityCheck = async () => {
        await svc.saveQualityCheck(qualityCheck, true);
        qualityCheck!.state = "pass";
      };
      saveQualityCheck().catch(console.error);
    }
  }, [dockStatusUpToDate, lastDockStatus, svc, qualityCheck, minimumDots]);

  return (
    <div
      style={{
        padding: 8,
        alignContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <Typography variant="h4">{description}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4">
            <a target="_blank" rel="noreferrer" href={matrixUrl(qualityCheck)}>
              {qualityCheckCode(qualityCheck)}
            </a>
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {lastDockStatus?.status === "online" ||
          localLastDockStatus !== undefined ? (
            <CheckCircle data-cy="check-dock-dock-usb-online" />
          ) : (
            <CircularProgress
              size="1em"
              data-cy="check-dock-dock-usb-offline"
            />
          )}
        </Grid>
        <Grid item xs={11}>
          <Typography>
            Connect the Docking Station (USB cable to laptop or ethernet over
            USB)
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {lastDockStatus?.status === "online" ||
          localLastDockStatus !== undefined ? (
            <CheckCircle data-cy="check-dock-dock-online" />
          ) : (
            <CircularProgress size="1em" data-cy="check-dock-dock-offline" />
          )}
        </Grid>
        <Grid item xs={11}>
          <Typography>
            Wait until the docking Station is connected to the cloud (green
            light): {lastDockStatus !== undefined ? lastDockStatus.status : "?"}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {dockStatusUpToDate ? (
            <CheckCircle data-cy="check-dock-dock-status-up-to-date" />
          ) : (
            <CircularProgress
              size="1em"
              data-cy="check-dock-dock-status-out-of-date"
            />
          )}
        </Grid>
        <Grid item xs={11}>
          <Typography>
            Wait until the dock status is up to date
            {lastDockStatus
              ? ` (timestamp: ${DateTime.fromSeconds(
                  lastDockStatus?.lastModified!
                ).toISO()})`
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          {dockStatusUpToDate &&
          minimumDots > 0 &&
          (isOK(lastDockStatus, minimumDots) ||
            isOK2(localLastDockStatus, minimumDots)) ? (
            <CheckCircle data-cy="check-dock-dots-ok" />
          ) : (
            <CircularProgress size="1em" />
          )}
        </Grid>
        <Grid item xs={11}>
          {minimumDots > 0 && (
            <Typography>
              Place at least {minimumDots} in the Docking Station. Check if all
              the dots are detected
            </Typography>
          )}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          {!localLastDockStatus &&
            dockStatusUpToDate &&
            minimumDots > 0 &&
            lastDockStatus !== undefined &&
            lastDockStatus.dots !== undefined &&
            lastDockStatus.dots.map((dot) => (
              <span>
                <Button disabled={true} variant="contained">
                  {dot.position}: {dot.dotId}
                </Button>{" "}
              </span>
            ))}

          {minimumDots > 0 &&
            localLastDockStatus !== undefined &&
            localLastDockStatus.DotStates !== undefined &&
            localLastDockStatus.DotStates.sort((a, b) => a.Slot - b.Slot).map(
              (dot) => (
                <span>
                  <Button disabled={true} variant="contained">
                    {dot.Slot}: {dot.Serial}
                  </Button>{" "}
                </span>
              )
            )}
        </Grid>
        {removeFromGroupEnabled && (
          <Grid item xs={1}>
            <Button
              variant="contained"
              color={"secondary"}
              fullWidth
              disabled={
                isBlank(dockId) ||
                (lastDockStatus !== undefined &&
                  isBlank(lastDockStatus.groupId))
              }
              onClick={async () => {
                try {
                  await removeDockFromGroup(group.id, dockId!);
                } catch (error) {
                  console.error("Failed to add Docking Station to group");
                }
              }}
            >
              Remove
            </Button>
          </Grid>
        )}
        {removeFromGroupEnabled && (
          <Grid item xs={9}>
            <Typography>
              Remove the Docking Station from the {group.description}. (Not
              needed when the Docking Station is not part of a group)
            </Typography>
          </Grid>
        )}
        {removeFromGroupEnabled && (
          <Grid item xs={2}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://cloud.byteflies.net/groupmgmt"
            >
              https://cloud.byteflies.net/groupmgmt
            </a>
          </Grid>
        )}
        <Grid item xs={6}>
          <QualityCheckFail
            qualityCheck={qualityCheck}
            label="Fail"
            tooltip="Fail the quality check"
            svc={svc}
            onError={(error) => {
              props.onError(error);
            }}
            onFail={() => {
              if (qualityCheck !== undefined) {
                qualityCheck.state = "fail";
              }
              onFail();
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <QualityCheckPass
            qualityCheck={qualityCheck}
            disabled={
              qualityCheck === undefined ||
              qualityCheck.id === undefined ||
              !(
                isOK(lastDockStatus, minimumDots) ||
                isOK2(localLastDockStatus, minimumDots)
              )
            }
            svc={svc}
            label="Pass"
            tooltip="Pass the quality check"
            onError={(error) => {
              props.onError(error);
            }}
            onSuccess={() => {
              if (qualityCheck !== undefined) {
                qualityCheck.state = "pass";
              }
              onSuccess();
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default QualityCheckDockTest;
