import { StockMoveLine } from "@byteflies/odoo-typescript";
import {
  ExtendedProduct,
  ExtendedProductSerial,
} from "./OdooOperationsService";

export enum ProductType {
  Consumable,
  Service,
  StorableProduct,
}

export enum Tracking {
  ByUniqueSerialNumber,
  ByLots,
  NoTracking,
}

export interface Price {
  quantity: number;
  currency: string;
}

export interface ProductVariant {
  variant: string;
}

export interface Product {
  id: number;
  company_id: number;
  name: string;
  canBeSold: boolean; //Visible in Sales Order Lines
  canBePurchased: boolean; //Visible in Purchase Order Lines
  image?: string;
  type: ProductType;
  internalReference?: string;
  lots?: string[];
  purchase?: {
    logistics?: {
      weight?: number; //kg
      volume?: number; //m3
      manufacturingLeadTime?: number; //days
      customerLeadTime?: number; //days
    };
  };
  inventory?: {
    operations?: {
      manufacture: boolean;
      buy: boolean;
    };
    traceability: {
      tracking: Tracking;
    };
  };
  bom_ids?: number[];
  route_ids?: number[];
}

export enum BillOfMaterialType {
  Manufacture,
  Kit,
}

export interface WorkCenter {
  name: string;
}

export interface BomOperation {
  operation: string;
  workCenter?: WorkCenter;
  defaultDuration: number; //minutes
  description?: string;
}

export interface BomComponent {
  component: Product;
  quantity: number; //units;
  applyOnVariants?: ProductVariant[];
  id: number;
  company_id: number;
  sequence: number;
}

export interface BillOfMaterial {
  product: Product;
  productVariant?: ProductVariant;
  quantity: number; //units
  reference?: string;
  bomType: BillOfMaterialType;
  components?: BomComponent[];
  operations?: BomOperation[];
  id: number;
  company_id: number;
}

export type MrpProductionState =
  | "draft"
  | "confirmed"
  | "progress"
  | "to_close"
  | "done"
  | "cancel";

export interface Location {
  id: number;
  name: string;
  type?: "virtual" | "customer" | "internal" | "view" | "inventory";
}

export interface Company {
  id: number;
  name: string;
}

export interface ProductionOrderTrace {
  product: ExtendedProduct;
  serial: ExtendedProductSerial;
  location: Location;
  po?: ProductionOrder;
}

export interface ProductionOrder {
  id: number;
  name?: string;
  origin?: string;
  product: Product;
  quantity: number;
  //reference?: string; //serial or batch
  date_planned_start?: Date;
  bomType: BillOfMaterialType;
  bom: BillOfMaterial;
  lines: ProductionOrderLine[];
  finishedSerial?: ProductSerial;
  state: MrpProductionState;
  qualityChecks: QualityCheck[];
  location_dest_id?: Location;
  location_src_id?: Location;
  quality_check_fail?: boolean;
  quality_check_todo?: boolean;
  move_raw_ids?: StockMove[];
  move_finished_ids?: StockMove[];
  finished_move_line_ids?: StockMoveLine[];
  move_line_raw_ids?: StockMoveLine[];
  unbuild_ids?: number[];
  unbuild_count?: number;
  workorder_ids?: number[];
}

export interface QualityCheck {
  id: number;
  name: string;
  note?: string;
  title?: string;
  sequence?: number;
  state?: "none" | "pass" | "fail";
  norm_unit?: string;
  tolerance_min?: number;
  tolerance_max?: number;
  product_id?: number;
  lot_id?: number;
  measure?: number;
  test_type?: string;
  test_type_id: any;
  display_name?: string;
  point_id?: number;
  team_id?: number;
}

export interface StockMove {
  id: number;
  product: Product;
  name: string;
  quantity: number;
  sequence: number;
  move_line_ids: number[];
}

export interface ProductionOrderLine {
  id: number;
  product: Product;
  product_qty: number; // the quantity of the product that is planned to be produced
  quantity_done: number; // the quantity of the product that has been produced
  serial: ProductSerial; //serial or batch
  bom_line_id: number;
  company_id: number;
  sequence: number;
  move_line_ids: number[];
}

export interface ProductSerial {
  id: number;
  company_id: number;
  serial?: string; //serial or batch
  product: Product;
  udi?: string;
  deviceId?: string;
  creationDate?: Date;
}
