import { useState } from "react";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { CheckCircle, Memory, Error as ErrorIcon } from "@mui/icons-material";
import { getStation } from "./Settings";
import { KitchenAid } from "./KitchenAid";
import { CradleTesterJob, CradleTesterJobState, CradleTesterJobType, PostCradleTesterJobRequest } from "./openapi/kitchenaid/cradle-tester";
import { getCradleTesterDeviceId } from "./CradleTester";

interface ReadCradleRomIdButtonProps {
  onRomId(id: string): void;
  onError(error: Error): void;
  onClick?(): void;
  disabled?: boolean;
}

export function ReadCradleRomIdButton(props: ReadCradleRomIdButtonProps) {
  const { onRomId, onError, onClick, disabled } = props;

  const [readState, setReadState] = useState<"busy" | "error" | "success" >();
  
  return (
    <Tooltip title="Read cradle ROM ID">
      <span>
        <IconButton
          data-cy="read-cradle-rom-id"
          disabled={disabled || readState === "busy"}
          onClick={async () => {
            setReadState("busy");
            try {
              onClick && onClick();
              const cradleTester = await getCradleTesterDeviceId();
              if (!cradleTester) {
                const msg = "Cradle tester has not been set"
                setReadState("error");
                console.error(msg);
                onError(Error(msg));
                return;
              }
              const jobRequest: PostCradleTesterJobRequest = {
                deviceId: cradleTester,
                type: CradleTesterJobType.ReadRomId,
              };
              console.log(`Read cradle ROM ID on device '${cradleTester}'`);
              const station = getStation();
              const test = ["test"].includes(station) ? true : false;
              const kitchenAid = new KitchenAid(test);
              const jobId = await kitchenAid.createCradleTesterJob(jobRequest);
              console.log(`Cradle tester job ID: ${jobId}`);
              let retries = -1;
              while (retries !== 10) {
                retries++;
                let job: CradleTesterJob;
                try {
                  job = await kitchenAid.readCradleTesterJob(jobId);
                  console.log(`Polling, job result: ${JSON.stringify(job, null, 2)}`);
                } catch (e) {
                  console.error("Polling", e);
                  continue;
                }
                const state = job.state;
                const readRomIdResult = job.readRomIdResult;
                if (state === CradleTesterJobState.Done) {
                  if (readRomIdResult?.romId) {
                    onRomId(readRomIdResult.romId);
                    setReadState("success");
                  } else {
                    throw new Error("Finished reading cradle ROM ID, but no result present");
                  }
                  return;
                } else if (state === CradleTesterJobState.Error) {
                  throw new Error("Cradle ROM ID read job errored out");
                }
                await new Promise((r) => setTimeout(r, 1000));
              }
              throw new Error("Cradle ROM ID read job did not finish in time");
            } catch (e) {
              setReadState("error");
              onError(e as Error);
            }
          }}
        >
          {!readState && <Memory />}
          {readState === "busy" && <CircularProgress size="1em" />}
          {readState === "error" && <ErrorIcon />}
          {readState === "success" && <CheckCircle />}
        </IconButton>
      </span>
    </Tooltip>
  );
}