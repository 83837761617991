import { useState } from "react";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { CheckCircle, Warning } from "@mui/icons-material";
import DockIcon from "./images/DockIcon";
import { DockStatusLocal, readDockStatus } from "./DockApiLocal";
import { isNotBlank } from "./StringUtils";

interface GetDockIdProps {
  disabled?: boolean;
  onDockId(status: DockStatusLocal): void;
  onError(error: any): void;
}
function GetDockIdButton(props: GetDockIdProps) {
  const [status, setStatus] = useState<
    undefined | "busy" | "success" | "failed"
  >(undefined);
  return (
    <Tooltip title="Read dock status">
      <span>
        <IconButton
          data-cy="read-dock-id"
          disabled={props.disabled}
          onClick={async () => {
            setStatus("busy");
            try {
              const status = await readDockStatus();
              if (status !== undefined && isNotBlank(status.DockID)) {
                props.onDockId(status);
              } else {
                throw new Error(
                  "Unable to read Docking Station ID: DockID is empty"
                );
              }
              setStatus("success");
            } catch (error) {
              setStatus("failed");
              props.onError(error);
            }
          }}
          color="primary"
        >
          {status === undefined && <DockIcon />}
          {status === "busy" && <CircularProgress size="1em" />}
          {status === "failed" && <Warning />}
          {status === "success" && <CheckCircle />}
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default GetDockIdButton;
