import { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OdooOperationsService from "./erp/manufacturing/OdooOperationsService";
import {
  id,
  name,
  odooUrl,
  OPERATION_TYPE_RETURN_USED_BOX,
  PARTNER_CUSTOMERS,
  VIRT_PRODUCTION,
  WH_RETURNED_ITEMS,
} from "./erp/odoo/OdooUtils";
import {
  Alert,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IdWithName, StockPicking } from "@byteflies/odoo-typescript";
import SerialSearchField from "./SerialSearchField";
import {
  Location,
  ProductSerial,
} from "./erp/manufacturing/Product";
import { CheckCircle, Error as ErrorIcon } from "@mui/icons-material";

interface StockPickingOverviewProps {
  svc: OdooOperationsService;
}

interface ScannedProduct {
  serial: ProductSerial;
  stockPicking?: StockPicking;
  error?: Error;
  location?: Location;
  returnable?: boolean;
  returning?: boolean;
}

export function Return(props: StockPickingOverviewProps) {
  const { svc } = props;
  const [scannedProducts, setScannedProducts] = useState<ScannedProduct[]>([]);

  const updateScannedProduct = (product: ScannedProduct) => {
    setScannedProducts(
      scannedProducts.map((p) => {
        if (p.serial.serial === product.serial.serial) {
          return product;
        } else {
          return p;
        }
      })
    );
  };

  const returnProduct = async (
    product: ScannedProduct
  ): Promise<ScannedProduct> => {
    const productClone: ScannedProduct = {
      ...product,
      error: undefined,
      returning: true,
    };
    updateScannedProduct(productClone);
    if (!product.location) {
      productClone.error = Error("Product location is not defined");
    } else {
      const odooClient = svc.getOdooClient();
      const odooSession = await svc.getOdooSession();
      let stockPicking: StockPicking | undefined = undefined;
      try {
        stockPicking = await svc.createStockPick(
          [product.serial],
          product.location.id,
          WH_RETURNED_ITEMS,
          id(OPERATION_TYPE_RETURN_USED_BOX),
          false
        );
        if (stockPicking === undefined) {
          throw Error("Stock picking is undefined");
        }
      } catch (error) {
        productClone.error = Error(
          `Failed to create stock picking: ${(error as Error).message}`
        );
      }
      if (stockPicking) {
        try {
          const result = await odooClient.callButtonIds(
            odooSession,
            "stock.picking",
            "button_validate",
            [stockPicking.id!]
          );
          if (result !== false) {
            throw Error("Stock picking validation failed");
          }
          productClone.stockPicking = stockPicking;
          productClone.location = {
            id: stockPicking!.location_dest_id as number,
            name: "WH/Returned Items",
          };
          productClone.returnable = false;
        } catch (error) {
          productClone.error = Error(
            `Failed to execute stock picking: ${(error as Error).message}`
          );
          await odooClient.callButtonIds(
            odooSession,
            "stock.picking",
            "action_cancel",
            [stockPicking.id!]
          );
        }
      }
    }
    productClone.returning = false;
    updateScannedProduct(productClone);
    return productClone;
  };

  return (
    <>
      <Grid container style={{ padding: 24 }}>
        <Grid item xs={8}>
          <Typography
            variant="h4"
            color="inherit"
            style={{ padding: 24, textAlign: "left" }}
          >
            Return
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          <SerialSearchField
            svc={svc}
            fullWidth={true}
            onError={(error) => {
              console.error("Something went wrong scanning serial", error);
            }}
            onSerialsSelected={async (serials) => {
              const scannedProductsClone = [
                ...scannedProducts.filter(
                  (p) =>
                    !serials
                      .map((s) => s.serial.serial)
                      .includes(p.serial.serial)
                ),
              ];
              for (const serial of serials) {
                const product: ScannedProduct = {
                  serial: serial.serial,
                };
                scannedProductsClone.push(product);
                if (svc.isOdooProductSerial(serial.serial)) {
                  const stockQuants = (
                    await svc.searchStockQuantsByLotId(serial.serial.id)
                  ).filter((sq) => sq.quantity !== 0);
                  if (stockQuants.length !== 2) continue;
                  const realStockQuant = stockQuants.find(
                    (sq) => sq.quantity === 1
                  );
                  const prodStockQuant = stockQuants.find(
                    (sq) =>
                      sq.quantity === -1 &&
                      id(sq.location_id) === VIRT_PRODUCTION
                  );
                  if (!(realStockQuant && prodStockQuant)) continue;

                  product.location = {
                    id: id(realStockQuant.location_id as IdWithName)!,
                    name: name(realStockQuant.location_id as IdWithName)!,
                  };
                  if (
                    product.location.id === PARTNER_CUSTOMERS
                  ) {
                    product.returnable = true;
                  }
                }
              }
              setScannedProducts(scannedProductsClone);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Serial</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Return</TableCell>
                  <TableCell>Stock Picking</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scannedProducts.map((product) => (
                  <TableRow key={product.serial.id} data-cy={product.serial.id}>
                    <TableCell>{product.serial.product.name}</TableCell>

                    <TableCell>
                      {product.serial && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={odooUrl("stock.lot", product.serial.id)}
                        >
                          {product.serial.serial}
                        </a>
                      )}
                    </TableCell>

                    <TableCell>
                      <Grid container spacing={1} alignItems="center">
                        {product.location?.name && (
                          <Grid item>{product.location.name}</Grid>
                        )}
                        <Grid item>
                          {product.location?.id === PARTNER_CUSTOMERS ? (
                            ""
                          ) : product.location?.id === WH_RETURNED_ITEMS ? (
                            <CheckCircle color="success" />
                          ) : (
                            <ErrorIcon color="error" />
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell>
                      {product.returning ? (
                        <CircularProgress size="1.5em" />
                      ) : (
                        <Button
                          data-cy={"return-button"}
                          disabled={!product.returnable}
                          onClick={() => {
                            returnProduct(product);
                          }}
                        >
                          Return
                        </Button>
                      )}
                    </TableCell>

                    <TableCell>
                      {product.stockPicking?.id ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={odooUrl(
                            "stock.picking",
                            product.stockPicking.id
                          )}
                        >
                          Link
                        </a>
                      ) : (
                        product.error?.message && (
                          <Alert severity="error">
                            {product.error.message}
                          </Alert>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
