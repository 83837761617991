import axios, { AxiosInstance } from "axios";
import { Auth } from "aws-amplify";
import {
  MakeLabelRequest,
  Printer,
  Label,
  PostPrintJobRequest,
} from "./openapi/kitchenaid/print";
import {
  NfcDevice,
  PostNfcJobRequest,
  NfcJob,
  NfcJobState,
} from "./openapi/kitchenaid/nfc";
import {
  CradleTesterDevice,
  CradleTesterJob,
  PostCradleTesterJobRequest,
} from "./openapi/kitchenaid/cradle-tester";

const DEFAULT_WAIT_TIME_MILLIS = 7500;

export class KitchenAid {
  private readonly axiosInstance: AxiosInstance;

  constructor(test: boolean) {
    const axiosInstance = axios.create({
      baseURL: test
        ? "https://kitchenaid-test.byteflies.com"
        : "https://kitchenaid.byteflies.com",
      timeout: 60000,
    });

    axiosInstance.interceptors.request.use(async (config) => {
      config.headers["Authorization"] = await Auth.currentSession().then(
        (session) => session.getAccessToken().getJwtToken()
      );
      return config;
    });

    this.axiosInstance = axiosInstance;
  }

  public async listPrinters(computerId: string): Promise<Printer[]> {
    return await this.axiosInstance
      .get<Printer[]>("/printers", { params: { computerId: computerId } })
      .then((resp) => resp.data)
      .catch((reason) => {
        const msg = "Failed to list printers";
        console.error(msg, reason);
        throw new Error(msg);
      });
  }

  public async makeLabel(request: MakeLabelRequest): Promise<Label[]> {
    return await this.axiosInstance
      .post<Label[]>("/makeLabel", request)
      .then((resp) => resp.data)
      .catch((reason) => {
        const msg = "Failed to make label";
        console.error(msg, reason);
        throw new Error(msg);
      });
  }

  public async createPrintJob(request: PostPrintJobRequest): Promise<string> {
    return await this.axiosInstance
      .post("/printjobs", request)
      .then((resp) => resp.data)
      .catch((reason) => {
        const msg = "Failed to submit print job";
        console.error(msg, reason);
        throw new Error(msg);
      });
  }

  public async listNfcDevices(computerId?: string): Promise<NfcDevice[]> {
    return await this.axiosInstance
      .get<NfcDevice[]>("/nfc/devices", { params: { computerId: computerId } })
      .then((resp) => resp.data)
      .catch((reason) => {
        const msg = "Failed to list NFC devices";
        console.error(msg, reason);
        throw new Error(msg);
      });
  }

  public async createNfcJob(request: PostNfcJobRequest): Promise<NfcJob> {
    try {
      // POST /jobs can return an id or the job itself
      const response = await this.axiosInstance.post<string | NfcJob>(
        "/nfc/jobs",
        request,
        {
          params: {
            waitTimeMilliSeconds: DEFAULT_WAIT_TIME_MILLIS,
          },
          timeout: DEFAULT_WAIT_TIME_MILLIS + 1000,
        }
      );
      const data = response.data;
      const jobId = data as string;
      const job = data as NfcJob;
      if (typeof jobId === "string") {
        return { id: jobId, state: NfcJobState.New } as NfcJob;
      } else if (typeof job === "object" && job.id !== undefined) {
        return data as NfcJob;
      } else {
        throw new Error("Invalid POST job response");
      }
    } catch (error) {
      const msg = "Failed to create NFC job";
      console.error(msg, error);
      throw error;
    }
  }

  public async readNfcJob(id: string): Promise<NfcJob> {
    return await this.axiosInstance
      .get(`/nfc/jobs/${id}`, {
        params: {
          waitTimeMilliSeconds: DEFAULT_WAIT_TIME_MILLIS,
        },
        timeout: DEFAULT_WAIT_TIME_MILLIS + 1000,
      })
      .then((resp) => resp.data)
      .catch((reason) => {
        const msg = "Failed to get NFC job";
        console.error(msg, reason);
        throw new Error(msg);
      });
  }

  public async listCradleTesterDevices(): Promise<CradleTesterDevice[]> {
    try {
      return (
        await this.axiosInstance.get<CradleTesterDevice[]>(
          "/cradleTester/devices"
        )
      ).data;
    } catch (error) {
      const msg = "Failed to list cradle tester devices";
      console.error(msg);
      console.error(error);
      throw new Error(msg);
    }
  }

  public async createCradleTesterJob(
    request: PostCradleTesterJobRequest
  ): Promise<string> {
    try {
      return (
        await this.axiosInstance.post<string>("/cradleTester/jobs", request)
      ).data;
    } catch (error) {
      const msg = "Failed to create cradle tester job";
      console.error(msg);
      console.error(error);
      throw new Error(msg);
    }
  }

  public async readCradleTesterJob(id: string): Promise<CradleTesterJob> {
    try {
      return (
        await this.axiosInstance.get<CradleTesterJob>(
          `/cradleTester/jobs/${id}`
        )
      ).data;
    } catch (error) {
      const msg = "Failed to get cradle tester job";
      console.error(msg);
      console.error(error);
      throw new Error(msg);
    }
  }
}
