import axios from "axios";
import { isBlank } from "./StringUtils";

export interface FieldTestData {
  LastSeen: string;
}

export async function query(
  dotId: string | undefined
): Promise<FieldTestData | undefined> {
  if (isBlank(dotId)) {
    return undefined;
  }

  const url = `/v2/query`;
  const data = await axios.get<FieldTestData>(url, {
    baseURL: "https://ble.byteflies.com/",
    auth: { username: "admin", password: "n7IkoGIWhVI+6uLHulE7VQ" },
    params: {
      id: dotId,
    },
  });
  return data.data;
}
