export function isBlank(str: string | undefined | null) {
  return (
    str === null ||
    str === undefined ||
    typeof str !== "string" ||
    str.trim().length === 0
  );
}

export function isNotBlank(str: string | undefined | null): str is string {
  return !isBlank(str);
}

export const substringBetween = (s: string, start: string, end: string) => {
  const i1 = s.indexOf(start);
  const i2 = s.indexOf(end);
  if (i1 !== -1 && i2 !== -1) {
    return s.substring(i1 + start.length, i2);
  } else {
    return s;
  }
};

export const substringAfter = (s: string, start: string) => {
  const i1 = s.indexOf(start);
  if (i1 !== -1) {
    return s.substring(i1 + start.length);
  } else {
    return s;
  }
};

export const substringBefore = (s: string, start: string) => {
  const i1 = s.indexOf(start);
  if (i1 !== -1) {
    return s.substring(0, i1);
  } else {
    return s;
  }
};

export const equalsIgnoreCase = (s1: string, s2: string) => {
  if (isBlank(s1) && isBlank(s2)) {
    return true;
  } else if (
    (isBlank(s1) && isNotBlank(s2)) ||
    (isNotBlank(s1) && isBlank(s2))
  ) {
    return false;
  } else {
    return s1.toLowerCase() === s2.toLowerCase();
  }
};
