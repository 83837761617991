import { GetRecordingSignalResult, SignalType } from "../Plot/cloudApi";

const fft = require("fft-js").fft;
const fftUtil = require("fft-js").util;

// [time,channel]
export type CsvRow = [number, number];

const channelZero = function (data: CsvRow[]) {
  var i;
  const ch: number[] = [];

  if (data === undefined) {
    return ch;
  }

  for (i = 0; i < data.length; i++) {
    ch[i] = data[i][1];
  }

  return ch;
};

export function filter(
  signals: Map<GetRecordingSignalResult, CsvRow[]>,
  type: SignalType,
  channel?: number
) {
  for (const [signal, csv] of Array.from(signals.entries())) {
    if (!Array.isArray(csv)) {
      throw new Error(`Invalid CSV row ${JSON.stringify(csv)}`);
    } else if (csv.length > 0 && !Array.isArray(csv[0])) {
      throw new Error(`Invalid first CSV row ${JSON.stringify(csv)}`);
    }

    if (
      signal.type === type &&
      channel !== undefined &&
      signal.channel === channel
    ) {
      return csv;
    } else if (signal.type === type && channel === undefined) {
      return csv;
    }
  }
  return undefined;
}

export const parseCSV = function (rows: CsvRow[] | undefined) {
  const time: number[] = [];
  const channel: number[] = [];

  if (rows === undefined) {
    return { time, channel };
  }

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    if (!Array.isArray(row) || row.length < 2) {
      throw new Error(`Invalid CSV row ${JSON.stringify(row)}`);
    }

    // make sure the header is not included
    if (typeof row[0] === "number" && typeof row[1] === "number") {
      time[i] = row[0];
      channel[i] = row[1];
    }
  }

  return { time, channel };
};

export const filterExg = function (signal: number[]) {
  var order = 4;
  //TODO create a function to calculate the filter coefficients (now done with python with following filter specs)
  //var fl = 0.15625/125
  //var fh = 15.625/125

  var b = [0.02942547, 0, -0.05885095, 0, 0.02942547];
  var a = [1, -3.45684589, 4.49160289, -2.61200423, 0.57724908];

  var filtered = Array(signal.length).fill(0);

  for (var i = order; i < signal.length; i++) {
    for (var j = 0; j <= order; j++) {
      filtered[i] += b[j] * signal[i - j] - a[j] * filtered[i - j];
    }
  }

  order = 3;

  var intermediate = filtered;

  b = [0.03168934, 0.09506803, 0.09506803, 0.03168934];
  a = [1, -1.45902906, 0.910369, -0.19782519];

  for (i = order; i < signal.length; i++) {
    for (j = 0; j <= order; j++) {
      filtered[i] += b[j] * intermediate[i - j] - a[j] * filtered[i - j];
    }
  }

  return filtered as number[];
};

export interface SignalQualityConfig {
  sampleRate: number;
  // should be a power of 2
  blockSize: number;
  minimumFrequency: number;
  maximumFrequency: number;
  minimumMagnitude: number;
}

export interface SignalQualityConfig {
  sampleRate: number;
  // should be a power of 2
  blockSize: number;
  minimumFrequency: number;
  maximumFrequency: number;
  minimumMagnitude: number;
}

export const validateSignal = (rows: CsvRow[], config: SignalQualityConfig) => {
  if (config === undefined) {
    throw new Error("config should not be undefined");
  } else if (config.sampleRate !== 250) {
    throw new Error("sample rate must be 250");
  } else if (rows === undefined || rows.length === 0) {
    return false;
  }

  // In theory we would first need to resample the signal to 250 Hz to fix any missing values
  // and then perform a highpass filter at .5 Hz to remove any DC offset.
  // In practice we can skip this step because the signal is less than one minute.
  // const ch = resample(rows,sampleRate).newValues;
  const ch = channelZero(rows);

  const blocks = splitInBlocks(ch, config.blockSize);
  if (blocks.length <= 4) {
    return false;
  }

  const blockValidities: boolean[] = [];
  for (const block of blocks) {
    const blockOK = validateBlock(block, config);
    blockValidities.push(blockOK);
  }

  const validBlockCount = blockValidities.filter((v) => v === true).length;
  if (validBlockCount === 0) {
    return false;
    // } else if (blockValidities[0] === true) {
    //   console.error("first block is valid, this should never happen");
    //   return false;
    // } else if (blockValidities[blockValidities.length - 1] === true) {
    //   console.error("last block is valid, this should never happen");
    //   return false;
  } else if (validBlockCount < 4) {
    return false;
  }

  return true;
};

const validateBlock = (chunk: number[], config: SignalQualityConfig) => {
  if (chunk.length === config.blockSize) {
    const phasors = fft(chunk);

    // Returns an array of the frequencies (in hertz) of
    // each FFT bin provided, assuming the sampleRate is samples taken per second.
    const frequencies = fftUtil.fftFreq(phasors, config.sampleRate) as number[]; // Sample rate and coef is just used for length, and frequency step

    // Calculate FFT Magnitude for complex numbers
    const magnitudes = fftUtil.fftMag(phasors) as number[];

    var both = frequencies.map(function (f, ix) {
      return { frequency: f, magnitude: magnitudes[ix] };
    });

    for (const freqMag of both) {
      if (
        freqMag.frequency > config.minimumFrequency &&
        freqMag.frequency < config.maximumFrequency &&
        !isNaN(freqMag.magnitude) &&
        freqMag.magnitude > config.minimumMagnitude
      ) {
        return true;
      }
    }
  }

  return false;
};

const splitInBlocks = (arr: any[], len: number) => {
  var chunks = [],
    i = 0,
    n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
};
