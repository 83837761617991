import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import OdooOperationsService, {
  ComparatorCriterium,
  AndCriterium,
  Filter,
} from "./erp/manufacturing/OdooOperationsService";
import { ProductionOrder, ProductSerial } from "./erp/manufacturing/Product";
import { openProductionOrder, poStateChip } from "./ProductionOrderCard";
import SerialSearchField from "./SerialSearchField";
import { isNotBlank } from "./StringUtils";
import { Button, Tooltip } from "@mui/material";
import { dateToOdooDateTime, OdooModel } from "@byteflies/odoo-typescript";
import { odooUrl, productionOrderComplete } from "./erp/odoo/OdooUtils";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { CheckCircle, Pending, Warning } from "@mui/icons-material";

const SORT_MODEL_LOCAL_STORAGE_KEY =
  "byteflies.production-order-overview.sort-model";

class ProductionOrderFilter implements Filter {
  toDomain(): any[] {
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59);

    const datePlannedStart = new ComparatorCriterium(
      "date_planned_start",
      "<=",
      dateToOdooDateTime(endOfDay)
    );
    const state = new ComparatorCriterium("state", "in", [
      "draft",
      "confirmed",
      "progress",
      "to_close",
    ]);
    const active = new ComparatorCriterium("picking_type_id.active", "=", true);
    const and = new AndCriterium(datePlannedStart, state, active);
    return and.toArray().concat([["routecard_production", "=", true]]);
  }
}
interface ProductionOrderOverviewProps {
  svc: OdooOperationsService;
}

function ProductionOrderOverview(props: ProductionOrderOverviewProps) {
  const { svc } = props;
  const [productionOrders, setProductionOrders] = useState<
    ProductionOrder[] | undefined
  >();
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState<Error>();
  const [scannedProductionOrders, setScannedProductionOrders] = useState<
    ProductionOrder[]
  >([]);
  const history = useHistory();

  const rows = () => {
    return scannedProductionOrders !== undefined &&
      scannedProductionOrders.length > 0
      ? scannedProductionOrders
      : productionOrders || [];
  };

  useEffect(() => {
    const fetchPos = async () => {
      try {
        setError(undefined);
        setBusy(true);
        const filter = new ProductionOrderFilter();
        const pos = (await svc.listProductionOrders(filter));
        const boms = await svc.readBoms(pos.map((po) => po.bom.id));
        for (const [index, po] of pos.entries()) {
          po.bom.reference = boms[index].code;
        }
        setProductionOrders(pos);
      } catch (err) {
        setError(err as Error);
        console.error("Failed to fetch production orders", err);
      } finally {
        setBusy(false);
      }
    };

    if (productionOrders === undefined && !busy) {
      fetchPos();
    }
  }, [productionOrders, svc, setProductionOrders, busy, setBusy, setError]);

  const formatSerial = (serial: ProductSerial | undefined) => {
    if (serial !== undefined && isNotBlank(serial.serial)) {
      return serial.serial;
    } else {
      return "";
    }
  };

  const formatQualityIcon = (productionOrder: ProductionOrder) => {
    if (productionOrder.quality_check_fail === true) {
      return (
        <Tooltip title="At least one quality check has failed">
          <Warning color="error" />
        </Tooltip>
      );
    } else if (
      productionOrder.quality_check_fail === false &&
      productionOrder.quality_check_todo === false
    ) {
      return (
        <Tooltip title="No quality checks have failed and none are todo">
          <CheckCircle color="success" />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="At least one quality check is not finished yet">
          <Pending color="disabled" />
        </Tooltip>
      );
    }
  };

  const c: GridColDef[] = [
    {
      headerName: "Reference",
      field: "id",
      width: 160,
      renderCell: (params: GridRenderCellParams<ProductionOrder>) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={odooUrl("mrp.production", params.row?.id || -1) || ""}
        >
          {params?.row?.name || params?.row?.id}
        </a>
      ),
    },
    {
      headerName: "Product",
      field: "product",
      width: 300,
      type: "string",
      valueGetter: (params: GridValueGetterParams<ProductionOrder>) =>
        params.row?.product?.name || "",
      renderCell: (params: GridRenderCellParams<ProductionOrder>) => (
        <>{params?.row?.product?.name}</>
      ),
      sortable: true,
    },
    {
      headerName: "Lot/Serial number",
      field: "finishedSerial",
      width: 240,
      type: "string",
      valueGetter: (params: GridValueGetterParams<ProductionOrder>) =>
        params.row?.finishedSerial?.serial || "",
      renderCell: (params: GridRenderCellParams<ProductionOrder>) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={
            odooUrl(
              "stock.lot" as OdooModel,
              params?.row?.finishedSerial?.id || -1
            ) || ""
          }
        >
          {formatSerial(params?.row?.finishedSerial)}
        </a>
      ),
      sortable: true,
    },

    {
      headerName: "Open",
      field: "open",
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<ProductionOrder>) => (
        <Button
          data-cy={"edit-production-order"}
          onClick={() => {
            openProductionOrder(params.row, history);
          }}
        >
          Open
        </Button>
      ),
    },
    {
      headerName: "BOM",
      field: "bom",
      sortable: false,
      filterable: false,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams<ProductionOrder>) => (
        <>
          {productionOrderComplete(params?.row) ? (
            <CheckCircle color="success" />
          ) : (
            <Pending color="disabled" />
          )}
          &nbsp;
          {params.row.bom.reference
            ? params.row.bom.reference
            : `ID: ${params.row.bom.id}`}
        </>
      ),
    },
    {
      headerName: "QCs",
      field: "qcs",
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<ProductionOrder>) =>
        formatQualityIcon(params?.row!),
    },
    {
      headerName: "Quantity",
      field: "quantity",
      type: "number",
      filterable: false,
    },
    {
      headerName: "State",
      field: "state",
      filterable: false,
      renderCell: (params: GridRenderCellParams<ProductionOrder>) =>
        poStateChip(params?.row?.state || ""),
      sortComparator: (state1: string, state2: string) => {
        return state1.localeCompare(state2);
      },
    },
  ];

  let sortModel;
  const sortModelValue = localStorage.getItem(SORT_MODEL_LOCAL_STORAGE_KEY);
  if (sortModelValue) {
    sortModel = JSON.parse(sortModelValue) as GridSortModel;
  }

  return (
    <Grid container style={{ padding: 24 }}>
      <Grid item xs={4}>
        <Typography variant="h4" color="inherit">
          Production orders
        </Typography>
      </Grid>
      <Grid item xs={5}></Grid>
      <Grid item xs={3}>
        <SerialSearchField
          svc={svc}
          fullWidth={true}
          onClear={() => {
            setScannedProductionOrders([]);
          }}
          onError={(error) => {
            console.error("Failed to search for serials", error);
          }}
          onSerialsSelected={async (serials) => {
            console.log("Serials found", JSON.stringify(serials));
            for (const serial of serials) {
              const pos = await svc.searchProductionOrderBySerials([
                serial.serial,
              ]);

              // We assume that the original serials are better populated than
              // the ones we got from the search above, so replace them
              for (const po of pos) {
                if (
                  po.finishedSerial !== undefined &&
                  po.finishedSerial.id !== undefined
                ) {
                  const existingSerial = serials.find(
                    (s) => s.serial.id === po.finishedSerial!.id
                  );
                  if (existingSerial !== undefined) {
                    po.finishedSerial = existingSerial.serial;
                  }
                }
              }

              // Now re-render
              const cloneScannedPos = [...scannedProductionOrders];
              for (const productionOrder of pos) {
                cloneScannedPos.push(productionOrder);
              }
              setScannedProductionOrders(cloneScannedPos);
            }
          }}
        />
      </Grid>

      <Grid item xs={12} height={700} width={"100%"} flexGrow={1}>
        <DataGrid
          rows={rows()}
          autoHeight={true}
          columns={c}
          density="compact"
          filterMode="client"
          pageSize={100}
          rowsPerPageOptions={[10, 20, 50, 100]}
          sortingMode="client"
          onSortModelChange={(model) =>
            localStorage.setItem(
              SORT_MODEL_LOCAL_STORAGE_KEY,
              JSON.stringify(model)
            )
          }
          initialState={{
            sorting: {
              sortModel: sortModel,
            },
          }}
          loading={busy}
          error={error === undefined ? undefined : true}
        />
      </Grid>
    </Grid>
  );
}

export default ProductionOrderOverview;
