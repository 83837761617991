/* tslint:disable */
/* eslint-disable */
/**
 * NFC api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Computer
 */
export interface Computer {
    /**
     * 
     * @type {string}
     * @memberof Computer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Computer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Computer
     */
    'inet'?: string;
    /**
     * 
     * @type {string}
     * @memberof Computer
     */
    'createTimestamp'?: string;
    /**
     * 
     * @type {ComputerState}
     * @memberof Computer
     */
    'state'?: ComputerState;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ComputerState = {
    Disconnected: 'disconnected',
    Connected: 'connected'
} as const;

export type ComputerState = typeof ComputerState[keyof typeof ComputerState];


/**
 * 
 * @export
 * @interface DeviceCapabilities
 */
export interface DeviceCapabilities {
    /**
     * 
     * @type {boolean}
     * @memberof DeviceCapabilities
     */
    'write'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceCapabilities
     */
    'read'?: boolean;
}
/**
 * 
 * @export
 * @interface NDEFMessage
 */
export interface NDEFMessage {
    /**
     * 
     * @type {Array<NDEFRecord>}
     * @memberof NDEFMessage
     */
    'records': Array<NDEFRecord>;
}
/**
 * 
 * @export
 * @interface NDEFRecord
 */
export interface NDEFRecord {
    /**
     * 
     * @type {string}
     * @memberof NDEFRecord
     */
    'recordType': NDEFRecordRecordTypeEnum;
    /**
     * the mime type
     * @type {string}
     * @memberof NDEFRecord
     */
    'mediaType'?: string;
    /**
     * The record payload
     * @type {string}
     * @memberof NDEFRecord
     */
    'data'?: string;
}

export const NDEFRecordRecordTypeEnum = {
    Mime: 'mime'
} as const;

export type NDEFRecordRecordTypeEnum = typeof NDEFRecordRecordTypeEnum[keyof typeof NDEFRecordRecordTypeEnum];

/**
 * 
 * @export
 * @interface NfcDevice
 */
export interface NfcDevice {
    /**
     * 
     * @type {string}
     * @memberof NfcDevice
     */
    'id': string;
    /**
     * 
     * @type {Computer}
     * @memberof NfcDevice
     */
    'computer': Computer;
    /**
     * 
     * @type {string}
     * @memberof NfcDevice
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof NfcDevice
     */
    'description'?: string;
    /**
     * 
     * @type {DeviceCapabilities}
     * @memberof NfcDevice
     */
    'capabilities'?: DeviceCapabilities;
    /**
     * 
     * @type {object}
     * @memberof NfcDevice
     */
    'default'?: object;
    /**
     * 
     * @type {string}
     * @memberof NfcDevice
     */
    'createTimestamp': string;
}
/**
 * 
 * @export
 * @interface NfcJob
 */
export interface NfcJob {
    /**
     * 
     * @type {string}
     * @memberof NfcJob
     */
    'id': string;
    /**
     * 
     * @type {NfcDevice}
     * @memberof NfcJob
     */
    'device': NfcDevice;
    /**
     * 
     * @type {NfcJobType}
     * @memberof NfcJob
     */
    'type': NfcJobType;
    /**
     * 
     * @type {NDEFMessage}
     * @memberof NfcJob
     */
    'message'?: NDEFMessage;
    /**
     * 
     * @type {string}
     * @memberof NfcJob
     */
    'expireAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NfcJob
     */
    'createTimestamp'?: string;
    /**
     * 
     * @type {NfcJobState}
     * @memberof NfcJob
     */
    'state'?: NfcJobState;
    /**
     * 
     * @type {NfcJobOptions}
     * @memberof NfcJob
     */
    'options'?: NfcJobOptions;
}
/**
 * 
 * @export
 * @interface NfcJobOptions
 */
export interface NfcJobOptions {
    /**
     * 
     * @type {boolean}
     * @memberof NfcJobOptions
     */
    'protect'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NfcJobState = {
    New: 'new',
    SentToClient: 'sent_to_client',
    Done: 'done',
    Error: 'error',
    Expired: 'expired'
} as const;

export type NfcJobState = typeof NfcJobState[keyof typeof NfcJobState];


/**
 * 
 * @export
 * @enum {string}
 */

export const NfcJobType = {
    Read: 'read',
    Write: 'write'
} as const;

export type NfcJobType = typeof NfcJobType[keyof typeof NfcJobType];


/**
 * 
 * @export
 * @interface PostNfcJobRequest
 */
export interface PostNfcJobRequest {
    /**
     * 
     * @type {string}
     * @memberof PostNfcJobRequest
     */
    'deviceId': string;
    /**
     * 
     * @type {NfcJobType}
     * @memberof PostNfcJobRequest
     */
    'type': NfcJobType;
    /**
     * 
     * @type {Array<NDEFRecord>}
     * @memberof PostNfcJobRequest
     */
    'records'?: Array<NDEFRecord>;
    /**
     * The maximum number of seconds we should retain this NFC job in the event that the job cannot be executed immediately. The current default is 14 days or 1,209,600 seconds.
     * @type {number}
     * @memberof PostNfcJobRequest
     */
    'expireAfter'?: number;
    /**
     * 
     * @type {NfcJobOptions}
     * @memberof PostNfcJobRequest
     */
    'options'?: NfcJobOptions;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new NFC job
         * @param {PostNfcJobRequest} [postNfcJobRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nfcJobsPost: async (postNfcJobRequest?: PostNfcJobRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nfc/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postNfcJobRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new NFC job
         * @param {PostNfcJobRequest} [postNfcJobRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nfcJobsPost(postNfcJobRequest?: PostNfcJobRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nfcJobsPost(postNfcJobRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new NFC job
         * @param {PostNfcJobRequest} [postNfcJobRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nfcJobsPost(postNfcJobRequest?: PostNfcJobRequest, options?: any): AxiosPromise<string> {
            return localVarFp.nfcJobsPost(postNfcJobRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Create a new NFC job
     * @param {PostNfcJobRequest} [postNfcJobRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public nfcJobsPost(postNfcJobRequest?: PostNfcJobRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).nfcJobsPost(postNfcJobRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NfcApi - axios parameter creator
 * @export
 */
export const NfcApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get NFC job
         * @param {string} nfcJobId NFC job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNfcJob: async (nfcJobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nfcJobId' is not null or undefined
            assertParamExists('getNfcJob', 'nfcJobId', nfcJobId)
            const localVarPath = `/nfc/jobs/{nfcJobId}`
                .replace(`{${"nfcJobId"}}`, encodeURIComponent(String(nfcJobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List NFC devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNfcDevices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nfc/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List NFC jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNfcJobs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nfc/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NfcApi - functional programming interface
 * @export
 */
export const NfcApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NfcApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get NFC job
         * @param {string} nfcJobId NFC job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNfcJob(nfcJobId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NfcJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNfcJob(nfcJobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List NFC devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNfcDevices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NfcDevice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNfcDevices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List NFC jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listNfcJobs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NfcJob>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listNfcJobs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NfcApi - factory interface
 * @export
 */
export const NfcApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NfcApiFp(configuration)
    return {
        /**
         * 
         * @summary Get NFC job
         * @param {string} nfcJobId NFC job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNfcJob(nfcJobId: string, options?: any): AxiosPromise<NfcJob> {
            return localVarFp.getNfcJob(nfcJobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List NFC devices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNfcDevices(options?: any): AxiosPromise<Array<NfcDevice>> {
            return localVarFp.listNfcDevices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List NFC jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listNfcJobs(options?: any): AxiosPromise<Array<NfcJob>> {
            return localVarFp.listNfcJobs(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NfcApi - object-oriented interface
 * @export
 * @class NfcApi
 * @extends {BaseAPI}
 */
export class NfcApi extends BaseAPI {
    /**
     * 
     * @summary Get NFC job
     * @param {string} nfcJobId NFC job ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NfcApi
     */
    public getNfcJob(nfcJobId: string, options?: AxiosRequestConfig) {
        return NfcApiFp(this.configuration).getNfcJob(nfcJobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List NFC devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NfcApi
     */
    public listNfcDevices(options?: AxiosRequestConfig) {
        return NfcApiFp(this.configuration).listNfcDevices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List NFC jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NfcApi
     */
    public listNfcJobs(options?: AxiosRequestConfig) {
        return NfcApiFp(this.configuration).listNfcJobs(options).then((request) => request(this.axios, this.basePath));
    }
}


