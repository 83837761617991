import { useCallback, useEffect, useState } from "react";
import AssemblyStep from "./AssemblyStep";
import {
  Switch,
  Route,
  useParams,
  Link,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";
import IOdooOperationsService from "./erp/manufacturing/OdooOperationsService";
import { getOdooSettings } from "./Settings";
import { ProductionOrder, QualityCheck } from "./erp/manufacturing/Product";
import {
  isPreBomQualityCheck,
  qualityCheckCodeWithoutProject,
  sortQualityChecks,
} from "./Utils";
import BillOfMaterialEditor from "./BillOfMaterialEditor";
import {
  Breadcrumbs,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { odooUrl, productionOrderComplete } from "./erp/odoo/OdooUtils";
import { SkipNext, SkipPrevious } from "@mui/icons-material";
import { serialIsDone } from "./actions/MarkAsDoneButton";

interface ParamTypes {
  id: string | undefined;
}

interface ProductionOrderPageProps {
  basePath: string;
  svc: IOdooOperationsService;
}

function ProductionOrderPage(props: ProductionOrderPageProps) {
  const { id } = useParams<ParamTypes>();

  const [creator, setCreator] = useState("");

  const { svc, basePath } = props;
  const [productionOrder, setProductionOrder] = useState<ProductionOrder>();
  const [qualityChecks, setQualityChecks] = useState<
    QualityCheck[] | undefined
  >(undefined);

  const getSteps = useCallback((): (QualityCheck | "BOM")[] => {
    const preBomQcs = qualityChecks?.filter(isPreBomQualityCheck) ?? [];
    const postBomQcs =
      qualityChecks?.filter((qc) => !preBomQcs?.includes(qc)) ?? [];
    return [...preBomQcs, "BOM", ...postBomQcs];
  }, [qualityChecks]);

  const getStepPath = useCallback(
    (index: number): string | undefined => {
      const step = getSteps()[index];
      if (step === undefined) {
        return undefined;
      }
      const suffix = step === "BOM" ? "/bom" : "/qc/" + step.id;
      return basePath + "/" + id + suffix;
    },
    [basePath, getSteps, id]
  );

  const [activeStep, setActiveStep] = useState<number>();

  const history = useHistory();
  const location = useLocation();

  const next = useCallback(() => {
    const newActiveStep = (activeStep ?? 0) + 1;
    setActiveStep(newActiveStep);
    const stepPath = getStepPath(newActiveStep);
    if (stepPath) {
      history.push(stepPath);
    }
  }, [activeStep, getStepPath, history]);

  const previous = useCallback(() => {
    const newActiveStep = (activeStep ?? 0) - 1;
    setActiveStep(newActiveStep);
    const stepPath = getStepPath(newActiveStep);
    if (stepPath) {
      history.push(stepPath);
    }
  }, [activeStep, getStepPath, history]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "l") {
        next();
      } else if (event.key === "h") {
        previous();
      } else if (event.key === "b") {
        const newActiveStep = getSteps().findIndex((value) => value === "BOM");
        if (newActiveStep === -1) {
          console.error("Could not swith to BOM editor");
          return;
        }
        setActiveStep(newActiveStep);
        const stepPath = getStepPath(newActiveStep);
        if (stepPath) {
          history.push(stepPath);
        }
      } else if (event.key === "[") {
        const newActiveStep = 0;
        setActiveStep(newActiveStep);
        const stepPath = getStepPath(newActiveStep);
        if (stepPath) {
          history.push(stepPath);
        }
      } else if (event.key === "]") {
        const newActiveStep = getSteps().length - 1;
        setActiveStep(newActiveStep);
        const stepPath = getStepPath(newActiveStep);
        if (stepPath) {
          history.push(stepPath);
        }
      }
    },
    [next, previous, history, getStepPath, getSteps]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (activeStep !== undefined || !qualityChecks) return;
    const routeStep = getSteps().findIndex((_, index) => {
      const stepPath = getStepPath(index);
      if (stepPath === location.pathname) return true;
      return false;
    });
    if (routeStep !== -1) {
      setActiveStep(routeStep);
    }
  }, [location, activeStep, getSteps, getStepPath, qualityChecks]);

  useEffect(() => {
    if (creator === undefined || creator === "") {
      const settings = getOdooSettings();
      if (
        settings !== undefined &&
        settings.fullname !== undefined &&
        settings.fullname !== ""
      ) {
        setCreator(settings.fullname);
      }
    }
  }, [creator, setCreator]);

  useEffect(() => {
    async function readPo(id: number) {
      try {
        const po = await svc.readProductionOrder(id, {
          readQualityChecks: false,
          readLot: true,
          readStockMoves: false,
        });
        setProductionOrder(po);

        const checkIds = po.qualityChecks?.map((qc) => qc.id);
        if (checkIds.length > 0) {
          const checks = await svc.readQualityChecks(checkIds);
          const qcs = sortQualityChecks(
            po.product?.internalReference || "",
            checks
          );
          po.qualityChecks = checks;
          setProductionOrder({ ...po, qualityChecks: qcs });
          setQualityChecks(qcs);
        } else {
          setQualityChecks([]);
        }

        const lineIds = po.lines?.map((l) => l.id);
        if (lineIds.length > 0) {
          const lines = await svc.readStockMoves3(lineIds);
          setProductionOrder({ ...po, lines: lines });
        }
      } catch (error) {
        console.error("error getting PO", id, error);
      }
    }
    if (id !== undefined && id !== "") {
      readPo(+id);
    }
  }, [id, setProductionOrder, svc, setQualityChecks]);

  if (id === undefined) {
    return <div>Production order ID not found</div>;
  }

  return (
    <>
      <Grid container spacing={1} style={{ padding: 20 }}>
        <Grid item xs={4}>
          <Breadcrumbs>
            <Link to="/">Production orders</Link>

            {productionOrder !== undefined &&
              svc.isOdooProductionOrder(productionOrder) && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={odooUrl("mrp.production", productionOrder.id) || ""}
                >
                  {productionOrder.name || productionOrder.id}
                </a>
              )}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={8} />
        {activeStep !== undefined && (
          <>
            <Grid item xs={1}>
              <Button
                data-cy="previous"
                variant="contained"
                color="inherit"
                fullWidth={true}
                disabled={activeStep === 0}
                onClick={previous}
                startIcon={<SkipPrevious />}
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={10}>
              <Stepper activeStep={activeStep}>
                {getSteps().map((step, index) => (
                  <Step
                    key={getStepPath(index)}
                    completed={
                      step === "BOM"
                        ? productionOrderComplete(productionOrder) &&
                          serialIsDone(productionOrder)
                        : step.state === "pass"
                    }
                  >
                    <StepLabel error={step !== "BOM" && step.state === "fail"}>
                      <Typography
                        variant="body1"
                        color={
                          index === activeStep
                            ? "text.primary"
                            : "text.disabled"
                        }
                        sx={{
                          fontWeight: index === activeStep ? "bold" : "regular",
                        }}
                      >
                        {step === "BOM"
                          ? "BOM"
                          : qualityCheckCodeWithoutProject(step)}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={1}>
              <Button
                data-cy="next"
                variant="contained"
                color="primary"
                fullWidth={true}
                disabled={activeStep === getSteps().length - 1}
                onClick={next}
                endIcon={<SkipNext />}
              >
                Next
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      {qualityChecks !== undefined && (
        <Switch>
          <Route exact path={basePath + "/" + id}>
            <Redirect to={getStepPath(0)!} />
          </Route>
          <>
            <Route exact path={basePath + "/" + id + "/bom"}>
              <BillOfMaterialEditor
                svc={svc}
                productionOrder={productionOrder!}
                onChange={(po) => {
                  console.log("onChange(po)", JSON.stringify(po));
                  setProductionOrder({ ...po, lines: [...po.lines] });
                  setQualityChecks([...po.qualityChecks]);
                }}
              />
            </Route>
            {qualityChecks.map((qc) => (
              <Route path={basePath + "/" + id + "/qc/" + qc.id} key={qc.id}>
                <AssemblyStep
                  svc={svc}
                  productionOrder={productionOrder!}
                  qualityCheck={qc}
                  onChange={(po) => {
                    setProductionOrder({ ...po });
                    setQualityChecks([...po.qualityChecks]);
                  }}
                />
              </Route>
            ))}
          </>
        </Switch>
      )}
    </>
  );
}

export default ProductionOrderPage;
