import { GetRecordingSignalResult } from "./cloudApi";
import React from "react";
import {
  CsvRow,
  parseCSV,
  filter,
  filterExg,
} from "../quality_checks/QualityCheckTestSensorDotUtils";
import Plot from "react-plotlyjs-ts";

interface PlotDataProps {
  signals: Map<GetRecordingSignalResult, CsvRow[]>;
}

interface PlotlyData {
  type: "scatter";
  mode: "lines";
  name: string;
  x: number[];
  y: number[];
  visible?: "legendonly";
  line: { color: string };
}
function PlotData(props: PlotDataProps) {
  const bat = parseCSV(filter(props.signals, "BAT"));
  const acc = parseCSV(filter(props.signals, "ACC"));
  const eeg1 = parseCSV(filter(props.signals, "EEG", 1));
  const leadOff1 = parseCSV(filter(props.signals, "LEAD_OFF", 1));
  const eeg2 = parseCSV(filter(props.signals, "EEG", 2));
  const leadOff2 = parseCSV(filter(props.signals, "LEAD_OFF", 2));
  const ch1Filt = filterExg(eeg1.channel);
  const ch2Filt = filterExg(eeg2.channel);
  const ch1Trunc = ch1Filt;
  const ch2Trunc = ch2Filt;

  for (let j = 0; j < ch2Trunc.length; j++) {
    ch2Trunc[j] += 66000;
  }

  const eeg1Trace: PlotlyData = {
    type: "scatter",
    mode: "lines",
    name: "EEG Channel 1",
    x: eeg1.time,
    y: ch1Trunc,
    line: { color: "#7F7F7F" },
  };
  const eeg2Trace: PlotlyData = {
    type: "scatter",
    mode: "lines",
    name: "EEG Channel 2",
    x: eeg2.time,
    y: ch2Trunc,
    line: { color: "#17BECF" },
  };
  const lo1Trace: PlotlyData = {
    type: "scatter",
    mode: "lines",
    name: "Lead_off Channel 1",
    x: leadOff1.time,
    y: leadOff1.channel,
    visible: "legendonly",
    line: { color: "#7F7F7F" },
  };
  const lo2Trace: PlotlyData = {
    type: "scatter",
    mode: "lines",
    name: "Lead_off Channel 2",
    x: leadOff2.time,
    y: leadOff2.channel,
    visible: "legendonly",
    line: { color: "#17BECF" },
  };
  const trace3: PlotlyData = {
    type: "scatter",
    mode: "lines",
    name: "Battery",
    x: bat.time,
    y: bat.channel,
    visible: "legendonly",
    line: { color: "#7F7F7F" },
  };
  const trace4: PlotlyData = {
    type: "scatter",
    mode: "lines",
    name: "Accelerometer",
    x: acc.time,
    y: acc.channel,
    visible: "legendonly",
    line: { color: "#17BECF" },
  };

  const traces: PlotlyData[] = [
    eeg1Trace,
    eeg2Trace,
    lo1Trace,
    lo2Trace,
    trace3,
    trace4,
  ];
  return (
    <Plot
      data={traces}
      layout={{ width: 1440, height: 640, title: undefined }}
    />
  );
}

export default React.memo(PlotData);
