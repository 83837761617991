import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { setIntervalAsync, clearIntervalAsync } from "set-interval-async/fixed";
import { isBlank } from "../StringUtils";
import {
  cloudFetchDockStatus,
  cloudSetToken,
  GetDockStatusDotsResponse,
} from "./cloudApi";
import { Auth } from "aws-amplify";
import { MANUFACTURING_GROUP } from "../Utils";

const EMPTY_DOTS: GetDockStatusDotsResponse[] = [
  { position: 0, dotId: "..." },
  { position: 1, dotId: "..." },
  { position: 2, dotId: "..." },
  { position: 3, dotId: "..." },
  { position: 4, dotId: "..." },
];

interface SensorDotSelectionDialogProps {
  open: boolean;
  onOK: (dotId: string) => void;
  onClose: () => void;
}
function SensorDotSelectionDialog(props: SensorDotSelectionDialogProps) {
  const [error, setError] = useState<Error>();
  const group = MANUFACTURING_GROUP;
  const dockIds: string[] = ["Dock-d49cdd48d7c0"];
  const [dockId, setDockId] = useState<string>(dockIds[0]);
  const [dots, setDots] = useState<GetDockStatusDotsResponse[]>(EMPTY_DOTS);
  const [dot, setDot] = useState<GetDockStatusDotsResponse | undefined>(
    undefined
  );

  useEffect(() => {
    const timer = setIntervalAsync(
      async (dockId: string | undefined) => {
        if (dockId === undefined || dockId === "") {
          setDots(EMPTY_DOTS);
          return;
        }

        try {
          const session = await Auth.currentSession();
          const token = session?.getIdToken().getJwtToken();
          cloudSetToken(token);

          const dockStatus = await cloudFetchDockStatus(dockId);
          if (
            dockStatus !== undefined &&
            dockStatus.dots !== undefined &&
            Array.isArray(dockStatus.dots)
          ) {
            setDots(dockStatus.dots);
            setError(undefined);
          }
        } catch (error) {
          console.error("Failed to get dock status", dockId, error);
          setError(error as Error);
          setDots(EMPTY_DOTS);
        }
      },
      1000,
      dockId
    );
    return () => {
      clearIntervalAsync(timer);
    };
  }, [dockId, setDots, setDockId, setError, group.id]);

  return (
    <Dialog
      open={props.open}
      aria-labelledby="form-dialog-title"
      data-cy={`select-sensor-dot-dialog`}
      scroll="paper"
      fullWidth={true}
    >
      <DialogTitle>Select a Sensor Dot</DialogTitle>
      <DialogContent>
        <DialogContentText>Group</DialogContentText>
        <RadioGroup value={group.id}>
          {[group].map((g) => (
            <FormControlLabel
              value={g.id}
              key={group.id}
              control={<Radio />}
              label={g.name}
              disabled={true}
            />
          ))}
        </RadioGroup>

        <DialogContentText>Docking Station</DialogContentText>
        <RadioGroup
          value={dockId}
          onChange={async (_, value) => {
            const selectedDock = value as string;
            const filteredDocks1 = dockIds
              .filter((c) => c !== undefined && c !== null && c !== "")
              .filter((c) => c.toLowerCase() === selectedDock.toLowerCase());
            if (filteredDocks1.length === 1) {
              setDockId(filteredDocks1[0]);
            }

            try {
              setDots(EMPTY_DOTS);

              const session = await Auth.currentSession();
              const token = session?.getIdToken().getJwtToken();
              cloudSetToken(token);

              const dockStatus = await cloudFetchDockStatus(selectedDock);

              if (
                dockStatus !== undefined &&
                dockStatus.dots !== undefined &&
                Array.isArray(dockStatus.dots)
              ) {
                setDots(dockStatus.dots);
                setError(undefined);
              }
            } catch (error) {
              console.error("Failed to get dock status", value, error);
              setError(error as Error);
              setDots(EMPTY_DOTS);
            }
          }}
        >
          {dockIds.map((dockId) => (
            <FormControlLabel
              value={dockId}
              key={dockId}
              control={<Radio />}
              label={dockId}
              disabled={group === undefined || group.id === undefined}
              data-cy={`select-docking-station-${dockId}`}
            />
          ))}
        </RadioGroup>

        <DialogContentText>Sensor Dot</DialogContentText>

        {error !== undefined && (
          <Alert severity="warning">
            Something went wrong when listing the current Sensor Dots. Please
            try again.
          </Alert>
        )}

        <RadioGroup
          value={dot !== undefined ? dot.dotId : "-----"}
          onChange={async (_, value) => {
            const selectedDot = value as string;
            try {
              setDot(undefined);
              const filteredDot = dots
                .filter(
                  (c) =>
                    c.dotId !== undefined && c.dotId !== null && c.dotId !== ""
                )
                .filter(
                  (c) => c.dotId!.toLowerCase() === selectedDot.toLowerCase()
                );
              if (filteredDot.length > 0) {
                setDot(filteredDot[0]);
              }
            } catch (error) {
              console.error("Failed to select Sensor Dot", error);
              setError(error as Error);
            }
          }}
        >
          {dots.map((d) => (
            <FormControlLabel
              value={d.dotId || ""}
              key={`${d.position}-${d.dotId}`}
              control={<Radio />}
              label={`slot ${d.position}: ${d.dotId}`}
              disabled={isBlank(d.dotId) || d.dotId === "..."}
              data-cy={`select-sensor-dot-${d.dotId}`}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="cancel"
          onClick={() => {
            props.onClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>

        <Button
          data-cy="ok"
          variant="contained"
          color="primary"
          onClick={() => {
            if (dot!.dotId !== undefined) {
              props.onOK(dot!.dotId);
            }
          }}
          disabled={dot === undefined || isBlank(dot.dotId)}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default SensorDotSelectionDialog;
