import { useState } from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { CheckCircle, Nfc, Warning } from "@mui/icons-material";
import { ProductionOrder, QualityCheck } from "../erp/manufacturing/Product";
import { needsDeviceId } from "../Utils";
import { getStation } from "../Settings";
import { writeNfc, generateNdefRecords } from "../Nfc";
import { NDEFRecord } from "../openapi/kitchenaid/nfc";

interface WriteNfcTagButtonProps {
  productionOrder: ProductionOrder | undefined;
  qualityCheck?: QualityCheck;
  onError(error: Error): void;
  onSuccess(records: NDEFRecord[]): void;
}
function WriteNfcTagButton(props: WriteNfcTagButtonProps) {
  const { productionOrder, onError, onSuccess } = props;

  const [writeNfcState, setWriteNfcState] = useState<
    undefined | "busy" | "success" | "failed"
  >(undefined);

  return (
    <Button
      data-cy="write-nfc"
      variant="contained"
      color="primary"
      fullWidth={true}
      disabled={
        productionOrder === undefined ||
        productionOrder.product === undefined ||
        productionOrder.finishedSerial === undefined ||
        (needsDeviceId(productionOrder.product.internalReference) &&
          productionOrder.finishedSerial.deviceId === undefined) ||
        (needsDeviceId(productionOrder.product.internalReference) &&
          productionOrder.finishedSerial.deviceId === "") ||
        productionOrder.finishedSerial.udi === undefined ||
        productionOrder.finishedSerial.udi === ""
      }
      onClick={async () => {
        try {
          const station = getStation();
          if (station === undefined) {
            onError(new Error("Select a station in settings first."));
            return;
          }
          const records = generateNdefRecords(productionOrder!.finishedSerial!);
          if (records === undefined || records.length === 0) {
            onError(new Error("NFC records should not be empty"));
            return;
          }
          setWriteNfcState("busy");
          await writeNfc(station, records);
          setWriteNfcState("success");
          onSuccess(records);
        } catch (error) {
          setWriteNfcState("failed");
          console.error("Failed to write NFC", error);
          onError(error as Error);
        }
      }}
    >
      {writeNfcState === undefined && <Nfc />}
      {writeNfcState === "busy" && <CircularProgress size="1em" />}
      {writeNfcState === "failed" && <Warning />}
      {writeNfcState === "success" && <CheckCircle />}
      &nbsp;Write NFC Documents
    </Button>
  );
}

export default WriteNfcTagButton;
