import { TextField } from "@mui/material";
import { getIdFromResponse } from "./GetNfcDialog";
import ReadNfcButton from "./ReadNfcButton";
import { isBlank } from "./StringUtils";
import {
  BF_COVIDCARE_BOX_1,
  BF_COVIDCARE_BOX_2,
  hasIdChip,
  hasNfc,
} from "./Utils";
import GetNextSequenceButton from "./GetNextSequenceButton";
import OdooOperationsService from "./erp/manufacturing/OdooOperationsService";
import { ReadCradleRomIdButton } from "./ReadCradleRomIdButton";
import { useState } from "react";

export interface DeviceIdFieldProps {
  svc: OdooOperationsService;
  productInternalReference: string;
  deviceId: string | undefined;
  onChange: (deviceId: string) => void;
  onError(error: Error): void;
  onNfcStart?(): void;
  onNfcFinish?(): void;
  nfcBusy?: boolean;
}

function DeviceIdField(props: DeviceIdFieldProps) {
  const {
    deviceId,
    productInternalReference,
    svc,
    onNfcStart,
    onNfcFinish,
    nfcBusy,
  } = props;
  const [error, setError] = useState<Error>();

  return (
    <TextField
      data-cy="finished-product-device-id"
      fullWidth
      label="Device ID"
      size="small"
      error={error !== undefined}
      helperText={error?.message}
      disabled={true}
      value={isBlank(deviceId) ? "" : deviceId}
      placeholder="Device ID"
      InputProps={{
        id: "finished-product-device-id",
        endAdornment:
          productInternalReference === BF_COVIDCARE_BOX_1 ||
          productInternalReference === BF_COVIDCARE_BOX_2 ? (
            <GetNextSequenceButton
              svc={svc}
              code={
                productInternalReference === BF_COVIDCARE_BOX_1
                  ? "byteflies.covidcare.box.v0"
                  : productInternalReference === BF_COVIDCARE_BOX_2
                  ? "byteflies.covidcare.box.v1"
                  : "unknown"
              }
              onNextSequence={(sequence) => {
                console.info("Next box sequence", sequence);
                props.onChange(sequence);
              }}
              onError={(error) => {
                console.error("Failed to get next box sequence", error);

                props.onError(error);
              }}
            />
          ) : hasIdChip(productInternalReference) ? (
            <ReadCradleRomIdButton
              onError={async (error) => {
                console.error("Failed to read cradle ROM ID");
                console.error(error);
                setError(error);
                props.onError(error);
              }}
              onRomId={(romId) => {
                props.onChange(romId);
              }}
              disabled={deviceId !== undefined && deviceId.length !== 0}
              onClick={() => setError(undefined)}
            />
          ) : hasNfc(productInternalReference) ? (
            <ReadNfcButton
              onStart={onNfcStart}
              onFinish={onNfcFinish}
              disabled={nfcBusy}
              onError={async (error) => {
                console.error("Failed to read NFC");
                console.error(error);
                setError(error);
                props.onError(error);
              }}
              onNfc={(nfc) => {
                if (nfc !== undefined) {
                  const deviceId = getIdFromResponse(nfc);
                  if (deviceId !== undefined) {
                    props.onChange(deviceId);
                  } else {
                    console.error("Unable to parse NFC", JSON.stringify(nfc));
                  }
                }
              }}
            />
          ) : null,
      }}
    />
  );
}

export default DeviceIdField;
