import { Auth } from "aws-amplify";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export const STATUS_DEPLOYABLE = 2;
export const LOCATION_BYTEFLIES_HQ = 1;

export interface SnipeItError {
  status: "error";
  messages: string;
}

interface SnipeItDate {
  datetime: string;
  formatted: string;
}
export interface SnipeItListResult<T> {
  rows: T[];
  total: number;
}

export interface SnipeItRef {
  id: number;
  name: string;
}
export interface ListHardware {
  id: number;

  asset_tag: string;
  serial?: string;

  status_id: number;

  model_id: number;

  name: string;

  model: SnipeItRef;
  model_number: number | string;

  status_label: {
    id: number;
    name: string;
    status_meta: string;
    status_type: "archived";
  };
  company: string;
  location: SnipeItRef;
  created_at: SnipeItDate;
}

export interface PostHardware {
  asset_tag: string;
  status_id: number;
  location_id: number;
  model_id: number;
  name: string;
  serial: string;
  _snipeit_dot_ids_3?: string;
}

export interface PatchHardware {
  notes?: string;
  archived?: boolean;
  status_id?: number;
  asset_tag?: string;
  location_id?: number;
  model_id?: number;
  name?: string;
  serial?: string;
}

export interface ListModelsResponse {
  id: number;
  name: string;
  model_number: string;

  requestable: boolean;
  created_at?: null | SnipeItDate;
  updated_at?: null | SnipeItDate;
  category: SnipeItRef;
  manufacturer: SnipeItRef;
}

export interface PostModelRequest {
  name: string;
  model_number?: string;
  category_id: number;
  manufacturer_id: number;
}

export interface PostModelResponse {
  id: number;
  name: string;
}

export interface PostHardwareResponse {
  status: string;
  messages: string;
  payload: {
    model_id: number;
    name: string;
    serial: string;
    company_id: number;
    asset_tag: string;
    status_id: number;
    id: number;
    model: SnipeItRef;
  };
}

export default class SnipeItClient {
  private readonly axios: AxiosInstance;

  constructor(private url: string) {
    this.axios = axios.create({
      baseURL: url,
      timeout: 10000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    this.axios.interceptors.request.use(async (config) => {
      config.headers["Authorization"] = await Auth.currentSession().then(
        (session) => session.getAccessToken().getJwtToken()
      );
      return config;
    });
  }

  async postHardware(hw: PostHardware) {
    if (
      hw === undefined ||
      hw.asset_tag === undefined ||
      hw.asset_tag.trim().length === 0
    ) {
      throw new Error("asset_tag");
    } else if (hw.model_id === undefined || isNaN(hw.model_id)) {
      throw new Error("model_id");
    } else if (hw.status_id === undefined || isNaN(hw.status_id)) {
      throw new Error("status_id");
    }

    console.log("POST", this.url, "/api/v1/hardware", JSON.stringify(hw));
    const response = await this.axios.post<PostHardwareResponse>(
      "/api/v1/hardware",
      hw
    );
    console.log(
      "POST",
      this.url,
      "/api/v1/hardware",
      response.status,
      response.statusText,
      JSON.stringify(response.data, undefined, 2)
    );
    return response.data;
  }

  async postModel(model: PostModelRequest) {
    if (
      model === undefined ||
      model.name === undefined ||
      model.model_number === undefined ||
      isNaN(model.manufacturer_id) ||
      isNaN(model.category_id)
    ) {
      throw new Error("model");
    }

    console.log("POST", this.url, "/api/v1/models", JSON.stringify(model));
    const response = await this.axios.post<PostModelResponse>(
      "/api/v1/models",
      model
    );
    console.log(
      "POST",
      this.url,
      "/api/v1/models",
      response.status,
      response.statusText,
      JSON.stringify(response.data, undefined, 2)
    );
    const newModel = response.data;
    if (newModel === undefined || newModel === null || isNaN(newModel.id)) {
      throw new Error("Model could not be posted");
    }
    return newModel;
  }

  async getHardwareBySerial(serial: string) {
    if (serial === undefined || serial.trim().length === 0) {
      throw new Error();
    }
    const url = "/api/v1/hardware/byserial/" + serial.trim();
    console.log("GET", this.url, url, serial);
    const response = await this.axios.get<SnipeItListResult<ListHardware>>(url);

    if (response.status === 200) {
      console.log(
        "GET",
        this.url,
        url,
        response.status,
        response.statusText,
        JSON.stringify(response.data, undefined, 2)
      );
      return response.data;
    } else {
      console.error(
        "GET",
        this.url,
        url,
        response.status,
        response.statusText,
        JSON.stringify(response.data, undefined, 2)
      );
      throw new Error();
    }
  }

  async getHardwareByTag(tag: string): Promise<ListHardware | null> {
    if (tag === undefined || tag.trim().length === 0) {
      throw new Error();
    }
    const url = "/api/v1/hardware/bytag/" + encodeURIComponent(tag.trim());
    console.log("GET", this.url, url, tag);
    const response = await this.axios.get<ListHardware | SnipeItError>(url);

    if (response.status === 200) {
      const body = response.data;
      console.log(
        "GET",
        this.url,
        url,
        response.status,
        response.statusText,
        JSON.stringify(body, undefined, 2)
      );
      if (
        "status" in body &&
        body.status === "error" &&
        body.messages === "Asset does not exist."
      ) {
        console.log("Asset does not exist");
        return null;
      } else {
        console.log("Asset already exists");
        return body as ListHardware;
      }
    } else {
      console.error(
        "GET",
        this.url,
        url,
        response.status,
        response.statusText,
        JSON.stringify(response.data, undefined, 2)
      );
      throw new Error();
    }
  }

  async patchHardware(id: number, hw: PatchHardware) {
    if (isNaN(id)) {
      throw new Error("id");
    }
    const url = "/api/v1/hardware/" + id;
    console.log("PATCH", this.url, url, JSON.stringify(hw));
    const response = await this.axios.patch<string>(url, hw);

    if (response.status === 200) {
      console.log(
        "PATCH",
        this.url,
        url,
        response.status,
        response.statusText,
        JSON.stringify(response.data, undefined, 2)
      );
      return response.data;
    } else {
      console.error(
        "PATCH",
        this.url,
        url,
        response.status,
        response.statusText,
        JSON.stringify(response.data, undefined, 2)
      );
      throw new Error();
    }
  }

  async getHardwareByStatus(status: "Pending") {
    if (status === undefined || status.trim().length === 0) {
      throw new Error();
    }
    // https://byteflies.snipe-it.io/api/v1/hardware?status=Pending&order_number=&company_id=&status_id=&search=&sort=category&order=asc&offset=20&limit=20

    const url = `/api/v1/hardware?status=${status}&sort=category&order=asc&offset=0&limit=1000`;
    console.log("GET", this.url, url, status);
    const response = await this.axios.get<SnipeItListResult<ListHardware>>(url);

    if (response.status === 200) {
      console.log(
        "GET",
        this.url,
        url,
        response.status,
        response.statusText,
        JSON.stringify(response.data, undefined, 2)
      );
      return response.data;
    } else {
      console.error(
        "GET",
        this.url,
        url,
        response.status,
        response.statusText,
        JSON.stringify(response.data, undefined, 2)
      );
      throw new Error();
    }
  }

  async listModelsById(bfNumber: string) {
    if (bfNumber === undefined || bfNumber.trim().length === 0) {
      throw new Error();
    }
    const url = "/api/v1/models";
    const namePrefix = `[${bfNumber}]`;
    const params = { search: namePrefix };
    const getOptions: AxiosRequestConfig = {
      params: params,
    };
    console.log("GET", getOptions.baseURL, url, JSON.stringify(params));

    const response = await this.axios.get<
      SnipeItListResult<ListModelsResponse>
    >(url, getOptions);

    if (response.status === 200) {
      console.log(
        "GET",
        getOptions.baseURL,
        url,
        response.status,
        response.statusText,
        JSON.stringify(response.data, undefined, 2)
      );

      const models = response.data;
      return models.rows.filter((m) => m.name.indexOf(namePrefix) !== -1);
    } else {
      console.error(
        "GET",
        getOptions.baseURL,
        url,
        response.status,
        response.statusText,
        JSON.stringify(response.data, undefined, 2)
      );
      throw new Error();
    }
  }
}
