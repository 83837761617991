import { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import SerialSelection, { Serials } from "./SerialSelection";
import { Button, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { convertNfcResponse, nfcToFilter } from "./GetNfcDialog";
import ReadNfcButton from "./ReadNfcButton";
import IOdooOperationsService, {
  ExtendedProductSerial,
  SerialFilter,
} from "./erp/manufacturing/OdooOperationsService";
import {
  Tracking,
  ProductSerial,
  ProductionOrderLine,
  Product,
} from "./erp/manufacturing/Product";
import {
  BF_NAKED_DOCK,
  BF_VIRTUAL_BYTEFLIES_KIT,
  BF_VIRTUAL_BYTEFLIES_KIT_WITH_IFU,
  COMPLETE_GROUP_SEPARATOR_STATES,
  GroupSeparatorState,
  GROUP_SEPARATOR,
  isScannableLotProduct,
} from "./Utils";
import GetDockIdButton from "./GetDockIdButton";
import { isBlank, isNotBlank } from "./StringUtils";
import { parseSerial } from "@byteflies/byteflies-serials";
import NewSerialWithoutPoButton from "./actions/NewSerialWithoutPoButton";
import { StockQuant } from "@byteflies/odoo-typescript";
import { id } from "./erp/odoo/OdooUtils";

interface ProductionOrderLineEditorProps {
  svc: IOdooOperationsService;
  line: ProductionOrderLine;
  index: number;
  inputRefs: React.MutableRefObject<HTMLInputElement[]>;
  onError(error: any): void;
  onChange(line: ProductionOrderLine): void;
  onAddLine(line: ProductionOrderLine): void;
  onDeleteLine(line: ProductionOrderLine): void;
  onCopySerial(serial: ProductSerial): void;
  allowedProducts: Product[];
  canAddLine(line: ProductionOrderLine): boolean;
  canDeleteLine(line: ProductionOrderLine): boolean;
  canCopySerial(line: ProductionOrderLine): boolean;
  serials: Serials;
  stockQuants: StockQuant[];
  onNfcStart?(): void;
  onNfcFinish?(): void;
  nfcBusy?: boolean;
}
function ProductionOrderLineEditor(props: ProductionOrderLineEditorProps) {
  const {
    svc,
    line,
    index,
    inputRefs,
    serials,
    stockQuants,
    onNfcStart,
    onNfcFinish,
    nfcBusy,
  } = props;
  const [product, setProduct] = useState(line.product);
  const [serial, setSerial] = useState(line.serial);
  const [myUdi, setMyUdi] = useState(line.serial.udi || "");
  const [myUdiError, setMyUdiError] = useState(false);
  const [myDeviceId, setMyDeviceId] = useState(line.serial.deviceId || "");
  const [myDeviceIdError, setMyDeviceIdError] = useState(false);
  const [apiBusy, setApiBusy] = useState(false);

  const grpSepState = useRef<GroupSeparatorState>("");

  useEffect(() => {
    setProduct(line.product);
    setSerial(line.serial);
  }, [line, setSerial, setProduct]);

  useEffect(() => {
    if (serial !== undefined) {
      setMyUdi(serial.udi || "");
      setMyDeviceId(serial.deviceId || "");
    }
  }, [serial, setMyDeviceId, setMyUdi]);

  if (line === undefined || product === undefined) {
    return null;
  }

  const handleUdiChange = async (
    udi: string,
    currentInput: HTMLInputElement,
    focusNextInput?: boolean
  ): Promise<void> => {
    const clonedSerial: ProductSerial = { ...serial, udi: udi };
    if (udi !== "") {
      setApiBusy(true);
      try {
        const p = parseSerial(udi);
        clonedSerial.serial = p.serial ?? p.lot;
        if (!clonedSerial.serial) {
          throw Error("Could not determine serial");
        }
        const odooSerial = await svc.getSerial(
          clonedSerial.product,
          clonedSerial.serial
        );
        if (odooSerial) {
          const a = clonedSerial as ExtendedProductSerial;
          const b = odooSerial as ExtendedProductSerial;
          a.id = b.id;
          a.deviceId = b.deviceId;

          if (clonedSerial.deviceId !== undefined) {
            setMyDeviceId(clonedSerial.deviceId);
          }
          console.log("changed deviceId", clonedSerial.deviceId);
        } else {
          throw Error("Could not find Odoo serial");
        }
        if (focusNextInput) {
          const nextInput = inputRefs.current
            .slice(index)
            .filter(() => true)[1];
          if (nextInput) {
            nextInput.focus();
          }
        }
      } catch (error) {
        console.error("Failed to parse udi", udi, error);
        setMyUdiError(true);
        currentInput.focus();
        currentInput.select();
      } finally {
        setApiBusy(false);
      }
    }

    const clone: ProductionOrderLine = {
      ...line,
      serial: clonedSerial,
    };
    setSerial(clonedSerial);
    props.onChange(clone);
  };

  return (
    <TableRow
      key={line.id || index}
      data-cy={`raw-ingredient-${index}`}
      data-cy-internal-reference={product.internalReference || `${index}`}
    >
      <TableCell>{line.product_qty}</TableCell>
      <TableCell>
        {(props.allowedProducts === undefined ||
          props.allowedProducts.length <= 1) && (
            <>{product.internalReference}</>
        )}

        {props.allowedProducts !== undefined &&
          props.allowedProducts.length > 1 && (
            <Select
              data-cy={`internal-reference-${index}`}
              fullWidth
              label="Catalog nr"
              type="text"
              value={product === undefined ? "" : product}
              onChange={(event) => {
                if (event.target.value !== "") {
                  const product = event.target.value as Product;
                  setProduct(product);

                  const clone: ProductionOrderLine = {
                    ...line,
                    product: product,
                  };
                  props.onChange(clone);
                }
              }}
            >
              {props.allowedProducts !== undefined &&
                props.allowedProducts.map((prod, i) => {
                  return (
                    <MenuItem value={prod as any} key={index + "-" + i}>
                      {prod.internalReference}
                    </MenuItem>
                  );
                })}
            </Select>
          )}
      </TableCell>
      <TableCell>{product.name}</TableCell>
      <TableCell>
        {product.inventory !== undefined &&
        product.inventory.traceability !== undefined &&
        (product.inventory.traceability.tracking ===
          Tracking.ByUniqueSerialNumber ||
          isScannableLotProduct(product.internalReference)) ? (
          <TextField
            inputRef={(ref) => (inputRefs.current[index] = ref)}
            data-cy="udi"
            size="small"
            fullWidth
            label="UDI"
            type="text"
            value={myUdi}
            error={myUdiError}
            disabled={apiBusy}
            onFocus={(event) => event.target.select()}
            onKeyDown={(event) => {
              event.stopPropagation();
              setMyUdiError(false);
              const key = event.key;
              if (key === "Enter" && myUdi !== "") {
                event.preventDefault();
                handleUdiChange(myUdi, inputRefs.current[index], true);
              } else if (key === "Alt") {
                event.preventDefault();
                grpSepState.current = "Alt";
              } else if (key === "0" && grpSepState.current === "Alt") {
                event.preventDefault();
              } else if (key === "2" && grpSepState.current === "Alt") {
                event.preventDefault();
                grpSepState.current = "Alt2";
              } else if (key === "9" && grpSepState.current === "Alt2") {
                event.preventDefault();
                grpSepState.current = "Alt29";
              } else if (key === "Control") {
                event.preventDefault();
                grpSepState.current = "Control";
              } else if (key === "]" && grpSepState.current === "Control") {
                event.preventDefault();
                grpSepState.current = "Control]";
              } else if (grpSepState.current !== "") {
                grpSepState.current = "";
              }

              if (
                COMPLETE_GROUP_SEPARATOR_STATES.includes(grpSepState.current)
              ) {
                const newUdi = myUdi + GROUP_SEPARATOR;
                setMyUdi(newUdi);
                grpSepState.current = "";
              }
            }}
            onChange={(event) => {
              const newUdi = event.target.value;
              setMyUdi(newUdi);
              setMyUdiError(false);
            }}
            onBlur={(event) => {
              if (myUdi !== "") {
                setMyUdiError(false);
                handleUdiChange(myUdi, inputRefs.current[index]);
              }
            }}
            InputProps={
              product.internalReference &&
              [
                BF_VIRTUAL_BYTEFLIES_KIT,
                BF_VIRTUAL_BYTEFLIES_KIT_WITH_IFU,
              ].includes(product.internalReference)
                ? {
                    endAdornment: (
                      <NewSerialWithoutPoButton
                        product={product}
                        svc={svc}
                        onError={async (error) => {
                          console.error("Failed to create new serial", error);
                          setMyUdiError(true);
                          props.onError(error);
                        }}
                        onSerialCreated={async (newSerial) => {
                          const clone: ProductionOrderLine = {
                            ...line,
                            serial: newSerial,
                          };
                          setSerial(newSerial);
                          props.onChange(clone);
                        }}
                        disabled={serial.id !== undefined}
                      />
                    ),
                  }
                : product.inventory.traceability.tracking ===
                  Tracking.ByUniqueSerialNumber
                ? {
                    endAdornment: (
                      <ReadNfcButton
                        onStart={onNfcStart}
                        onFinish={onNfcFinish}
                        disabled={nfcBusy}
                        onError={async (error) => {
                          console.error("Failed to read NFC", error);
                          setMyUdiError(true);
                          props.onError(error);
                        }}
                        onNfc={async (nfc) => {
                          try {
                            if (nfc === undefined) {
                              throw new Error("Failed to read NFC");
                            }

                            const device = convertNfcResponse(nfc);
                            setMyUdi(device.udi || "");
                            setMyUdiError(false);

                            if (isBlank(device.serial)) {
                              throw new Error("Failed to parse NFC: serial");
                            }

                            const filter = nfcToFilter(product, nfc);

                            setApiBusy(true);

                            const odooSerials = await svc.searchSerialsByName(
                              filter
                            );

                            if (odooSerials.length === 0) {
                              throw new Error(
                                `No serial found in odoo: ${device.serial}`
                              );
                            } else if (odooSerials.length === 1) {
                              const odooSerial = odooSerials[0];

                              if (
                                isNotBlank(odooSerial.deviceId) &&
                                isNotBlank(device.deviceId) &&
                                odooSerial.deviceId !== device.deviceId
                              ) {
                                throw new Error(
                                  `Device ID in odoo and NFC do not match: ${odooSerial.deviceId} ${device.deviceId}`
                                );
                              }
                              const clone: ProductionOrderLine = {
                                ...line,
                                serial: odooSerial,
                              };
                              setSerial(odooSerial);
                              props.onChange(clone);
                            } else if (odooSerials.length > 1) {
                              throw new Error(
                                `Multiple serials found in odoo: ${device.serial}`
                              );
                            }
                          } catch (error) {
                            console.error("NFC error", error);
                            setMyUdiError(true);
                            props.onError(error);
                            return;
                          } finally {
                            setApiBusy(false);
                          }
                        }}
                      />
                    ),
                  }
                : {}
            }
          />
        ) : (
          <SerialSelection
            product={product}
            serials={serials.filterByProduct(product)}
            stockQuants={stockQuants.filter(
              (sq) => id(sq.product_id) === product.id
            )}
            value={serial}
            onSerialSelected={(serial) => {
              const clone: ProductionOrderLine = { ...line, serial: serial };
              props.onChange(clone);
            }}
          />
        )}
      </TableCell>

      <TableCell>
        {product.inventory !== undefined &&
          product.inventory.traceability !== undefined &&
          product.inventory.traceability.tracking ===
            Tracking.ByUniqueSerialNumber && (
            <TextField
              data-cy="device-id"
              size="small"
              fullWidth
              label="Device ID"
              type="text"
              value={myDeviceId}
              error={myDeviceIdError}
              disabled={true}
              onKeyDown={(event) => event.stopPropagation()}
              InputProps={{
                endAdornment: product.internalReference === BF_NAKED_DOCK && (
                  <GetDockIdButton
                    onDockId={async (status) => {
                      setMyDeviceIdError(false);
                      if (status !== undefined && status.DockID !== undefined) {
                        const filter: SerialFilter = {
                          ref: status.DockID,
                          productRefs: [BF_NAKED_DOCK],
                        };
                        const odooSerials = await svc.searchSerialsByName(
                          filter
                        );
                        if (odooSerials.length === 0) {
                          const clonedSerial: ProductSerial =
                            serial !== undefined
                              ? serial
                              : ({
                                  product: product,
                                } as ProductSerial);
                          clonedSerial.deviceId = status.DockID;

                          const clone: ProductionOrderLine = {
                            ...line,
                            serial: serial,
                          };
                          setSerial(clonedSerial);
                          props.onChange(clone);
                        } else if (odooSerials.length === 1) {
                          const odooSerial = odooSerials[0];
                          const clone: ProductionOrderLine = {
                            ...line,
                            serial: odooSerial,
                          };
                          setSerial(odooSerial);
                          props.onChange(clone);
                        } else {
                          console.error(
                            "Multiple dock serial numbers found in odoo",
                            status.DockID
                          );
                        }
                      }
                    }}
                    onError={(error) => {
                      console.error("Failed to read Dock ID", error);
                      setMyDeviceIdError(true);
                      props.onError(error);
                    }}
                  />
                ),
              }}
            />
          )}
      </TableCell>

      <TableCell>
        <span>
          {product.inventory !== undefined &&
            product.inventory.traceability !== undefined &&
            product.inventory.traceability.tracking ===
              Tracking.ByUniqueSerialNumber &&
            props.canCopySerial(line) && (
              <Tooltip title="Copy this udi/serial to the product">
                <span>
                  <Button
                    data-cy="copy-serial"
                    fullWidth={false}
                    disabled={!props.canCopySerial(line)}
                    color="inherit"
                    onClick={() => {
                      props.onCopySerial(serial);
                    }}
                  >
                    <GetAppIcon />
                  </Button>
                </span>
              </Tooltip>
            )}

          {props.canAddLine(line) && (
            <Tooltip title="Copy this production order line">
              <span>
                <IconButton
                  data-cy="add"
                  edge="start"
                  color="inherit"
                  aria-label="add"
                  onClick={() => {
                    const clone: ProductionOrderLine = {
                      ...line,
                      serial: {
                        ...serial,
                        udi: undefined,
                        deviceId: undefined,
                        serial: undefined,
                      },
                    };
                    props.onAddLine(clone);
                  }}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {props.canDeleteLine(line) && (
            <Tooltip title="Delete this production order line">
              <span>
                <IconButton
                  data-cy="delete"
                  edge="start"
                  color="inherit"
                  aria-label="delete"
                  onClick={() => {
                    props.onDeleteLine(line);
                  }}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </span>
      </TableCell>
    </TableRow>
  );
}

export default ProductionOrderLineEditor;
